export default {
  titles: {
    types: "Reports",
    standard: 'Standard',
    custom: 'Custom',
    points_pago: 'Pago Points',
    world_class: 'World Class',
    cash_out: 'Cash Out',
    back_to_types: 'Report types',
    consumed_budget: 'Consumed Budget',
    unconsumed_budget: 'Unconsumed Budget',
    allocated_budget: 'Allocated Budget',
    data_tracking: 'Trending report',
    monthly_budget: 'Budget Valability Report',
    offers: 'Offer report',
    upBenefits: 'Report payroll MB ',
  },
  buttons: {
    types: {
      standard: 'Standard Report',
      custom: 'Custom Report',
      world_class: 'Export World Class',
      points_pago: 'Points Pago',
      cash_out: 'Cash Out',
      back_to_types: 'Report types',
      consumed_budget: 'Consumed Budget',
      unconsumed_budget: 'Unconsumed Budget',
      allocated_budget: 'Allocated Budget',
      monthly_budget: 'Budget Valability Report',
      data_tracking: 'Trending report',
      offers: 'Offer report',
      upBenefits: 'Payroll MB report',
    },
    show_report: "Show report",
    refresh_report: "Refresh report",
    download_report: "Download report",
  },
  info: {
    world_class: 'Indiferent de data la care se face exportul dupa 27 ale lunii pana la finalul lunii curente, in raport vor fi cuprinse solicitarile din perioada 1-27 ale lunii respective'
  },
  filters: {
    company: 'Company',
    client: 'Company',
    budget: "Budget type",
    period: "Period",
    employeeData: "Employee data",
    ticketInfo: "Ticket info",
    benefitInfo: "Info other benefits",
    benefit: "Benefit type",
    totalTaxes: "Total taxes",
    total_taxes: "Total taxes",
    partner_fields: "Partner fields",
    groupType: "Group Type",
    taxedBy: "Taxed By",
    date_groups: "Date grouping type",
    campaign_track_levels: "Campaign group type",
    categories: "Categories",
    campaign_types: "Product type",
    start_month: "Start month",
    end_month: "End month",
    start_year: "Start year",
    end_year: "End year",
  },
  inputLabels: {
    from : 'From',
    to: 'To',
    startDate: 'Start date',
    endDate: 'End date',
    startDateOffer: 'Offer activation interval:',
    endDateOffer: 'Offer expiration date:',
    ticket_count: 'Nr.',
    total_value: 'Value',
    total_value_cas_cass: 'CAS+CASS',
    total_value_tax: 'Impozit venit',
    value_cam: 'CAM tax',
    partner_name: 'Partner name',
    partner_iban: 'Partner IBAN',
    contract_number: 'Contract Nr.',
    world_class_month: 'Subscription month',
    world_class_year: 'Subscription year',
    totals: 'Total - ',
    group_by: {
      benefit: 'Benefit',
      request: 'Request',
      sumRequest: 'Grouped requests',
    },
    taxed_by: {
      gross: 'Gross',
      net: 'Net'
    }
  },
  errors: {
    endDateBeforeStartDate: 'End date must be greater than start date',
    no_date_selected: 'Select start and end date',
    no_start_date_selected: 'Select start date 1 and start date 2',
    no_end_date_selected: 'Select end date 1 and end date 2',
    no_date_selected_unconsumed_budget: 'Select the expiration date of the unconsumed budget',
    no_start_date_selected_monthly_budget: 'Select start date',
    no_end_date_selected_monthly_budget: 'Select end date',
    no_client_selected: 'Select a client',
    minimum_columns: 'Minumum columns not selected (First and last name, CNP)',
    year_required: 'Select year',
    month_required: 'Select month',
  },
  standard: {
    fields: {
      employee_code: 'Marc',
      cnp: 'CNP',
      name: 'Name',
      fist_name: 'First name',
      allocated_budget: 'Allocated Employer Budget',
      ppp3_budget: 'PPP3 Budget',
      available_budget: 'Available Budget',
      budget_expiration_date: 'Unused Budget Expiration Date',
      budget_type: 'Budget_type',
      contribution_type: 'Contribution type',
      benefit: 'Benefit Type',
      quantity: 'Quantity',
      unit_value: 'Unit Value',
      total_value: 'Total Value',
      tax_value: 'Income Tax',
      cass_value: 'CASS',
      cas_value: 'CAS',
      cam_value: 'CAM',
      net_value: 'Net Value',
      placed_at: 'Placed on',
      status: 'Request Status',
      company: 'Company',
      partner_name: 'Partner Name',
      partner_iban: 'Partner IBAN',
      contract_number: 'Contract Nr.',
      personal_use: 'Personal use',
      debit_from: 'Debit from',
      ce_deleted_at: 'User deleted'
    }
  },
  allocated_budget: {
    fields: {
      employee_code: 'Marc',
      cnp: 'CNP',
      name: 'Name',
      fist_name: 'First name',
      company: 'Company',
      allocated_budget: 'Allocated Employer Budget',
      available_budget: 'Available Budget',
      budget_expiration_date: 'Budget Expiration Date',
      allocated_telework_budget: 'Allocated Employer Telework Budget',
      available_telework_budget: 'Available Telework Budget',
      budget_telework_expiration_date: 'Unused Telework Budget Expiration Date',
      allocated_ppp3_budget: 'Allocated Employer Ppp3 Budget',
      available_ppp3_budget: 'Available Ppp3 Budget',
      budget_ppp3_expiration_date: 'Unused Ppp3 Budget Expiration Date',
    }
  },
  consumed_budget: {
    fields: {
      employee_code: 'Marc',
      cnp: 'CNP',
      name: 'Name',
      budget_expiration_date: 'Budget Expiration Date',
      budget_type: 'Budget Type',
      benefit: 'Benefit Type',
      ticket_quantity: 'Ticket Quantity',
      ticket_value: 'Ticket Value',
      base_budget_value: 'Budget',
      base_budget_consumed: 'Consumed',
      base_exceeded_budge_value: 'Exceeded Value',
      base_exceeded_budge_consumed: 'Exceeded Consumed',
      base_tax_value: 'Income Tax',
      base_cass_value: 'CASS',
      base_cas_value: 'CAS',
      base_exceeded_tax_value: 'Exceeded Income Tax',
      base_exceeded_cass_value: 'Exceeded CASS',
      base_exceeded_cas_value: 'Exceeded CAS',
      gross_taxed: 'Gross taxed',
      total_value: 'Total Value',
      tax_value: 'Total Income Tax',
      cass_value: 'Total CASS',
      cas_value: 'Total CAS',
      cam_value: 'Total CAM',
      net_value: 'Net Value',
      placed_at: 'Placed on',
      status: 'Request Status',
      company: 'Company',
      partner_name: 'Partner Name',
      partner_iban: 'Partner IBAN',
      contract_number: 'Contract Nr.',
      month_year :'Month/Year',
    }
  },
  offers: {
    fields: {
      offer_name : 'Offer Name',
      offer_type : 'Offer Type',
      offer_status: 'Offer Status',
      partner: 'Partner',
      partner_name: 'Partner Name',
      partner_type: 'Partner Type',
      partner_fiscal_code: 'Fiscal Code',
      county_availability: 'County Availability',
      all_county: 'All county',
      affiliate_platform: 'Affiliate Platform',
      affiliate_channel: 'Affiliate Channel',
      affiliate_channel_name: 'Affiliate Channel Name',
      created_at: 'Created at',
      end_use_date: 'Expiration Date',
      start_use_date: 'Start Date',
      offer_code: 'Offer Code',
      category: 'Category',
      offer_tracking_order: 'Nr click - Order',
      offer_tracking_details: 'Nr click - Details'
    }
  },
  unconsumed_budget: {
    fields: {
      cnp: 'CNP',
      name: 'Name',
      date: 'Available budget at'
    }
  },
  custom: {
    fields: {
      beneficiary: {
        company: 'Company',
        name: 'Name',
        cnp: 'CNP',
        cost_center: 'Cost center',
        employee_number: 'Employee ID',
        department: 'Department',
        job_title: 'Job title',
        workstation: 'Working point',
        benefit_category_code: 'Benefit group',
        underage_children_nr: 'Children Nr.',
      },
      legislated_benefits: {
        dejun: {
          timesheet_ticket_count: 'Allocated dinner ticket nr.',
          total_nr_tickets: 'Ordered Dinner tickets',
          total_value_cas_cass: 'Dinner tickets CAS+CASS value',
          total_value_tax: 'Dinner tickets tax value',
          total_value: 'Dinner tickets total value',
        },
        vacation: {
          total_nr_tickets: 'Ordered Vacation tickets',
          total_value_cas_cass: 'Vacation tickets CAS+CASS value',
          total_value_tax: 'Vacation tickets tax value',
          total_value: 'Vacation tickets total value',
        },
        culture: {
          // month
          total_month_nr_tickets: 'Ordered Culture month tickets',
          total_month_value_cas_cass: 'Culture month tickets CAS+CASS value',
          total_month_value_tax: 'Culture month tickets tax value',
          total_month_value: 'Culture month tickets total value',
          // occasions
          total_occasion_nr_tickets: 'Ordered Culture occasions tickets',
          total_occasion_value_cas_cass: 'Culture occasions tickets CAS+CASS value',
          total_occasion_value_tax: 'Culture occasions tickets tax value',
          total_occasion_value: 'Culture occasions tickets total value',
          // total
          total_nr_tickets: 'Ordered Culture tickets',
          total_value_cas_cass: 'Culture tickets CAS+CASS value',
          total_value_tax: 'Culture tickets tax value',
          total_value: 'Culture tickets total value',
        },
        gift: {
          // legal_events
          total_legal_events_nr_tickets: 'Ordered Gift tickets - legislated events',
          total_legal_events_value_cas_cass: 'Gift tickets CAS+CASS value - legislated events',
          total_legal_events_value_tax: 'Gift tickets tax value - legislated events',
          total_legal_events_value: 'Gift tickets total value - legislated events',
          // company events
          total_company_events_nr_tickets: 'Ordered Gift tickets - company events',
          total_company_events_value_cas_cass: 'Gift tickets CAS+CASS value - company events',
          total_company_events_value_tax: 'Gift tickets tax value - company events',
          total_company_events_value: 'Gift tickets total value - company events',
          // stim
          total_stim_nr_tickets: 'Ordered Gift tickets - STIM',
          total_stim_value_cas_cass: 'Gift tickets CAS+CASS value - STIM',
          total_stim_value_tax: 'Gift tickets tax value - STIM',
          total_stim_value: 'Gift tickets total value - STIM',
          // total
          total_nr_tickets: 'Ordered Gift tickets - Total',
          total_value_cas_cass: 'Gift tickets CAS+CASS value - Total',
          total_value_tax: 'Gift tickets tax value - Total',
          total_value: 'Gift tickets total value - Total',
        },
      },
      not_legislated_benefits: {
        ticket_count: 'Ticket Nr.',
        total_value: 'Value',
        total_value_cas_cass: 'CAS+CASS',
        total_value_tax: 'Tax',
      },
      benefit_totals: {
        sum_taxes: 'Total Taxes',
        tax: 'Total income tax',
        cas_cass: 'Total CAS+CASS',
        cam: 'Total CAM',
      }
    }
  },
  world_class: {
    fields: {
      subscription_start_day: 'Subscription start date',
      index: 'No',
      first_name: 'First name',
      last_name: 'Last Name',
      email: 'E-mail',
      phone: 'Phone',
      birth_day: 'Birthdate (dd/mm/yyyy)',
      ci_number: 'ID serie&number',
      city: 'Oras',
      address: 'Address',
      network: 'Network',
      club_name: 'Club Home base',
      type: 'Membership Type',
      subscription_duration: 'Length of Membership Type',
      order_request_id: 'Order request no',
    }
  },
  points_pago: {
    fields: {
      index: 'Nr',
      first_name: 'First name',
      last_name: 'Last name',
      email: 'E-mail order',
      cnp: 'CNP',
      placed_at: 'Placed on',
      order_request_id: 'Request Nr',
      request_value_total: 'Ordered value(total)',
      request_value_pago: 'Ordered value(Pago)',
      conversion_value: 'Conversion value',
      points: 'Points',
      processing_tax: 'Processing tax',
    }
  },
  data_tracking: {
    date_groups: {
      day: 'Day',
      month: 'Month',
      year: 'Year',
      week: 'Week',
    },
    campaign_track_levels: {
      category: 'Category',
      product: 'Product',
    },
    campaign_type: {
      offer: 'Offers',
      benefit: 'Benefits',
    }
  }
}
