<template>
  <div>
    <b-modal centered :visible="show" @hide="closeModal" size="lg"
             @hidden="updateShowProp" id="modal-1" ref="confirmationModal" hide-footer>
      <div class="d-block text-center">
        <h3 >{{ $t("superUpLang.tree.actionModal.select_app_version") }} </h3>
      </div>
      <div class="mt-2 text-center mr-5 ml-5 mb-2 action-modal-tabs">
        <div class="col-6 m-auto" >
          <div class="d-flex justify-content-between">
            <b-button class="w-35" @click="selectVersionModal('v1')" variant="outline-info" size="lg">v1</b-button>
            <b-button class="w-35" @click="selectVersionModal('v2')" variant="outline-info" size="lg">v2</b-button>
          </div>
        </div>
      </div>
    </b-modal>

    <content-loader :loader-visible="this.loading" :loader-error="loaderError"></content-loader>
  </div>
</template>

<script>
import {BButton, BModal, VBModal} from "bootstrap-vue";
import HttpCatServices from "@/superUp-module/resources/services/httpCatServices";
import HttpLabelServices from "@/superUp-module/resources/services/httpLabelServices";
import HttpTreeService from "@/superUp-module/resources/services/httpTreeService";

export default {
  components: {
    "b-modal": BModal
  },
  directives: {
    "b-modal": VBModal
  },
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false
    },
    selectVersion: {

    },
  },
  data() {
    return {
      loading: false,
      loaderError: false
    }
  },
  methods: {
    closeModal() {
      this.$emit('update:show', false);
      this.$refs.confirmationModal.hide();
    },
    updateShowProp() {
      if (this.show) {
        this.$emit('update:show', false);
      }
    },
    selectVersionModal(version)
    {
      this.$emit('update:selectVersion', version);
    },
    initCache()
    {
      HttpTreeService.initCache().then(() => {
        window.reload();
      })
    }
  }
}
</script>
