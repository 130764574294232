export default {
  title: "New requests",
  title_history: "Previous requests",
  title_specific_info: "Specific information",
  error_message: "Error message",
  title_placed_at: "Request {number} details placed on",
  no_benefit_partner: "Request benefit is not assigned to any partner! Contact support to continue.",
  no_specific_fields: "Benefit has no specific fields",
  new_requests: "New requests",
  detail_requests: "Requests details",
  bou_error: {
    general_message : "Please contact AS400 team",
    bou_id : "Bou id"
  },
  await_callback: {
    message : "The status for this request is 'await_callback' - we are waiting for a response from UP Online"
  },
  filter: {
    title: "Filter new requests",
    field: {
      client: "Client",
      beneficiary: "Beneficiary",
      request_type: "Request type",
      start_date: "Start date",
      end_date: "End date",
      benefit: "Benefit",
      benefit_sub_type: "Benefit sub type",
      status: "status",
      delivery_address: 'Card delivery address code',
      department: "Department",
      job_title: "Job title",
      cost_center: "Cost center",
      working_point: 'Working point',
      benefit_group: 'Benefit groups',
      partner: "Partner",
      platform_source: 'Platform source',
      beneficiary_status: 'Beneficiary status',
      beneficiary_statuses: {
        status_1: 'Active',
        status_2: 'Invited',
        status_3: 'Suspended',
        status_4: 'Pending delete',
        status_5: 'Uninvited',
        status_6: 'Deleted',
      },
    },
  },
  types: {
    top_up: 'Top-up request',
    top_up_live: 'Top-up request - warranty',
    card_request: 'Card request',
    voucher: 'Gift vouchers',
    subscription: 'Subscriptions',
    not_legislated: 'Partner',
  },
  table: {
    header: {
      number: "Request nr",
      placed_on: "Placed on",
      beneficiary: "Beneficiary",
      beneficiary_cnp: "Beneficiary CNP",
      client: "Client",
      request_type: "Request type",
      benefit: "Benefit",
      amount: "Benefit value",
      status: "Status",
      benefit_sub_type: "Benefit sub type",
      delivery_address: 'Delivery address',
      partner: 'Partner',
      taxes: 'Salary taxes',
      contract_number: 'Contract Nr.',
      contract_verified: 'Contract verified',
      platform_source: 'Platform source',
      ticket_count: 'Ticket count',
      ticket_value: 'Ticket value'
    },
  },
  summary: {
    title: "Summary",
    count_total_request: "Total request",
    error_placing: "Error placing",
    count_top_up_request: "Top-up request",
    count_request: "Card issuing requests",
    total_values: "Total values",
    action: "Action",
    order_placed: 'Order placed',
    order_invalid: 'Validation errors',
    status: {
      invited: 'Invited',
      new: 'New',
      rejected: 'Reject',
      canceled: 'Canceled',
      reject: 'Rejected',
      ordered: 'Ordered',
      valid: 'Valid',
      invalid: 'Invalid',
      await_top_up: 'Pending',
      await_callback: 'Pending validation',
      validation_failed: 'Validation failed',
      bou_error: 'BOU Error',
      await_voucher_upload: 'Awaiting voucher'
    },
  },
  modal: {
    title: "Confirmation",
    confirm_to_reject_request: 'You are about to cancel the {benefitName} request, worth {totalValue} lei',
    confirmed_rejected_request:'The request has been canceled!',
    please_confirm_this_action: "Please confirm this action.",
    this_will_show_one_cart_type : 'Are you sure you want to decline the request?',
    confirm_mass_place_order: "Are you sure you want to place the order?",
    confirm_mass_place_order_status_new: "Are you sure you want to mark the order as new?",
    confirm_mark_pending: "Are you sure you want to mark as pending?",
    info_non_legislated_recurring: 'Only orders for "Private pension pilon 3" and "Medical insurance" requests will be placed.',
    field: {
      reject_reason: "Reason of rejection",
      confirm_disable_auto_top_ups: "Do you want to stop auto top-ups for benefit {ppp3} and {medical_insurance}?",
    }
  },
  fields_modal: {
    title: "Additional fields",
    no_results: "There are no additional fields"
  },
  response: {
    success: "Success",
    error: "Error",
  },
  action: {
    confirm: "Confirm",
    cancel: "Cancel",
    refuse_requests: "Refuse requests",
    place_order: "Place order",
  },
  auto_statuses: {
    dejun_on: 'Up Dejun Auto Orders - ON',
    dejun_off: 'Up Dejun Auto Orders - OFF',
    gift_on: 'Up Cadou Auto Orders - ON',
    gift_off: 'Up Cadou Auto Orders - OFF',
    vacation_on: 'Up Vacanta Auto Orders - ON',
    vacation_off: 'Up Vacanta Auto Orders - OFF',
    culture_on: 'Up Cultura Auto Orders - ON',
    culture_off: 'Up Cultura Auto Orders - OFF',
  },
  btn: {
    request_type_top_up: 'UP Card top ups',
    request_type_card: 'UP Card issuing',
    request_type_partners: 'Other flexible benefits',
    approve_request: 'Approve request',
    mark_pending: 'Mark pending',
    mark_new: 'Mark new',
    reject_request: 'Reject request',
    back_to_list: 'Back to requests list',
    place_order: "Place order",
    set_delivery_address_to: 'Set delivery address to',
    back_to_requests: 'Back to all requests',
    export_requests: 'Export requests',
  },
  fields: {
    value: 'Value (Lei)',
    selected_period: 'Subscription period',
    voucher_file: 'Voucher file',
    voucher_code: 'Voucher code',
    physical_label: 'Voucher with physical delivery',
    physical_text: 'Contact the beneficiary to sat the delivery information relating to physical voucher delivery.',
    card_series: 'Existing card series',
    card_request: 'Request new card',
    consume_voucher_stock: 'Consume voucher from stock?',
    personal_use: 'Personal use'
  },
  success: {
    line_1: 'Placed <strong>{count}</strong> orders to {scope}',
    line_2: 'You can find the placed orders in the Orders section.',
    line_2_billing: 'To stay in touch with your order progress and to be able to download and pay the proforma, please go to the Orders page by accessing the button below.',
    scope_top_up: 'top up cards.',
    scope_card: 'emmit cards.',
  },
  errors: {
    no_voucher_stocks: 'There are no more stocks for this benefit'
  },
  delivering_auto: 'The system is delivering the voucher from stock'
}
