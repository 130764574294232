export default {
  title: "Clienti",
  description: "Gestioneaza clienti si informatiile lor",
  client_edit_title: "Beneficii",
  client_edit_description: "Configureaza parametrii pentru beneficiile disponibile companiei",
  client_general_config_titles: "Configurari companie",
  module_permission: "Permisiuni module",
  table: {
    title: "Lista clienti",
    search: "Cauta folosind nume, CNP, etc.",
    code: "Cod",
    name: "Nume",
    cui: "CUI",
    address: "Adresa",
    benefits: "Beneficii",
    option_default: "Selecteaza un beneficiu",
    on_birthday: "Zi de nastere beneficiar",
  },
  invoice: {
    title: 'Facturare beneficii nelegiferate',
    enable: 'Activare facturare',
    invoice_day: 'Zi lunara recurenta',
    invoice_type: 'Tip de factura',
    only_active_employees: 'Include doar beneficiari activi',
    only_active_employees_description: 'Daca e bifat va include doar beneficiarii activi in perioada facturata, in caz contrar va include si beneficiarii stersi si suspendati in aceasta perioada',
    invoice_types: {
      1: 'Factura cu toate cotele de TVA',
      2: 'Facturi separate cote TVA'
    },
    benefit_types: 'Tipuri de beneficii incluse in factura',
    subscription_type_included: 'Factura cu subscriptie inclusa',
  },
  partial: {
    top_up_live: 'Aprobarea comenzilor automat prin garantie',
    top_up_live_last_day: 'Selecteaza ultima zi din luna pentru plasarea comenzilor',
    top_up_live_last_day_invoice: 'Selecteaza data de facturare',
    specific_company_events: "Evenimente specifice",
    enable_benefit_for_client: "Activeaza beneficiul pentru client",
    insurance_contributions_deductible: "Contributii pentru asigurari",
    define_company_events: "Defineste evenimentele companiei",
    gender_availability: "Disponibilitate gen",
    for_birthdays: "Zi de nastere",
    events_stated_by_law: "Evenimente legiferate",
    legislated_events: "Evenimente legiferate",
    internal_benefit_name_visible_by_employees: "Denumirea beneficiului vizibila de catre angajatii companiei",
    add: "Adauga eveniment",
    attribute_type: "Tipul de atribuire",
    monthly_attribution: "Atribuire lunara",
    occasional_attribution: "Atribuire ocazionala",
    company_specific_occasions: "Ocazii specifice companiei",
    add_occasion: "Adauga ocazie",
    configuration_for_top_ups: "Configurari pentru alimentare si comenzi carduri",
    top_up_orders: "Comenzi de alimentare",
    number_of_days_per_month: "Numar de zile pe luna pentru a plasa automat comenzile",
    select_first_day_auto_order: "Selecteaza prima zi din luna pentru plasarea comenzilor",
    select_second_day_auto_order: "Selecteaza a doua zi din luna pentru plasarea comenzilor",
    last_day_of_the_month_allowing: "Ultima zi a lunii care permite beneficiarilor sa plaseze o comanda de alimentare a cardului Up Dejun pentru pontajul incarcat",
    last_hour_of_the_month_allowing: "Ora maxima din zi",
    card_issuing_orders: "Comenzi de emitere carduri",
    timesheets_upload_config: "Configurare de pontaje si multiple solicitari",
    number_of_days_per_month_to_place_card_issuing: "Numar de zile pe luna pentru a plasa automat comenzile de emitere card",
    no_benefits_added: "Nu s-au gasit beneficii",
    benefit: "Beneficiu",
    toggle_auto_top_up: "Aprobare automata solicitari de alimentare",
    toggle_direct_top_up: "Aprobarea comenzilor automat-buget virtual",
    toggle_auto_card: "Aprobare automata solicitari de emitere card",
    toggle_multiple_uploading_timesheets: "Activati incarcarea multipla a pontajelor si a solicitarilor",
    cam_budget_taxation: "Taxare Contributie Asiguratorie pentru Munca (CAM) din bugetul angajatului",
    toggle_multiple_timesheet_single_order: 'Activati auto alimentarea o singura data pe luna',
    last_order_days: "Ultima zi a lunii(inclusiv) care permite beneficiarilor sa plaseze solicitari",
    ppp3_order_day: "Data de plasare a solicitarilor de tip PPP3",
    medical_insurance_order_day: "Data de plasare a solicitarilor de tip asigurare medicala",
    gross_budget_taxation: "Taxare Buget angajati BRUT",
    legislated_gross_calculation: "Calcul impozit pe venit beneficii legislative raportat la buget BRUT",
    annual_budget_reset: "Resetare buget beneficiari la sfarsit de an",
    budget_telework_transfer_to_general: "Transfer budget telemunca in momentul expirarii",
    non_legislated_net_taxes_calculate: "Calcul taxe beneficii la nivel net",
    cash_out_last_order_day: "Ultima zi din luna pentru care beneficiarii sa poata face cash out la buget telemunca",
    cash_out_on_delete: "Cash Out Stergere beneficiar",
    cash_out_on_expiration: "Cash Out Buget Expirat",
    cash_out_on_suspend: "Cash Out Suspendare",
    use_telework_budget: "Disponibilitate bugete telemunca",
    banner: "Banner",
    toggle_auto_top_up_card: "Aprobare automata solicitari de emitere si alimentare card",
    order_cancelation_status: 'Status solicitari anulate',
    taxSettings: {
      title: 'Configurare taxare',
      applyTaxes: 'Aplicare taxe',
      tooltip: 'Aceasta optiune este valabila doar pentru bugetul brut. Daca este activata, taxele vor fi scazute din bugetul general.'
    },
    up_dejun: {
      text_1: 'Comenzile se vor procesa in ziua selectata / zilele selectate sau in urmatoarea zi lucratoare, daca este zi de weekend.',
      text_2: 'Beneficiarii pot opta pentru acest Beneficiu in functie de bugetele disponibile si de cele mai recente pontaje incarcate.',
      title_fixed_values: 'Configurare valori permise tichete',
      fixed_values: 'Activati valori fixe tichete de masa',
      fixed_values_info: 'Valori intre {min} si {max} Lei',
      fixed_values_info_singular: 'Valoare ticket (intre {min} si {max} Lei)',
      top_up_set_ticket_nr: 'Activeaza comanda numar tickete',
      divide_budget_for_ticket_value: 'Activati recalcularea valorii la incarcarea automata',
      tooltips: {
        divide_budget_for_ticket_value: 'Daca bugetul este insuficient pentru a se plasa o solicitare cu valoarea obtinuta din inmultirea pontajului cu valoarea ticketului aleasa de angajat, calculam valoarea ticketului folosind formula "valoare ticket = buget disponibil impartit la pontaj", doar daca noua valoare a ticketului depaseste valoarea minima legala. <br>Daca este activa deducerea taxelor din salariu, din valoarea ticketului se va scadea impozitarea. <br> Functia nu este disponibila pentru comandarea numarului de tickete.'
      }
    },
    up_vacation: {
      text_1: 'Comenzile se vor procesa in ziua selectata / zilele selectate sau in urmatoarea zi lucratoare, daca este zi de weekend.',
      text_2: 'Alocarea se poate face oricand pe parcursul anului, in limita plafonului legal.',
      min_val_order: 'Valoare minima pe comanda',
      max_val_year: 'Valoare maxima legala pe an',
    },
    up_culture: {
      text_1: 'Comenzile se vor procesa in ziua selectata / zilele selectate sau in urmatoarea zi lucratoare, daca este zi de weekend.',
      text_2: 'Alocarea se poate face doar &#177; 30 zile fata de data evenimentului.',
      text_3: 'Daca intervine o modificare legislativa cu privire la parametrii fiscali ai Beneficiului sau tipului de eveniment, nu se va mai putea aloca retroactiv.',
    },
    up_gift: {
      text_1: 'Comenzile se vor procesa in ziua selectata / zilele selectate sau in urmatoarea zi lucratoare, daca este zi de weekend.',
      text_2: 'Alocarea se poate face doar &#177; 30 zile fata de data evenimentului.',
      text_3: 'Daca intervine o modificare legislativa cu privire la parametrii fiscali ai Beneficiului sau tipului de eveniment, nu se va mai putea aloca retroactiv.',
      title_config_events: 'Configuratii specifice evenimentelor',
    },
    ppp3_enable_benefit_for_client: 'Activeaza',
    partial_tooltips: {
      non_legislated_net_taxes_calculate_tooltip: 'Aplica taxe 41.5%',
    }
  },
  modal: {
    are_you_sure_you_want_to_save_changes: "Salvezi modificarile?",
    the_information_has_been_changed_are_you_sure_you_want_to_leave: "Exista informatii modificate care nu au fost salvate. Confirmi parasirea paginii?",
    continue: "Continua",
    confirm: "Confirma",
    birthday: "Zi de nastere",
    gender_availability: "Disponibilitate gen",
    gender_male: "Barbati",
    gender_female: "Femei",
    set_active: "Activeaza",
    set_inactive: "Inactiveaza",
    occasion: "Ocazie",
    add: "Adauga",
    edit: "Modifica",
    event: "eveniment",
    update_success: "Informatii modificate cu succes.",
    update_fail: "Nu s-a putut modifica informatia.",
    max_limit_for_topup_per_child: 'Limita superioara pentru fiecare minor pentru alimentare',
    run_ppp3_topup_comand: 'Esti sigur ca vrei sa rulezi comanda pentru a crea solicitari de PPP3?',
    ppp3_command_success: 'Comanda PPP3 a fost initiata cu succes. Aceasta o sa inceapa in 10 minute.',
    ppp3_command_fail: 'Initierea comenzii PPP3 a esuat. Te rugam sa incerci din nou.',
  },
  benefits: {
    up_dejun: 'Dejun',
    up_vacation: 'Vacanta',
    up_culture: 'Cultura',
    up_gift: 'Cadou',
    pp3: 'Pensie Privata Pilon 3',
    partners: 'Alte beneficii flexibile'
  },
  benefit_types: {
    voucher: 'Voucher',
    voucher_fuel: 'Voucher carburant',
    subscription: 'Subscriptie',
    subscription_world_class: 'World Class',
    subscription_24_pay: '24 Pay',
    ppp3_personal: 'Pensie privata tip personal',
    ppp3_employer: 'Pensie privata tip angajator',
    medical_insurance: 'Asigurare medicala',
    points_pago: 'Puncte Pago',
  },
  dynamic_translations: {
    title: 'Texte personalizate clienti',
    error_save: 'Nu s-au putut salva traducerile! Reincercati in cateva minute',
    beneficiary_benefit_taxation: 'Deduceri salariale',
    btn: 'Texte personalizate'
  },
  errors: {
    general_last_top_up_day_greater_then_up_dejun: 'Ultima zi a lunii care permite beneficiarilor sa plaseze o comanda de alimentare a cardului Up Dejun pentru pontajul incarcat trebuie sa fie mai mica sau egala decat ultima zi de plasare solicitari din sectiunea "Configurari companie"',
    up_dejun_fixed_values_min_1: 'Valori fixe tichete de masa UpDejun  necesita cel putin o valoare',
    up_dejun_fixed_values_invalid_values: 'Valori fixe tichete de masa invalide',
    up_dejun_fixed_values_invalid_values_single: 'Valoare ticket tichet de masa invalida',
    order_day: 'Data de plasare a comenzilor de asigurare medicala sau ppp3 nu poate fi mai mare decat data de facturare',
  }
}
