export default {
    list_title_page: 'Tree List',
    create_title_page: 'Create Tree',
    update_title_page: 'Update Tree',
    select_type: 'Select Type',
    select_version: 'Select Version',
    select_products: 'Select products',
    select_brands: 'Select Brands',
    display_order: 'Display Order',
    category: 'Category',
    label: 'Label',
    product: 'Product',

    list_table: {
        header: {
            name: 'Name',
            type: 'Type',
            partner_name: 'Partner',
            status: 'Status',
            is_active: 'Is Active',
            created_at: 'Created At',
            updated_at: 'Updated At',
            actions: 'Actions',
            image_url: 'Image',
            number_row: "Display order level"

        },
        type: {
            1 : 'Benefits',
            2 : 'Offers',
        }
    },
    buttons: {
        new: "New Tree",
        update: "Update",
        delete: "Delete",
        cancel: 'Cancel',
        create: 'Create',
        save: 'Save',
        continue: 'Continue',
        delete_all: 'Delete All',
        change_version: 'Change version',

        refresh_cache: 'Refresh Cache',
        use_version: 'Use Version',
        add_previous_build: 'Roll back',
        create_version: 'Create version',
        regenerate: 'Regenerate',
        add_label: 'Add Label',
        add_brand: 'Add Brands',
        add_product: 'Add products'
    },
    name_modal: {
        title: 'Create',
        message: 'Create name for current tree',
    },
    actionModal: {
        title: 'Confirm',
        message: 'Are you sure you want to delete this item?',
        delete_all: 'Are you sure you want to delete all items?',
        regenerate: 'Create/Set versions',
        select_app_version: 'Select App Version',
    },
    input: {
        edit_name: 'Edit Tree Name',
        edit_type: 'Edit Tree Type',
    },
    import_modal: {
        import: 'Add import file',
        select_type: 'Select Type',
        password: 'EnterPassword'
    },
    errors: {
        password: 'Incorect password, please add valid password',
        file: 'Incorrect file, please try again',
        type: 'Error type, please chose correct type'
    }
}