<template>
  <div>
    <div class="row mb-4">
      <div class="col-12 col-md-6 flexben-page-title">
        <h1>{{ $t("superUpLang.tree.create_title_page") }} v2</h1>
      </div>
      <div class="col-12 col-md-6">
        <button @click="save()" class="btn btn-themed px-4 float-left float-md-right my-2">
          {{ $t("superUpLang.tree.buttons.save") }}
        </button>
      </div>
    </div>
    <h2 v-html="(selectedType) ? $t(`superUpLang.tree.list_table.type.${selectedType}`) : null"></h2>
    <div class="background-white w-100 p-2">
      <div class="d-flex justify-content-start">
        <button v-if="!init" @click="openModal('type')" class="btn btn-themed px-4 float-left float-md-right my-2">
          {{ $t("superUpLang.tree.buttons.create") }}
        </button>
      </div>
      <div class="treeview js-treeview" id="js-treeview">

      </div>
    </div>
    <SelectModal :title="modalTitles" :type.sync="selectedType" :show.sync="showTypeModal"></SelectModal>
    <list-modal :productsToUpdate="productsToUpdate"
                :brandsToUpdate="brandsToUpdate"
                :selectedType.sync="selectedType"
                :type.sync="type"
                :selectedProducts.sync="selectedProducts"
                :selectedBrands.sync="selectedBrands"
                :selectedCategory.sync="selectedCategory" :selectedLabel.sync="selectedLabel"
                :show.sync="showListModal" :title="modalTitles"></list-modal>
    <content-loader :loader-visible="this.loading" :loader-error="loaderError"></content-loader>
    <input-modal :save.sync="submitTree" :item.sync="treeName" :show.sync="showTreeNameModal"></input-modal>
    <import-modal :show.sync="importModalShow"></import-modal>
  </div>

</template>

<script>
import ListModal from "@/superUp-module/resources/views/layout/modals/SuperUpListModal.vue";
import InputModal from "@/superUp-module/resources/views/layout/modals/InputModal.vue";
import SelectModal from "@/superUp-module/resources/views/layout/modals/SelectModal.vue";
import HttpTreeService from "@/superUp-module/resources/services/httpTreeService";
import ImportModal from "@/superUp-module/resources/views/layout/modals/ImportModal.vue";
import TreeService from "@/superUp-module/resources/services/v2/treeService";
import TreeProcessorService from "@/superUp-module/resources/services/v2/treeProcessorService";


export default {
  name: 'SuperUpNewTree',
  components: {
    ListModal,
    InputModal,
    SelectModal,
    ImportModal
  },
  data() {
    return {
      appVersion: null,
      importModalShow: false,
      modalTitles: '',
      type: null,
      selectedType: null,
      selectedCategory: null,
      selectedLabel: null,
      selectedProducts: null,
      selectedBrands: null,
      productsToUpdate: [],
      brandsToUpdate: [],
      init: false,
      loading: false,
      loaderError: false,
      showListModal: false,
      treeService: TreeService,
      treeName: null,
      showTreeNameModal: false,
      submitTree: null,
      showTypeModal: false
    }
  },
  watch: {
    submitTree: function (val) {
      if (!val) {
        return
      }

      this.submit();
    },

    selectedType: function (val) {
      if (!val) {
        return
      }
      this.init = true;
      this.openModal('category');
    },

    selectedCategory: function (val) {
      this.loading = true;

      if (!val) {
        this.init = false;
        this.loading = false;
        return
      }

      this.treeService.processCategory(val);
    },
    selectedLabel: function (val) {
      if (!val) {
        alert('No label values found, tr again');
        return
      }
      this.loading = true;
      this.treeService.processLabel(val);
    },

    selectedBrands: function (data) {
      this.loading = true;

      if (!data) {
        alert('No brands values found, tr again');
        return
      }

      this.loading = true;

      this.treeService.processBrands(data)
    },

    selectedProducts: function (data) {
      this.loading = true;

      if (!data) {
        alert('No products values found, tr again');
        return
      }

      this.loading = true;
      this.treeService.processProducts(data)
    }
  },
  mounted() {
    this.appVersion = this.$route.params.version

    if (!document.getElementById('font-awesome-cdn')) {
      const link = document.createElement('link');
      link.id = 'font-awesome-cdn'; // Assign an ID to check existence
      link.rel = 'stylesheet';
      link.href = 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css';
      document.head.appendChild(link);
    }

    this.treeService.init(this, document.getElementById('js-treeview'));
  },
  methods: {
    save() {
      this.showTreeNameModal = true;
      this.submitTree = false;
    },

    submit() {
      // this.loading = true;
      let __this = this;
      HttpTreeService.getTypes().then(result => {

        const type = result.data.filter((item) => {
          return item.value === Number(__this.selectedType)
        })[0];

        const data = TreeProcessorService.getTree(this.treeService.categoryService, type);

        HttpTreeService.create({name: this.treeName, tree: data, type: this.selectedType, app_version: 'v2'}).then(result => {
          this.loading = false;
          this.submitTree = false;
          window.location.href = '/super-up/tree'
        })
      })
    },

    openModal(val = null) {
      if (!val) {
        console.error('No value to modale')
        return;
      }

      switch (val) {
        case 'brands':
          this.showListModal = true;
          this.modalTitles = this.$i18n.t('superUpLang.tree.select_brands');
          this.type = 'brands';
          break;
        case 'type':
          this.modalTitles = this.$i18n.t('superUpLang.tree.select_type');
          this.showTypeModal = true;
          break;
        case 'category':
          this.showListModal = true;
          this.modalTitles = this.$i18n.t('superUpLang.categories.modal.title_select');
          this.type = 'category';
          break
        case 'products':
          this.showListModal = true;
          this.modalTitles = this.$i18n.t('superUpLang.tree.select_products');
          this.type = 'products';
          break
        default:
          this.modalTitles = this.$i18n.t('superUpLang.labels.modal.title_select');
          this.showListModal = true;
          this.type = 'label';
      }
    }
  }
}
</script>
<style lang="scss">
@use "@/superUp-module/assets/tree.scss";
</style>

