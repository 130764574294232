<template>
  <div class="table-responsive scaled-table hover-table">
    <table class="table table-striped">
      <thead>
      <tr>
        <td @click="sortBy('id')" :class="{'cursor-pointer':enableSort}">
          {{ $t("flexben.beneficiary_pages.requests.table.request_number") }}
          <img src="@/assets/images/arrow-down-fill.svg" v-if="enableSort"
               :class=" (sortField === 'id' && reverseSort) ?'rotate-180':''"/>
        </td>
        <td @click="sortBy('placed_at')" :class="{'cursor-pointer':enableSort}">
          {{ $t("flexben.beneficiary_pages.requests.table.placed_date") }}
          <img src="@/assets/images/arrow-down-fill.svg" v-if="enableSort"
               :class=" (sortField === 'placed_at' && reverseSort) ?'rotate-180':''"/>
        </td>
        <td @click="sortBy('type')" :class="{'cursor-pointer':enableSort}">
          {{ $t("flexben.beneficiary_pages.requests.table.type") }}
          <img src="@/assets/images/arrow-down-fill.svg" v-if="enableSort"
               :class=" (sortField === 'type' && reverseSort) ?'rotate-180':''"/>
        </td>
        <td>
          {{ $t("flexben.beneficiary_pages.requests.table.benefit") }}
        </td>
        <td>
          {{ $t("flexben.beneficiary_pages.requests.table.budget_type") }}
        </td>
        <td @click="sortBy('total_value')" :class="{'cursor-pointer':enableSort}">
          {{ $t("flexben.beneficiary_pages.requests.table.amount") }}
          <img src="@/assets/images/arrow-down-fill.svg" v-if="enableSort"
               :class=" (sortField === 'total_value' && reverseSort) ?'rotate-180':''"/>
        </td>
        <td>{{ $t("flexben.beneficiary_pages.requests.table.taxes") }}</td>
        <td v-if="companyOptions.cam_budget_taxation">{{ $t("flexben.beneficiary_pages.requests.table.cam_value") }}
        </td>
        <td v-if="companyOptions.gross_budget_taxation || companyOptions.cam_budget_taxation">{{ $t("flexben.beneficiary_pages.requests.table.gross_amount")
          }}
        </td>
        <td>{{ $t("flexben.general.last_modified_at") }}</td>
        <td @click="sortBy('status')" :class="{'cursor-pointer':enableSort}">
          {{ $t("flexben.beneficiary_pages.requests.table.status") }}
          <img src="@/assets/images/arrow-down-fill.svg" v-if="enableSort"
               :class=" (sortField === 'status' && reverseSort) ?'rotate-180':''"/></td>
        <td></td>
      </tr>
      </thead>
      <tbody v-if="!loading && requests.data.length > 0" :key="'list_table'">
      <tr class="trhover parent" v-for="(request, index) in requests.data"
          :key="index">
        <td>{{ request.id }}</td>
        <td>{{ moment(request.placed_at, 'DD/MM/YYYY HH:mm') }}</td>
        <td>
          <img v-if="request.benefit_sub_type"
               :id="'type_tooltip_'+request.id" src="@/assets/images/flexben/order/notLegislated.svg"
               :alt="$t('flexben.requests.types.'+request.type)"/>
          <img v-else-if="!request.benefit_sub_type && request.type==='top_up'"
               :id="'type_tooltip_'+request.id" src="@/assets/images/flexben/order/topUp.svg"
               :alt="$t('flexben.requests.types.'+request.type)"/>
          <img v-else-if="!request.benefit_sub_type && request.type==='card_request'"
               :id="'type_tooltip_'+request.id" src="@/assets/images/flexben/order/cardRequest.svg"
               :alt="$t('flexben.requests.types.'+request.type)"/>

          <b-tooltip :target="'type_tooltip_'+request.id" triggers="hover" variant="flexben" placement="topleft">
            {{ $t('flexben.requests.types.'+ (request.benefit_sub_type ? 'not_legislated' : request.type)) }}
          </b-tooltip>
        </td>
        <td>
          <span :style="{backgroundColor: request.benefit.color}" class="color-bullet"></span> {{ request.benefit.name }}
          <span v-if="request.benefit_sub_type === 'subscription_24_pay'"> - {{request.metas[0].extra.product.name}}</span>
          <span v-if="request.event !== null"> - {{ (request.event.type ==='LEGISLATED') ? $t('flexben.general.legal_events.'+request.event.name) : request.event.name }}</span>
        </td>
        <td>{{ $t("flexben.beneficiary_pages.requests.budget_types."+request.budget_type) }}</td>
        <td>{{ (request.total_value > 0) ? (request.base_total_value.toFixed(2)+' Lei') : '-'}}</td>
        <td>{{ (request.total_value > 0) ?
          ((request.all_taxes).toFixed(2)+' Lei') : '-'}}
        </td>
        <td v-if="companyOptions.cam_budget_taxation">{{ request.value_cam.toFixed(2) }} Lei</td>
        <td v-if="companyOptions.gross_budget_taxation || companyOptions.cam_budget_taxation">
          {{ (request.total_value > 0) ? calculateTotal(request).toFixed(2)+' Lei' : '-' }}
        </td>
        <td>{{moment(request.updated_at,'DD/MM/YYYY HH:mm')}}</td>
        <td>
          <span class="beneficiary-request-status"
                  :class="'flx-'+request.status">
                {{ $t('flexben.beneficiary_pages.requests.status.' + request.status) }}
          </span>

          <span :id="'reject_tooltip_'+index"
                v-if="['rejected', 'validation_failed'].includes(request.status)"
                class="d-inline-block flexben-tooltip-icon flexben-tooltip-icon-red ml-2">i</span>
          <b-tooltip :target="'reject_tooltip_'+index"
                     variant="flexben"
                     placement="topleft"
                     triggers="hover"
                     v-if="['rejected', 'validation_failed'].includes(request.status)">
            {{ request.status === 'validation_failed' ? getValidationFailedMessage(request.reject_reason)  : getRejectMessage(request.reject_reason) }}
          </b-tooltip>

        </td>
        <td class="text-center">
          <button class="btn btn-themed-o width-auto p-1"
                  v-if="request.status==='new' && request.type === 'top_up' && request.benefit_sub_type!=='voucher'"
                  @click="confirmCancel(index)">{{ $t("flexben.general.btn.cancel") }}
          </button>
        </td>
      </tr>
      </tbody>
      <tbody v-else :key="'list_table'">
      <tr class="gri-cell text-center trhover">
        <td colspan="100" class="text-center">{{ $t("flexben.general.no_results_found") }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {BTooltip} from "bootstrap-vue";

export default {
  name: "RequestsTable",
  components: {
    "b-tooltip": BTooltip,
  },
  props: {
    sortField: {
      require: true,
      type: String
    },
    reverseSort: {
      require: true,
      type: Boolean
    },
    loading: {
      require: true,
      type: Boolean
    },
    requests: {
      require: true,
      type: Object,
      default() {
        return {
          data: []
        };
      }
    },
    companyOptions: {
      require: true,
      type: Object,
      default() {
        return {
          data: {
            cam_budget_taxation: false,
            gross_budget_taxation: false,
          }
        };
      }
    },
    enableSort: {
      require: true,
      type: Boolean,
      default: true
    },
  },
  methods: {
    sortBy(field) {
      if (this.enableSort) {
        this.$emit('sortBy', field);
      }
    },
    getRejectMessage(obj) {
      if (obj === null || obj.message === null || obj.message.length === 0) {
        return this.$t('flexben.general.no_reason_specified');
      }

      return obj.message;
    },

    getValidationFailedMessage(obj){
      if( typeof obj.message == 'undefined' || obj.message == null){
        return this.$t('flexben.up_ro_validation_messages.UNKNOWN');
      }
      let message = '', description  = '';

      message = obj.message.replaceAll('.','_');

      if ( typeof obj.description != 'undefined' || obj.description != null ){
        description = obj.description;
      }

      return this.$t('flexben.up_ro_validation_messages.'+message+'')+' '+description;
    },

    confirmCancel(index) {
      this.$emit('confirmCancel', index);
    },
    calculateTotal(item) {
      let returnVal = item.total_value;
      if (item.is_gross_taxed) {
        returnVal += item.all_taxes;
      }
      if (item.is_cam_in_value) {
        returnVal+=item.value_cam
      }
      return returnVal;
    }
  }
}
</script>
