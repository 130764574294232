<template>
  <div>
    <a class="btn text-themed go-back" @click="goBack()">{{ $t("flexben.general.go_back") }}</a>
    <div v-show="!initialLoading">
      <div class="row">
        <div class="col-12 col-xl-6 flexben-page-title d-inline-flex">
          <h1 class="m-0">{{ (formAction==='create') ? $t('flexben.offers.new') : $t('flexben.offers.edit') }}</h1>
          <div v-if="(formAction==='update')" class="p-0 d-inline-flex ml-2">
            <span class="offer-status my-auto" :class="'offer-status-'+formData.status">{{ $t("flexben.offers.statuses."+formData.status) }}</span>
          </div>
        </div>
        <div class="col-12 col-xl-6 text-right">
          <button :disabled="activate_preview" class="btn btn-themed px-4" v-if="(formAction==='create')"
                  @click="modalAct('preview')">
            {{$t("flexben.general.btn.preview")}}
          </button>
          <button class="btn text-danger px-4" v-if="(formAction==='update') && !affiliatePartnerId"
                  @click="modalAct('delete')">
            <font-awesome-icon icon="trash-alt"/>
            {{$t("flexben.general.btn.delete")}}
          </button>
          <button class="btn text-danger px-4"
                  v-if="(formAction==='update' && formData.status !== 'rejected') && !affiliatePartnerId && this.formData.partner_type === 'affiliated'"
                  @click="modalAct('rejected')">
            <font-awesome-icon icon="ban"/>
            {{$t("flexben.general.btn.reject")}}
          </button>
          <button class="btn text-themed px-4"
                  v-if="(formAction==='create' || (formAction==='update' && formData.status==='draft') && !affiliatePartnerId)
                   || (affiliatePartnerId && formAction==='create')"
                  @click="modalAct('draft')">{{ $t('flexben.general.btn.save_draft') }}
          </button>
          <button class="btn btn-themed px-4"
                  @click="modalAct((formData.status==='draft' || formAction==='create') ? 'publish': 'save')">
            {{ (formData.status==='active' && formAction==='update') ? $t('flexben.general.btn.save') :
            $t('flexben.general.btn.publish') }}
          </button>
        </div>
      </div>
      <div class="row my-1" v-if="checkNotEmptyObj(errorsBag)">
        <div class="col-12">
          <div class="alert alert-danger alert-dismissible mb-0">
            <button type="button" class="close" data-dismiss="alert" @click="closeErrorButton = false">&times;</button>
            <form-error-list :errorBag="errorsBag"></form-error-list>
          </div>
        </div>
      </div>
      <div class="card container-bg-white mt-3">
        <div class="row pt-3 px-3">
          <div class="col-12">
            <h3 class="mt-0 pt-1 pl-0">{{ $t("flexben.general.details") }}</h3>
          </div>
        </div>
        <div class="row px-3 pb-3">
          <div class="col-12 col-xl-4 d-flex flex-column justify-content-center align-items-start mb-3 mb-xl-0 mx-auto">
            <div class="image-container cursor-pointer d-flex align-items-center mx-auto"
                 @click="$refs.offer_image.click()">
              <div class="image-changed" v-if="imageChanged.image">{{$t("flexben.general.image_changed")}}</div>
              <img class="img-fluid cursor-pointer upload-image"
                   :class="{'img-border-danger':(Object.keys(errorsBag).includes('image'))}"
                   :src="(formData.image!==null) ? image_preview.image : formData.image_url"/>
              <div class="upload-image-middle-container my-auto">
                <div class="upload-image-text">{{$t("flexben.general.upload_image")}}</div>
              </div>
              <input type="file" class="hide" ref="offer_image" accept=".png,.jpeg,.jpg,.svg,.jfif"
                     @change="setFile('offer_image','image', 'image')">
            </div>
            <div class="mx-auto d-flex flex-row align-items-center my-3">

              <span id="image_tooltip"
                    class="d-inline-block flexben-tooltip-icon mr-2 text-bold">i</span>
              <b-tooltip :target="'image_tooltip'" variant="flexben" placement="topleft">
                <div class="text-left">{{ $t("flexben.offers.tooltips.image") }}</div>
              </b-tooltip>
              <h6 class="mt-0 pt-0 mb-0 pl-0 form-fields-required">
                {{ $t("flexben.offers.fields.mandatory_image")}}
              </h6>


            </div>
          </div>
          <div class="col-12 col-xl-8">
            <div class="row">
              <div class="col-12 col-md-6 form-group" v-for="(obj, index) in formData.name" :key="'name_'+index">
                <label :for="'name_'+index" class="col-form-label pt-0"  :class="{'form-fields-required': !(affiliatePartnerId && obj.lang_code==='en')}">{{
                  $t("flexben.offers.fields.name")
                  }} - {{obj.lang_code}}</label>
                <input :id="'name_'+index" type="text" class="form-control"
                       v-model="obj.value"
                       :class="{'border-danger':(Object.keys(errorsBag).includes('name|'+obj.lang_code))}">
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6 form-group">
                <label for="county_availability" class="form-fields-required col-form-label pt-0">
                  {{ $t("flexben.offers.fields.county_availability")}}</label>
                <select name="county_availability" id="county_availability" class="form-control"
                        v-model="county_availability">
                  <option v-for="value in options.county_availability"
                          :key="'ca_'+value"
                          :value="value">{{$t('flexben.offers.availability.'+value)}}
                  </option>
                </select>
              </div>
              <div class="col-12 col-md-6 form-group-multiselect">
                <label for="counties" class="col-form-label pt-0"
                       :class="{'text-bold':(county_availability==='specific')}">
                  {{ $t("flexben.offers.fields.counties")}}</label>

                <multiselect :multiple="true"
                             :showLabels="false"
                             :class="{'from-control':true, 'disabled':(county_availability!=='specific'), 'border-danger':(Object.keys(errorsBag).includes('selected_counties'))}"
                             label="name"
                             trackBy="value"
                             id="counties"
                             :closeOnSelect="false"
                             :disabled="(county_availability!=='specific')"
                             :placeholder="$t('flexben.general.search')"
                             v-model="selected_counties" :options="options.counties">
                </multiselect>
              </div>
              <div class="col-12 col-md-6 form-group">
                <label for="brand_availability" class="form-fields-required col-form-label pt-0">
                  {{ $t("flexben.offers.fields.brand_availability")}}
                </label>
                <select
                  name="brand_availability"
                  id="brand_availability"
                  class="form-control"
                  v-model.number="formData.brand_id"
                >
                  <option
                    v-for="value in options.brands"
                    :key="'ba_'+value.id"
                    :value="value.id"
                  >
                    {{value.name}}
                  </option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6 form-group">
                <label for="company_availability" class="form-fields-required col-form-label pt-0">
                  {{ $t("flexben.offers.fields.company_availability")}}</label>
                <select name="company_availability" id="company_availability" class="form-control"
                        :disabled="affiliatePartnerId || selected_category_type === 'client_scoped'"
                        v-model="company_availability">
                  <option v-for="value in options.company_availability"
                          :key="'ca_'+value"
                          :value="affiliatePartnerId ? company_availability : value">
                    {{$t('flexben.offers.company_availability.'+value)}}
                  </option>
                </select>
              </div>

              <div class="col-12 col-md-6 form-group">
                <label for="location_availability" class="col-form-label pt-0">
                  {{ $t("flexben.offers.fields.available_locations")}}</label>
                <select name="company_availability" id="location_availability" class="form-control"
                        v-model="formData.location_availability">
                  <option v-for="value in options.location_availability"
                          :key="'ca_'+value"
                          :value="value">{{$t('flexben.offers.location_availability.'+value)}}
                  </option>
                </select>
              </div>
              <div v-if="!affiliatePartnerId" class="col-12 col-md-6 form-group-multiselect">
                <div v-if="selected_category_type !== 'client_scoped'">
                  <label for="companies" class="col-form-label pt-0"
                         :class="{'text-bold':(county_availability==='specific')}">
                    {{ $t("flexben.offers.fields.companies")}}</label>

                  <multiselect :multiple="true"
                               :showLabels="false"
                               :class="{'from-control':true, 'disabled':(county_availability!=='specific'), 'border-danger':(Object.keys(errorsBag).includes('selected_companies'))}"
                               label="name"
                               trackBy="value"
                               id="companies"
                               :closeOnSelect="false"
                               :disabled="(!company_availability)"
                               :placeholder="$t('flexben.general.search')"
                               v-model="selected_companies" :options="options.companies">
                  </multiselect>
                </div>
              </div>
            </div>
            <div class="row">

              <div class="col-12 col-md-6 form-group mt-2">
                <label for="category_type" class="form-fields-required col-form-label pt-0">
                  {{ $t("flexben.categories.filters.type")}}</label>
                <span id="category_instructions_tooltip"
                      class="d-inline-block flexben-tooltip-icon ml-2 text-bold">i</span>
                <b-tooltip :target="'category_instructions_tooltip'" variant="flexben" placement="topleft">
                  <div class="text-left">{{ $t("flexben.benefit.tooltips.category") }}</div>
                </b-tooltip>
                <select name="type" id="category_type" class="form-control"
                        v-model="selected_category_type">
                  <option :value="null"> - </option>
                  <option v-for="(item) in options.category_types"
                          :key="item"
                          :value="item">{{$t("flexben.categories.filters." + item)}}
                  </option>
                </select>
              </div>
            </div>
            <div class="row">
              <div v-if="selected_category_type" class="col-12 col-md-6 form-group-multiselect">
                <label for="category" class="col-form-label pt-0 form-fields-required">
                  {{ $t("flexben.offers.fields.category")}}<span v-if="formData.promoted_as_new"
                                                                 class="mr-3 text-success bold"> - {{ $t('flexben.offers.promoted_as_new') }}</span></label>
                <multiselect v-if="selected_category_type === 'general'" :multiple="true"
                             :showLabels="false"
                             :class="{'from-control':true, 'border-danger':(Object.keys(errorsBag).includes('categories'))}"
                             label="name"
                             trackBy="value"
                             id="category"
                             :closeOnSelect="false"
                             :placeholder="$t('flexben.general.search')"
                             v-model="selected_categories_general" :options="translated_categories">
                </multiselect>
                <multiselect v-else :multiple="true"
                             :showLabels="false"
                             :class="{'from-control':true, 'border-danger':(Object.keys(errorsBag).includes('categories'))}"
                             label="name"
                             trackBy="value"
                             id="category"
                             :closeOnSelect="false"
                             :placeholder="$t('flexben.general.search')"
                             v-model="selected_categories_clients" :options="translated_categories_client">
                </multiselect>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6 form-group">
                <label for="type" class="form-fields-required col-form-label pt-0">
                  {{ $t("flexben.offers.fields.type")}}</label>
                <select name="type" id="type" class="form-control"
                        :class="{'border-danger':(Object.keys(errorsBag).includes('type'))}"
                        v-model="formData.type">
                  <option :value="null"></option>
                  <option v-for="(type, value) in options.types"
                          :key="'type_'+value"
                          :value="type">{{$t('flexben.offers.types.'+type)}}
                  </option>
                </select>
              </div>
            </div>
            <div class="row">
              <div v-if="!affiliatePartnerId" class="col-12 col-md-6 form-group">
                <label for="partner_name" class="col-form-label pt-0">
                  {{ $t("flexben.offers.fields.partner")}}</label>

                <input v-if="formData.partner_type === 'affiliated'" id="partner_name" type="text" class="form-control"
                       disabled
                       v-model="formData.partner_name"
                       :class="{'border-danger':(Object.keys(errorsBag).includes('code_value'))}">
                <select v-else name="category" id="partner_name" class="form-control"
                        :class="{'border-danger':(Object.keys(errorsBag).includes('partner'))}"
                        v-model="formData.partner_id">
                  <option :value="null">-</option>
                  <option v-for="(item, index) in options.partners"
                          :key="index"
                          :value="item.id">{{ item.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row">
              <div v-if="!affiliatePartnerId" class="col-12 col-md-6">
                <div class="form-group">
                  <label for="code_type" class="form-fields-required col-form-label pt-0">
                    {{ $t("flexben.offers.fields.code_type")}}</label>
                  <input v-if="formData.partner_type === 'affiliated'" id="code_type" type="text" class="form-control"
                         disabled
                         :value="formData.code_type">
                  <select v-else name="county_availability" id="code_type" class="form-control"
                          v-model="code_type">
                    <option v-for="code in options.code_types"
                            :key="'ct_'+code"
                            :value="code">{{$t('flexben.offers.code_types.'+code)}}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-md-6 form-group" v-show="(code_type==='text')">
                <label for="code_value_p" class="form-fields-required col-form-label pt-0">
                  {{ $t("flexben.offers.fields.code_value_t")}}</label>
                <input id="code_value_p" type="text" class="form-control"
                       :disabled="this.formData.partner_type === 'affiliated' && !this.affiliatePartnerId"
                       v-model="formData.code_value"
                       :class="{'border-danger':(Object.keys(errorsBag).includes('code_value'))}">
              </div>
              <div class="col-12 col-md-6 form-group overflow-hidden mb-0" v-show="(code_type==='file')">
                <label for="code_value_f" class="form-fields-required col-form-label pt-0">
                  {{ $t("flexben.offers.fields.code_value_f")}}</label>
                <input type="file" ref="code_value_file" accept="application/pdf"
                       size="2"
                       id="code_value_f" class="form-control p-1"
                       :class="{'border-danger':(Object.keys(errorsBag).includes('code_value'))}"
                       @change="setFile('code_value_file','file','code_value')">
                <a v-if="(formAction==='update' && formData.code_value_url!==null)"
                   :href="formData.code_value_url" class="mt-1 text-themed w-100 d-block"
                   target="_blank">
                  <font-awesome-icon icon="download"/>
                  {{ $t("flexben.offers.fields.download_current")}}</a>
              </div>
            </div>
          </div>
        </div>
        <hr>

        <div class="row px-3 mt-3">
          <div class="col-xl-4">
            <h3 class="mt-0 pt-0 pl-0">{{ $t("flexben.offers.fields.date_availability") }}</h3>
          </div>
          <div class="col-12 col-md-6 col-xl-4 form-group">
            <label class="col-form-label pt-0 text-bold form-fields-required"
                   for="start_use_date">{{$t("flexben.offers.fields.start_date")}}</label>
            <div class="w-100 d-inline-flex">
              <label for="start_use_date" class="my-auto mr-2 col-form-label">
                <font-awesome-icon icon="calendar-alt" class="text-24 "/>
              </label>
              <datepicker :format="dateFormat"
                          :inputClass="{'form-control-d m-0 w-100 background-white':true, 'border-danger':Object.keys(errorsBag).includes('start_use_date')}"
                          v-model="formData.start_use_date"
                          :class="{'w-100':true}"
                          :key="1"
                          :disabledDates="disableUntil"
                          @closed="function (){formData.end_use_date=null}"
                          id="start_use_date"></datepicker>
              <img class="cursor-pointer ml-2" alt="x" src="@/assets/images/flexben/table-remove-button.svg"
                   @click="formData.start_use_date=null"/>
            </div>
          </div>
          <div class="col-12 col-md-6 col-xl-4 form-group">
            <label class="col-form-label pt-0 text-bold"
                   for="end_use_date">{{$t("flexben.offers.fields.end_date")}}</label>
            <div class="w-100 d-inline-flex">
              <label for="end_use_date" class="my-auto mr-2 col-form-label">
                <font-awesome-icon icon="calendar-alt" class="text-24 "/>
              </label>
              <datepicker :format="dateFormat"
                          :inputClass="{'form-control-d m-0 w-100 background-white':true, 'border-danger':Object.keys(errorsBag).includes('end_use_date')}"
                          v-model="formData.end_use_date"
                          :class="{'w-100':true}"
                          :key="2"
                          :disabledDates="(formData.start_use_date!==null) ? {to:formData.start_use_date} : disableUntil"
                          id="end_use_date"></datepicker>
              <img class="cursor-pointer ml-2" alt="x" src="@/assets/images/flexben/table-remove-button.svg"
                   @click="formData.end_use_date=null"/>
            </div>
          </div>
        </div>
        <hr>
        <div class="row px-3">
          <div class="col-12 col-xl-4">
            <div class="d-flex align-items-center">
              <h3 class="mt-0 pt-0 mb-0 pl-0">{{ $t("flexben.offers.fields.description") }}</h3>

              <span id="offer_description_tooltip"
                    class="d-inline-block flexben-tooltip-icon ml-2 text-bold">i</span>
              <b-tooltip :target="'offer_description_tooltip'" variant="flexben" placement="topleft">
                <div class="text-left">{{ $t("flexben.offers.tooltips.description") }}</div>
              </b-tooltip>
            </div>
          </div>
          <div class="col-12 col-xl-8">
            <div class="row">
              <div class="col-12" v-for="(obj, index) in formData.description" :key="'de_'+index">
                <label :for="'description_'+index" class="col-form-label pt-0"
                       :class="{'form-fields-required': !(affiliatePartnerId && obj.lang_code==='en')}">{{
                  $t("flexben.offers.fields.description")
                  }} - {{obj.lang_code}}</label>
                <v-editor :id="'description_'+index" rows="5"
                          class="form-control-textarea w-100 my-0"
                          v-model="obj.value"
                          :placeholder="$t('flexben.offers.fields.description_placeholder')"
                          :editorToolbar="editorToolbar"
                          :class="{'border-danger':(Object.keys(errorsBag).includes('description|'+obj.lang_code))}">
                </v-editor>
                <p> {{obj.value.length}} / 3000</p>
              </div>
            </div>
          </div>
        </div>
        <hr>
        <div class="row px-3">
          <div class="col-12 col-xl-4">
            <div class="d-flex align-items-center">
              <h3 class="mt-0 pt-0 mb-0 pl-0">{{ $t("flexben.offers.fields.usage_instructions") }}</h3>

              <span id="offer_instructions_tooltip"
                    class="d-inline-block flexben-tooltip-icon ml-2 text-bold">i</span>
              <b-tooltip :target="'offer_instructions_tooltip'" variant="flexben" placement="topleft">
                <div class="text-left">{{ $t("flexben.offers.tooltips.instructions") }}</div>
              </b-tooltip>
            </div>
          </div>
          <div class="col-12 col-xl-8">
            <div class="row">
              <div class="col-12" v-for="(obj, index) in formData.usage_instructions"
                   :key="'ui_'+index">
                <label :for="'usage_instructions_'+index" class="col-form-label pt-0"
                       :class="{'form-fields-required': !(affiliatePartnerId && obj.lang_code==='en')}">{{
                  $t("flexben.offers.fields.usage_instructions")
                  }} - {{obj.lang_code}}</label>

                <v-editor :id="'usage_instructions_'+index" rows="6"
                          class="form-control-textarea w-100 my-0"
                          v-model="obj.value"
                          :editorToolbar="editorToolbar"
                          :placeholder="$t('flexben.offers.fields.instructions_placeholder')"
                          :class="{'border-danger':(Object.keys(errorsBag).includes('usage_instructions|'+obj.lang_code))}">
                </v-editor>
                <p> {{obj.value.length}} / 3000</p>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="row my-3">
        <div class="col-12 text-right">
          <button class="btn text-danger px-4" v-if="(formAction==='update' && !affiliatePartnerId)"
                  @click="modalAct('delete')">
            <font-awesome-icon icon="trash-alt"/>
            {{$t("flexben.general.btn.delete")}}
          </button>
          <button :disabled="activate_preview" class="btn btn-themed px-4" v-if="(formAction==='create')"
                  @click="modalAct('preview')">
            {{$t("flexben.general.btn.preview")}}
          </button>
          <button class="btn text-themed px-4"
                  v-if="(formAction==='create' || (formAction==='update' && formData.status==='draft'))"
                  @click="modalAct('draft')">{{ $t('flexben.general.btn.save_draft') }}
          </button>
          <button class="btn btn-themed px-4"
                  @click="modalAct((formData.status==='draft' || formAction==='create') ? 'publish': 'save')">
            {{ (formData.status==='active') ? $t('flexben.general.btn.save') : $t('flexben.general.btn.publish') }}
          </button>
        </div>
      </div>
    </div>
    <offer-preview-modal ref="offer-preview-modal" :offer="preview.offer" @publish="publish"
                         :locale="preview.locale"></offer-preview-modal>
    <confirm-modal :openModal="modal.open"
                   :modalAction="modal.action"
                   :formSuccess="modal.formSuccess"
                   :endMessage="modal.endMessage"
                   :options="modal.options"
                   :isAffiliatePartner="!!affiliatePartnerId"
                   @closeModal="closeModal"
                   @confirmAction="confirmAction"/>
    <content-loader :loaderVisible="loading" :loaderError="loaderError"></content-loader>
  </div>
</template>

<script>
import i18n from "@/plugins/language/i18n";
import httpFlexbenService from "@/http/http-flexben";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import ConfirmModal from "@/components/flexben/offers/modals/ConfirmModal";
import FormErrorList from "@/components/fragments/FormErrorList";
import {VueEditor} from "vue2-editor";
import OfferPreviewModal from "@/components/flexben/offers/modals/OfferPreviewModal";
import {BTooltip} from "bootstrap-vue";
import vueEditorSettings from '@/constants/vue_editor_settings';
import form from "@/components/flexben/brands/Form.vue";

export default {
  name: "OfferDetails",
  components: {
    datepicker: Datepicker,
    'confirm-modal': ConfirmModal,
    'offer-preview-modal': OfferPreviewModal,
    "form-error-list": FormErrorList,
    'v-editor': VueEditor,
    'b-tooltip': BTooltip
  },
  props: {
    formAction: {
      type: String,
      required: true
    },
    itemId: {
      type: Number,
    },
    affiliatePartnerId: {
      type: String,
      default: null,
      required: false
    },
    affiliatedPartner: {
      type: Object,
      default: null
    }
  },
  data: function () {
    return {
      initialLoading: true,
      loading: false,
      loaderError: false,
      showForm: false,
      isFormModified: false,
      locale: i18n.locale,
      httpService: httpFlexbenService.offerService,
      brandHttpService: httpFlexbenService.brandService,
      modal: {
        action: '',
        subOperation: '',
        open: false,
        formSuccess: false,
        endMessage: '',
        options: {
          saveAs: '',
          statusChange: '',
        }
      },
      preview: {
        locale: moment.locale(localStorage.language),
        offer: {}
      },
      dateFormat: 'dd/MM/yyyy',
      disableUntil: {to: moment().startOf('day').toDate()},

      formData: {
        id: null,
        partner_id: null,
        partner_type: '',
        partner_name: '',
        status: null,
        promoted_as_new: false,
        type: null,
        county_availability: 'all',
        selected_counties: [],
        selected_category_type : null,
        company_availability: 0,// selected first always
        category_type: null,
        location_availability: 0,
        selected_companies: [],
        selected_clients: [],
        selected_categories: [],
        code_type: 'text',
        code_value: null,
        name: [],
        description: [],
        usage_instructions: [],
        start_use_date: null,
        end_use_date: null,
        image: null,
        image_url: httpFlexbenService.globalVariablesService.baseImagePreview,
        code_value_url: null,
        brand_id: null,
      },
      county_availability: 'all',
      partner_type: '',
      selected_counties: [],
      selected_categories_general: [],
      selected_categories_clients: [],
      selected_category_type: null,
      company_availability: 0,// selected first always
      // location_availability: 0,
      selected_companies: [],
      code_type: 'text',
      options: {
        county_availability: ['all', 'specific'],
        company_availability: [0, 1],
        code_types: ['text', 'file'],
        partner_types: [],
        available_languages: [],
        location_availability: [],
        counties: [],
        companies: [],
        brands: [],
        category_types: [],
        categories: [],
        categories_client: [],
        partners: [],
        types: [],
      },
      errorsBag: {},
      imageChanged: {
        image: false,
      },
      image_preview: {
        image: null,
      },
      editorToolbar: vueEditorSettings.editorToolbar
    }
  },
  computed: {
    translated_categories: function () {
      return this.options.categories.map((item) => {
        return {
          id: item.id,
          value: item.id,
          name: item.name[this.locale],
        }
      });
    },
    translated_categories_client: function () {
      return this.options.categories_client.map((item) => {
        return {
          id: item.id,
          value: item.id,
          name: item.name[this.locale],
        }
      });
    },
    activate_preview: function () {
      if (this.formData.name.length && this.formData.description.length &&
        this.formData.usage_instructions.length && this.formData.start_use_date && this.formData.end_use_date
        && this.formData.code_value && this.formData.type && this.formData.image) {
        return false
      }

      return true;
    }
  },
  watch: {
    selected_category_type: {
      handler: function (type) {
        this.selected_categories = [];

        if (type === 'client_scoped') {
          this.formData.company_availability = 0;
        }
      }
    },
    selected_counties: {
      deep: true,
      handler: function (values) {
        let items = [];
        for (let index in values) {
          items.push(parseInt(values[index].value));
        }
        this.formData.selected_counties = items;
      }
    },
    selected_categories_clients: {
      deep: true,
      handler: function (values) {
        let items = [];
        for (let index in values) {
          items.push(parseInt(values[index].value));
        }
        this.formData.selected_categories = items;
      }
    },
    selected_categories_general: {
      deep: true,
      handler: function (values) {
        let items = [];
        for (let index in values) {
          items.push(parseInt(values[index].value));
        }
        this.formData.selected_categories = items;
      }
    },
    selected_companies: {
      deep: true,
      handler: function (values) {
        let items = [];
        for (let index in values) {
          items.push(parseInt(values[index].value));
        }
        this.formData.selected_companies = items;
      }
    },
    county_availability: {
      deep: true,
      handler: function (value) {
        this.formData.county_availability = value;
        if (value === "all") {
          this.selected_counties = [];
          this.formData.selected_counties = [];
        }
      }
    },
    company_availability: {
      deep: true,
      handler: function (value) {
        this.formData.company_availability = value;
        if (value === 0) {
          this.selected_companies = [];
          this.formData.selected_companies = [];
        }
      }
    },
    code_type: {
      deep: true,
      handler: function (value) {
        this.formData.code_value = null;
        this.formData.code_type = value;
      }
    },
    formData: {
      deep: true,
      handler: function (value) {
        this.isFormModified = true;
      }
    }
  },
  created() {
    if (this.formAction === 'create') {
      this._getOptions();
    } else if (this.formAction === 'update' && this.itemId) {
      this._getOffer();
    } else {
      this.goBack();
    }
    this._getBrandList();
  },
  beforeUpdate() {
    this.locale = i18n.locale;
  },
  methods: {
    _getOptions() {
      this.loading = true;
      this.httpService.createOptions({aff_id: this.affiliatePartnerId})
        .then((response) => {
          this._setFormOptions(response.data);
          if (this.initialLoading) {
            this.initialLoading = false;
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error)
          this.loading = false;
        })
        .then(() => {
          this.isFormModified = false;
        });
    },
    _getOffer() {
      this.loading = true;

      this.httpService.details(this.itemId, {aff_id: this.affiliatePartnerId})
        .then((response) => {
          this._setFormOptions(response.data);
          if (this.initialLoading) {
            this.initialLoading = false;
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        })
        .then(() => {
          this.isFormModified = false;
        });
    },
    _getBrandList() {
      this.brandHttpService.all().then((response) => {
        response.data.forEach((value) => {
          this.options.brands.push(value);
        });
      }).catch(() => {
        this.initialLoading = false;
        this.loading = false;
      });
    },
    goBack() {
      if (this.isFormModified) {
        this.modalAct('go_back')
      } else {
        this.loading = true;
        this._clearForm();
        this.loading = false;
        this.$emit('clearForm');
      }
    },
    setFile(refTarget, fileType, formKey) {
      this.errorsBag = {};
      if (fileType === 'image') {
        if (this.$refs[refTarget].accept.indexOf(this.$refs[refTarget].files[0].type.split('/')[1]) > 0) {
          let reader = new FileReader();
          reader.readAsDataURL(this.$refs[refTarget].files[0]);
          reader.onload = evt => {
            let img = new Image();
            img.onload = () => {
              if (img.width !== img.height) {
                this.errorsBag = {image: 'image_square'};
                this.scrollToTop();
              } else if (img.width !== 500 || img.height !== 500) {
                this.errorsBag = {image: 'image_500_500'};
                this.scrollToTop();
              } else {
                this.formData[formKey] = this.$refs[refTarget].files[0];
                this.imageChanged[formKey] = true;
                if (typeof this.formData[formKey] !== "undefined") {
                  this.image_preview[formKey] = URL.createObjectURL(this.formData[formKey]);
                }
              }
            }
            img.src = evt.target.result;
          }
        } else {
          this.errorsBag = {image: 'image_type_supported'}
        }
      } else if (fileType === 'file') {
        if (typeof this.$refs[refTarget].files[0] !== 'undefined') {
          delete this.errorsBag.code_value;

          if (this.$refs[refTarget].files[0].size < 10000000) {
            this.formData[formKey] = this.$refs[refTarget].files[0];
          } else {
            this.errorsBag[formKey] = 'exceeds_10_mb';
            this.scrollToTop();
          }
        }
      }
    },
    modalAct(action, statusToChange = '') {
      if (action === 'draft' || action === 'publish' || action === 'rejected'
        || action === 'save' || action === 'active') {
        this.modal.action = 'form_submit';
        this.modal.subOperation = 'form_submit';
        this.modal.options.saveAs = action;
      } else if (action === 'status_change') {
        this.modal.action = action;
        this.modal.options.statusChange = statusToChange;
      } else if (action === 'preview') {
        this._prepareForm();
        this.preview.offer = this.formData;
        this.openPreview();
        return;
      } else {
        this.modal.action = action;
      }
      this.modal.open = true;
    },
    _prepareForm() {
      let formData = new FormData();
      formData.append('image', this.formData.image);
      let name = {},
        description = {},
        usage_instructions = {},
        index = 0;
      for (index in this.formData.name) {
        name[this.formData.name[index].lang_code] = this.formData.name[index].value;
      }
      formData.append('name', JSON.stringify(name));

      index = 0;
      for (index in this.formData.description) {
        description[this.formData.description[index].lang_code] = this.formData.description[index].value;
      }
      formData.append('description', JSON.stringify(description));

      index = 0;
      for (index in this.formData.usage_instructions) {
        usage_instructions[this.formData.usage_instructions[index].lang_code] = this.formData.usage_instructions[index].value;
      }
      formData.append('usage_instructions', JSON.stringify(usage_instructions));

      formData.append('status', this.formData.status);
      formData.append('county_availability', this.formData.county_availability);
      formData.append('company_availability', this.formData.company_availability);
      if (this.selected_category_type === 'general') {
        formData.append('category_types', 'all');
      }
      if (this.selected_category_type === 'client_scoped') {
        formData.append('category_types', 'specific');
      }
      if (this.selected_category_type === null) {
        formData.append('category_types', null);
      }
      formData.append('location_availability', this.formData.location_availability);
      formData.append('selected_counties', JSON.stringify(this.formData.selected_counties));
      formData.append('selected_companies', JSON.stringify(this.formData.selected_companies));
      formData.append('selected_categories', JSON.stringify(this.formData.selected_categories));
      formData.append('code_type', this.formData.code_type);
      formData.append('reason', this.formData.reason);
      formData.append('partner_id', this.formData.partner_id);
      formData.append('partner_type', this.formData.partner_type);
      formData.append('code_value', this._checkAndFormatEmptyCodeValue(this.formData.code_value));
      formData.append('start_use_date', (this.formData.start_use_date !== null) ? moment(this.formData.start_use_date).format('YYYY-MM-DD') : null);
      formData.append('end_use_date', (this.formData.end_use_date !== null) ? moment(this.formData.end_use_date).format('YYYY-MM-DD') : null);
      formData.append('type', this.formData.type);
      formData.append('brand_id', this.formData.brand_id);
      return formData;
    },
    _checkAndFormatEmptyCodeValue(value) {
      return value ? value : "";
    },
    _setFormOptions(data) {
      this.options.available_languages = data.form_options.available_languages;
      this.options.location_availability = data.form_options.location_availability;
      this.options.code_types = data.form_options.code_types;
      this.options.types = data.form_options.types;

      let counties = [];
      for (let obj of data.form_options.counties) {
        counties.push({
          name: obj.name,
          value: obj.id,
        });
      }
      this.options.counties = counties;
      let companies = [];
      for (let obj of data.form_options.companies) {
        companies.push({
          name: obj.name,
          value: obj.id,
        });
      }
      this.options.companies = companies;
      this.options.categories = data.form_options.categories;
      this.options.categories_client = data.form_options.categories_client;
      this.options.category_types = data.form_options.category_types;
      this.options.partners = data.form_options.partners;
      let objToPush, langCode;
      let formData = {...this.formData};
      for (let index in data.form_options.available_languages) {
        langCode = data.form_options.available_languages[index];
        if (this.formAction === 'create') {
          objToPush = {
            lang_code: langCode,
            value: ''
          };
          formData.name.push({...objToPush});
          formData.description.push({...objToPush});
          formData.usage_instructions.push({...objToPush});
        } else {
          formData.name.push({
            lang_code: langCode,
            value: data.item.name[langCode]
          });
          formData.description.push({
            lang_code: langCode,
            value: data.item.description[langCode]
          });
          formData.usage_instructions.push({
            lang_code: langCode,
            value: data.item.usage_instructions[langCode]
          });
        }
      }
      if (this.formAction === 'update') {
        formData.id = data.item.id;
        formData.brand_id = data.item.brand_id;
        formData.partner_id = data.item.partner_id;
        formData.partner_type = data.item.partner_type;
        formData.partner_name = data.item.partner.name;
        formData.type = data.item.type;
        formData.county_availability = data.item.county_availability;
        this.county_availability = data.item.county_availability;
        formData.location_availability = data.item.location_availability;
        formData.company_availability = data.item.company_availability;
        this.company_availability = data.item.company_availability;
        formData.status = data.item.status;
        formData.promoted_as_new = data.item.promoted_as_new;
        formData.code_type = data.item.code_type;
        this.code_type = data.item.code_type;
        if (formData.code_type === 'text') {
          formData.code_value = data.item.code_value;
        } else if (formData.code_type === 'file') {
          formData.code_value_url = data.item.code_value_url;
        }
        formData.start_use_date = moment(data.item.start_use_date).startOf('day').toDate();
        formData.end_use_date = moment(data.item.end_use_date).startOf('day').toDate();
        if (data.item.image_url) {
          formData.image_url = data.item.image_url;
        }

        formData.selected_counties = data.item.selected_counties;
        formData.selected_companies = data.item.selected_companies;
        formData.selected_categories = data.item.selected_categories;
        formData.category_type = data.item.category_types;

        this.formData = formData;
        this.selected_counties = (formData.county_availability === 'all') ? [] : this.options.counties.filter((item) => {
          return data.item.selected_counties.includes(item.value);
        });
        this.selected_companies = (formData.company_availability === 0) ? [] : this.options.companies.filter((item) => {
          return data.item.selected_companies.includes(item.value);
        });

        this.selected_categories_general = this.options.categories
          .filter((item) => {
            return data.item.selected_categories.includes(item.value);
          })
          .map((item) => {
            return {
              id: item.id,
              value: item.id,
              name: item.name[this.locale],
            }
          });

        this.selected_categories_clients = this.options.categories_client
          .filter((item) => {
            return data.item.selected_categories.includes(item.value);
          })
          .map((item) => {
            return {
              id: item.id,
              value: item.id,
              name: item.name[this.locale],
            }
          });

        if (this.formData.category_type === 'all') {
          this.selected_category_type = 'general';
        }
        if (this.formData.category_type === 'specific') {
          this.selected_category_type = 'client_scoped';
        }
      }
    },
    _clearForm() {
      this.isFormModified = false;
      this.formData = {
        id: null,
        partner_id: null,
        partner_type: null,
        status: null,
        type: null,
        county_availability: 'all',
        category_types: null,
        location_availability: 0,
        selected_counties: [],
        code_type: 'text',
        code_value: null,
        name: [],
        description: [],
        usage_instructions: [],
        start_use_date: null,
        end_use_date: null,
        image: null,
        image_url: httpFlexbenService.globalVariablesService.baseImagePreview,
      };
    },
    closeModal() {
      this.modal.action = '';
      this.modal.options.statusChange = '';
      this.modal.options.saveAs = '';
      this.modal.open = false;
    },
    confirmAction(action, rejectReason = null) {
      this.modal.endMessage = '';
      if (action === 'go_back') {
        this._clearForm();
        this.goBack();
      }
      if (action === 'rejected') {
        this.formData.reason = rejectReason;
        this.modal.open = false;
        this._updateItem();
        this.closeModal();
      }
      if (action === 'form_submit') {
        this.loading = true;
        this.errorBag = {};
        this.modal.open = false;
        if (this.modal.action === 'form_submit') {
          if (this.formAction === 'update') {
            this._updateItem();
          } else {
            this._createItem();
          }
        }
      }
      if (this.modal.action === 'delete') {
        this._deleteItem();
      }
      if (action === 'end') {
        this.closeModal();
        if (this.modal.formSuccess) {
          this._clearForm();
          this.$emit('clearForm', true);
        }
      }
    },
    _createItem() {
      if (this.modal.options.saveAs === 'publish' || this.modal.options.saveAs === 'save') {
        this.formData.status = 'active';
      } else {
        this.formData.status = this.modal.options.saveAs;
      }
      this.httpService.create(this._prepareForm(), {aff_id: this.affiliatePartnerId})
        .then((response) => {
          this.modal.formSuccess = true;
          this.modal.action = 'end';
          this.loading = false;
          this.modal.open = true;
        })
        .catch((error) => {
          if (error.response.status === 400) {
            this.errorsBag = error.response.data.error_fields;
            this.modal.formSuccess = false;
            this.modal.endMessage = this.$t('flexben.general.validation_errors');
            this.modal.action = 'end';
            this.loading = false;
            this.modal.open = true;
            this.scrollToTop();
          } else {
            this.loading = false;
            this.loaderError = true;
          }
        });
    },
    _updateItem() {
      if (this.modal.options.saveAs !== this.formData.status) {
        if (this.modal.options.saveAs === 'publish' || this.modal.options.saveAs === 'save') {
          this.formData.status = 'active';
        } else {
          this.formData.status = this.modal.options.saveAs;
        }
      }

      this.httpService.update(this.itemId, this._prepareForm(), {aff_id: this.affiliatePartnerId})
        .then((response) => {
          this.modal.formSuccess = true;
          this.modal.action = 'end';
          this.loading = false;
          this.modal.open = true;
        })
        .catch((error) => {
          if (error.response.status === 400) {
            this.errorsBag = error.response.data.error_fields;
            this.modal.formSuccess = false;
            this.modal.endMessage = this.$t('flexben.general.validation_errors');
            this.modal.action = 'end';
            this.loading = false;
            this.modal.open = true;
            this.scrollToTop();
          } else {
            this.loading = true;
            this.loaderError = true;
          }
        });
    },
    _deleteItem() {
      this.loading = true;
      this.httpService.delete(this.itemId, {aff_id: this.affiliatePartnerId})
        .then((response) => {
          this.modal.formSuccess = true;
          this.modal.action = 'end';
          this.loading = false;
          this.modal.open = true;
        })
        .catch((error) => {
          if (error.response.status === 400) {
            this.modal.formSuccess = false;
            this.modal.action = 'end';
            this.loading = false;
            this.modal.open = true;
          } else {
            this.loading = false;
            this.loaderError = true;
          }
        });
    },
    _changeItemStatus() {
      this.httpService.changeStatus(this.itemId, {status: this.modal.options.statusChange})
        .then((response) => {
          this.formData.status = response.new_status;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    openPreview() {
      this.preview.offer.counties = this.options.counties;
      this.preview.offer.partner = this.affiliatedPartner;
      this.$refs['offer-preview-modal'].openModal(this.preview.offer, this.image_preview['image']);
    },
    publish() {
      this.modalAct('publish')
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

