<template>
  <div>
    <create-v1 v-if="this.$route.params.version === 'v1'"></create-v1>
    <create-v2 v-if="this.$route.params.version === 'v2'"></create-v2>
  </div>

</template>

<script>
import {default as CreateV1}  from "@/superUp-module/resources/views/pages/tree/v1/Create.vue";
import {default as CreateV2}  from "@/superUp-module/resources/views/pages/tree/v2/Create.vue";


export default {
  name: 'Create',
  components: {
    CreateV1,
    CreateV2
  }
}
</script>
<style lang="scss">
@use "@/superUp-module/assets/tree.scss";
</style>

