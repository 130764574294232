<template>
  <div>
    <b-modal centered :visible="show" @hide="closeModal"
             @hidden="updateShowProp" id="modal-1" ref="confirmationModal" hide-footer>
      <div class="d-block text-center mt-2 text-center mr-3 ml-3 mb-2">
          <h3>{{ $t("superUpLang.tree.import_modal.import") }}</h3>
        <label>{{ $t("superUpLang.tree.import_modal.select_type") }}</label>
        <div class="mb-4">
          <select class="form-control" name="ppp3_child" id="ppp3_child"
                  @change="select" v-model="selected">
            <option :value="item.value" v-for="item in items"
                    :key="item.value" v-html="item.name">
            </option>
          </select>
          <div class="invalid-feedback show" v-if="errors.type">
            {{ $t("superUpLang.tree.errors.type") }}
          </div>
        </div>
        <vue-dropzone
          ref="pdfRef"
          id="pdf"
          :options="dropzoneOptions"
          @vdropzone-file-added="onFileAdded"
        ></vue-dropzone>
        <div class="invalid-feedback show" v-if="errors.file">
          {{ $t("superUpLang.tree.errors.file") }}
        </div>
        <div class="mt-2">
          <label class="mb-0">{{ $t("superUpLang.tree.import_modal.password") }}</label>
          <input type="password" class="custom-password-input" v-model="password" >
          <div class="invalid-feedback show" v-if="errors.password">
            {{ $t("superUpLang.tree.errors.password") }}
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-sm-around">
        <b-button class="mt-3 w-30" variant="primary" @click="submit()">{{ $t("superUpLang.tree.buttons.save") }}</b-button>
        <b-button class="mt-3 w-30" variant="secondary" @click="closeModal">{{ $t("superUpLang.categories.buttons.cancel") }}</b-button>
      </div>
    </b-modal>
    <content-loader :loader-visible="this.loading" :loader-error="loaderError"></content-loader>
  </div>
</template>

<script>
import {BButton, BModal, VBModal} from "bootstrap-vue";
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import HttpTreeService from "@/superUp-module/resources/services/httpTreeService";
export default {
  components: {
    vueDropzone: vue2Dropzone,
    "b-modal": BModal,
    "b-button": BButton
  },
  directives: {
    "b-modal": VBModal
  },
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false
    },
    appVersion: {
      type: String
    }
  },
  watch: {
    show:function (val) {
      if (val) {
        this.setTypes();
      }

      this.items = null
    },
  },
  data() {
    return {
      items: null,
      password: null,
      selected: null,
      onChangeSelected: null,
      loading: false,
      loaderError: false,
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 150,
        acceptedFiles: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        maxFiles: 1,
        addRemoveLinks: true,
        dictDefaultMessage: 'Drag & drop a XLSX here or click to upload',
        autoProcessQueue: false
      },
      selectedFile: null,
      errors: {
        type: false,
        file: false,
        password: false
      }
    }
  },
  methods: {
    setTypes() {
      this.loading = true;
      HttpTreeService.getTypes().then(result => {
        // this.items =  result.data;
        this.items = [
          {
            name: 'Offers',
            value: 2
          }
        ]
        this.loading = false;
      })
    },
    onFileAdded(file) {
      // Capture the file when added to ensure it's available for the formData
      this.selectedFile = file;
    },
    submit() {
      if (!this.selectedFile) {
        this.errors.file = true
        return;
      }

      if (!this.password) {
        this.errors.password = true
        return;
      }

      let formData = new FormData();
      formData.set('type', this.onChangeSelected);
      formData.append('file', this.selectedFile);
      formData.append('password', this.password);
      formData.append('appVersion', this.appVersion)
      const __this = this;
      this.loading = true;
      // Send the formData to the server
      HttpTreeService.upload(formData).then(res => {
        window.location = '/super-up/tree';
      }).catch(function (error) {

        Object.keys(error.response.data).forEach((key) => {
          if (key === 'type') {
            __this.errors.type = true;
          }

          if (key === 'file') {
            __this.errors.file = true;
          }

          if (key === 'password') {
            __this.errors.password = true;
          }
          __this.loading = false;
        });
      });
    },
    select(event) {
      this.onChangeSelected = event.target.value;
    },
    closeModal() {
      this.password = null
      this.selected = null
      this.selectedFile = null
      this.$emit('update:show', false);
      this.$refs.confirmationModal.hide();
    },
    updateShowProp() {
      if (this.show) {
        this.$emit('update:show', false);
      }
    },
  }
}
</script>
