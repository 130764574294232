import Api from "./http.js";
class HttpTreeServices {
    prefix = 'superUp/admin/tree';

    api = Api.create();

    token = null;

    getToken() {
        return Api.token
    }

    getListUrl()
    {
        return `${this.api.defaults.baseURL}${this.prefix}`;
    }

    getTypes()
    {
        return this.api.get(`${this.prefix}/get-types`);
    }

    getVersions(appVersion)
    {
        return this.api.get(`${this.prefix}/get-tree-versions/${appVersion}`);
    }

    getTypeData()
    {
        return `${this.api.defaults.baseURL}${this.prefix}/get-types-data/`;
    }

    getBrandsData()
    {
        return `${this.api.defaults.baseURL}${this.prefix}/get-brands/`;
    }

    create(data)
    {
        return this.api.post(this.prefix, data)
    }

    delete(id)
    {
        return this.api.delete(`${this.prefix}/${id}`);
    }

    get(id)
    {
        return this.api.get(`${this.prefix}/show/${id}`, {
            headers: {
                "X-Up-Language": null,
            }
        });
    }

    update(data, id)
    {
        return this.api.post(`${this.prefix}/${id}`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            }
        })
    }

    upload(data)
    {
        return this.api.post(`${this.prefix}/upload-file`, data)
    }
    deleteAll(data)
    {
        return this.api.post(`${this.prefix}/delete-all`, data)
    }

    regenerateCache(data)
    {
        return this.api.post(`${this.prefix}/regenerate-cache`, data)
    }

    useVersion(data)
    {
        return this.api.post(`${this.prefix}/use-version`, data)
    }

    createVersion()
    {
        return this.api.post(`${this.prefix}/create-version`)
    }
    initCache()
    {
        return this.api.get(`${this.prefix}/init-cache`)
    }
}

export default new HttpTreeServices();
