import categoryService from "@/superUp-module/resources/services/v2/categoryService";
import HttpLabelServices from "@/superUp-module/resources/services/httpLabelServices";
import LabelService from "@/superUp-module/resources/services/v2/labelService";
import HttpCatServices from "@/superUp-module/resources/services/httpCatServices";
import ProductService from "@/superUp-module/resources/services/v2/productService";
import labelService from "@/superUp-module/resources/services/v2/labelService";
import BrandService from "@/superUp-module/resources/services/v2/brandService";

class TreeService {
    tree = []
    treeSection;
    componentInstance;
    categoryService = categoryService;
    parentInstance = null;
    instanceToUpdate = null;

    init(componentInstance, treeSection) {
        this.componentInstance = componentInstance;
        this.treeSection = treeSection;
    }

    processCategory(val = null, data = null) {
        const process = (data) => {
            if (!this.hasCategoryInstance()) {
                this.categoryService.init(this.treeSection, this.componentInstance);
                this.categoryService.add(data);
                this.tree.push(this.categoryService);
                this.componentInstance.loading = false;
                this.componentInstance.type = null;
                return;
            }

            this.categoryService.updateData(data);
            this.componentInstance.loading = false;
            this.componentInstance.type = null;
        }

        if (!data) {
            HttpCatServices.get(val).then(result => {
                process(result.data);
            })
            return;
        }

        process(data);
    }

    processLabel(val, data = null) {
        const processCreate = (data, labelService) => {
            this.parentInstance.addLabelService(labelService);
            labelService.add(this.parentInstance, data, this.componentInstance);
            this.parentInstance = null;
            this.componentInstance.loading = false;
            this.componentInstance.type = null;
        }

        const processUpdate = (data) => {
            this.instanceToUpdate.updateData(data);
            this.instanceToUpdate = null;
            this.componentInstance.loading = false;
            this.componentInstance.type = null;
        }

        if (this.parentInstance !== null) {
            const labelService = new LabelService();
            if (!data) {
                HttpLabelServices.get(val).then(result => {
                    processCreate(result.data, labelService);
                });
                return labelService;
            }
            processCreate(data, labelService);
            return labelService;

        }
        if (this.instanceToUpdate !== null) {
            HttpLabelServices.get(val).then(result => {
                processUpdate(result.data);
            });
            return labelService;
        }

        return null;
    }

    processBrands(items) {
        if (this.parentInstance !== null) {
            const brandService = new BrandService();
            brandService.add(items, this.parentInstance, this);
            this.parentInstance.addBrandService(brandService);
            this.componentInstance.loading = false;
            this.componentInstance.type = null;
            this.parentInstance = null;
            return;
        }

        if (this.instanceToUpdate !== null) {
            this.instanceToUpdate.update(items);
            this.componentInstance.loading = false;
            this.componentInstance.type = null;
            this.instanceToUpdate = null;
        }
    }

    processProducts(items) {
        if (this.parentInstance !== null) {
            const productService = new ProductService();
            productService.add(items, this.parentInstance, this);
            this.parentInstance.addProductService(productService);
            this.componentInstance.loading = false;
            this.componentInstance.type = null;
            this.parentInstance = null;
            return;
        }

        if (this.instanceToUpdate !== null) {
            this.instanceToUpdate.update(items);
            this.componentInstance.loading = false;
            this.componentInstance.type = null;
            this.instanceToUpdate = null;
        }
    }

    getTree() {
        return this.processTree(this.categoryService);

    }

    setTree(data = [], parentInstance, type = 'category') {
        let __this = this;

        if (!data) {
            return;
        }

        switch (type) {
            case 'category':
                __this.processCategory(null, data.itemData);

                Object.keys(data).forEach(item => {
                    switch (true) {
                        case item === 'labels' && Object.keys(data.labels).length !== 0:
                            setTimeout(() => {
                                __this.setTree(data.labels, categoryService, 'labels');
                            }, 30)
                        break

                        case item === 'offers' && Object.keys(data.offers).length !== 0:
                            setTimeout(() => {
                                __this.setTree(data.offers, categoryService, 'offers');
                            }, 10)
                        break

                        case item === 'benefits' && Object.keys(data.benefits).length !== 0:
                            setTimeout(() => {
                                __this.setTree(data.benefits, categoryService, 'benefits');
                            }, 30)
                        break

                        case item === 'brands' && Object.keys(data.brands).length !== 0:
                            setTimeout(() => {
                                __this.setTree(data.brands, categoryService, 'brands');
                            }, 10)
                    }
                })
                break
            case 'labels':
                data.forEach((label) => {
                    if (label === null) return;
                    __this.parentInstance = parentInstance;
                    const labelService = __this.processLabel(null, label.itemData);
                    Object.keys(label).forEach(item => {
                        switch (true) {
                            case item === 'labels' && Object.keys(label.labels).length !== 0:
                                setTimeout(() => {
                                    __this.setTree(label.labels, labelService, 'labels');
                                }, 30)
                                break

                            case item === 'offers' && Object.keys(label.offers).length !== 0:
                                setTimeout(() => {
                                    __this.setTree(label.offers, labelService, 'offers');
                                }, 30)
                                break

                            case item === 'benefits' && Object.keys(label.benefits).length !== 0:
                                setTimeout(() => {
                                    __this.setTree(label.benefits, labelService, 'benefits');
                                }, 30)
                                break

                            case item === 'brands' && Object.keys(label.brands).length !== 0:
                                setTimeout(() => {
                                    __this.setTree(label.brands, labelService, 'brands');
                                }, 30)
                        }
                    })
                })
                break
            case 'offers':
                setTimeout(() => {
                    __this.parentInstance = parentInstance;
                    __this.processProducts(data.map(offer => {
                        if (offer["itemData"] !== undefined) return offer.itemData;
                        return offer;
                    }));
                }, 10)
                break;
            case 'benefits':
                setTimeout(() => {
                    __this.parentInstance = parentInstance;
                    __this.processProducts(data.map(benefit => {
                        if (benefit["itemData"] !== undefined) return benefit.itemData;
                        return benefit;

                    }));
                }, 10)
            break;
            case 'brands':
                setTimeout(() => {
                    __this.parentInstance = parentInstance;
                    __this.processBrands(data.map(brand => {
                        if (brand["itemData"] !== undefined) return brand.itemData;
                        return brand;
                    }));
                }, 10)
        }
    }

    hasCategoryInstance() {
        return this.tree.length !== 0;
    }

    removeTree() {
        this.tree = [];
    }
}

export default new TreeService();