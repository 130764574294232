<template>
  <b-modal
    id="listaModal"
    ref="modal"
    :visible="show"
    :modal-class="['list-modal']"
    @hide="hideModal"
    @hidden="updateShowProp"
    size="xl"
    centered
  >
    <div>
      <b-tabs content-class="mt-3 list-modal" fill>
        <b-tab :title="title" active >
          <div class="modal-body">
            <table :id="table.tableId" class="display table table-hover dataTable swal-datatable" :data-columns='JSON.stringify(table.tableHeader)'>
            </table>
          </div>
        </b-tab>
        <b-tab title="Update order display" :disabled="products.length === 0" v-if="type === 'products'">
          <div class="modal-body">
            <div class="table-responsive hover-table">
              <table class="table table-striped" style="width:100%">
                <thead>
                <tr>
                  <th>
                    {{ $t('superUpLang.tree.list_table.header.number_row') }}
                  </th>
                  <th>
                    {{ $t('superUpLang.tree.list_table.header.image_url') }}
                  </th>
                  <th>
                    {{ $t('superUpLang.tree.list_table.header.name') }}
                  </th>
                </tr>
                </thead>
                <draggable
                  v-model="products"
                  :options="{ handle: '.drag-handle', animation: 200 }"
                  :tag="'tbody'"
                >
                  <tr
                    v-for="(item, index) in products"
                    :key="index"
                    class="trhover"
                    style="cursor: pointer"
                  >
                    <td class="drag-handle" style="width: 18%">⠿ {{ index + 1 }}</td>
                    <td class="drag-handle" style="width: 1%"><img :src="item.image_url" alt=""></td>
                    <td class="drag-handle" v-html="(typeof item.name === 'string') ? item.name : item.name[$i18n.locale]"></td>
                  </tr>
                </draggable>
              </table>
            </div>
          </div>
        </b-tab>
        <b-tab title="Update order display" :disabled="brands.length === 0" v-if="type === 'brands'">
          <div class="modal-body">
            <div class="table-responsive hover-table">
              <table class="table table-striped" style="width:100%">
                <thead>
                <tr>
                  <th>
                    {{ $t('superUpLang.tree.list_table.header.number_row') }}
                  </th>
                  <th>
                    {{ $t('superUpLang.tree.list_table.header.image_url') }}
                  </th>
                  <th>
                    {{ $t('superUpLang.tree.list_table.header.name') }}
                  </th>
                </tr>
                </thead>
                <draggable
                  v-model="brands"
                  :options="{ handle: '.drag-handle', animation: 200 }"
                  :tag="'tbody'"
                >
                  <tr
                    v-for="(item, index) in brands"
                    :key="index"
                    class="trhover"
                    style="cursor: pointer"
                  >
                    <td class="drag-handle" style="width: 18%">⠿ {{ index + 1 }}</td>
                    <td class="drag-handle" style="width: 1%"><img :src="item.image_url" alt=""></td>
                    <td class="drag-handle" v-html="(typeof item.name === 'string') ? item.name : item.name[$i18n.locale]"></td>
                  </tr>
                </draggable>
              </table>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </div>
    <template #modal-footer v-if="type === 'products' || type === 'brands'">
      <b-button variant="secondary" @click="hideModal">Close</b-button>
      <b-button variant="primary" @click="saveChanges">Save changes</b-button>
    </template>
    <content-loader :loader-visible="this.loading" :loader-error="loaderError"></content-loader>
  </b-modal>

</template>

<script>
import {BButton, BModal, VBModal} from "bootstrap-vue";
import DataTable from "datatables.net-dt";
import HttpCatServices from "@/superUp-module/resources/services/httpCatServices";
import i18n from "@/plugins/language/i18n";
import HttpTreeService from "@/superUp-module/resources/services/httpTreeService";
import HttpLabelServices from "@/superUp-module/resources/services/httpLabelServices";
import draggable from 'vuedraggable';

export default {
  name: 'SuperUpListModal',

  components: {
    draggable,
    "b-modal": BModal,
    "b-button": BButton,

  },
  directives: {
    "b-modal": VBModal
  },
  props: {
    title: {
      type: String,
      required: true,
      default: ''
    },
    type: {
      default: null
    },
    show: {
      type: Boolean,
      required: true,
      default: false
    },
    selectedType:{
      default: null
    },
    selectedCategory: {
      default: null
    },
    selectedLabel: {
      default: null
    },
    selectedProducts: {
      default: []
    },
    selectedBrands: {
      default: []
    },
    productsToUpdate: {
      default: []
    },
    brandsToUpdate: {
      default: []
    }
  },
  data() {
    return {
      table: {
        tableId: null,
        tableHeader: null,
        tableUri: null,
      },
      loading: false,
      loaderError: false,
      errors: {},
      errorsRequest: null,
      datatable: null,
      products: [],
      brands: [],
    }
  },
  watch: {
    show: function (val) {
      if (val) {
        if (this.productsToUpdate.length > 0) {
          this.products = this.productsToUpdate
        }

        if (this.brandsToUpdate.length > 0) {
          this.brands = this.brandsToUpdate
        }

        switch (this.type) {
          case 'category':
            this.table.tableId = 'categoryTableId';
            this.table.tableUri = HttpCatServices.getListUrl();
            this.table.tableHeader = [
              {"title": this.$t(`superUpLang.categories.list_table.header.img`), "name": "img", "data": "img", "orderable": "false"},
              {"title": this.$t(`superUpLang.categories.list_table.header.name`), "name": "name", "data": "name"},
              {"title": this.$t(`superUpLang.categories.list_table.header.title`), "name": "title", "data": "title"},
              {"title": this.$t(`superUpLang.categories.list_table.header.is_active`), "name": "is_active", "data": "is_active"},
              {"title": this.$t(`superUpLang.categories.list_table.header.actions`), "name": "action_select", "data": "action_select", "orderable": "false"}
            ];
          break;
          case 'label':
            this.table.tableId = 'labelTableId';
            this.table.tableUri = HttpLabelServices.getListUrl();
            this.table.tableHeader = [
              {"title": this.$t(`superUpLang.categories.list_table.header.img`), "name": "img", "data": "img", "orderable": "false"},
              {"title": this.$t(`superUpLang.categories.list_table.header.name`), "name": "name", "data": "name"},
              {"title": this.$t(`superUpLang.categories.list_table.header.title`), "name": "title", "data": "title"},
              {"title": this.$t(`superUpLang.categories.list_table.header.is_active`), "name": "is_active", "data": "is_active"},
              {"title": this.$t(`superUpLang.categories.list_table.header.actions`), "name": "action_select", "data": "action_select", "orderable": "false"}
            ];
          break;
          case 'products':
            this.table.tableId = 'productsTableId';
            this.table.tableUri = HttpTreeService.getTypeData();
            this.table.tableHeader = [
              {"title": this.$t(`superUpLang.categories.list_table.header.img`), "name": "img", "data": "img", "orderable": "false"},
              {"title": this.$t(`superUpLang.categories.list_table.header.name`), "name": "name", "data": "name"},
              {"title": this.$t(`superUpLang.categories.list_table.header.brand`), "name": "brand", "data": "brand"},
              {"title": this.$t(`superUpLang.categories.list_table.header.actions`), "name": "action_select", "data": "action_select", "orderable": "false"}
            ];
          break
          case 'brands':
            this.table.tableId = 'brandsTableId';
            this.table.tableUri = HttpTreeService.getBrandsData();
            this.table.tableHeader = [
              {"title": this.$t(`superUpLang.categories.list_table.header.img`), "name": "img", "data": "img", "orderable": "false"},
              {"title": this.$t(`superUpLang.categories.list_table.header.name`), "name": "name", "data": "name"},
              {"title": this.$t(`superUpLang.categories.list_table.header.available_types`), "name": "available_types", "data": "available_types", "orderable": "false"},
              {"title": this.$t(`superUpLang.categories.list_table.header.actions`), "name": "action_select", "data": "action_select", "orderable": "false"}
            ];
        }
        setTimeout(() => {
          this.initTable();
        }, 100)
        this.loading = false;
      } else {

      }
    }
  },
  methods: {
    initTable() {
      this.loading = true
      const instance = this;
      this.datatable = new DataTable(`#${this.table.tableId}`, {
        serverSide: true,
        autoWidth: false,
        searching: true,
        responsive: true,
        search: {
          return: true
        },
        lengthMenu: [ 7, 25, 50 ],
        ajax: {
          beforeSend: function(request) {
            request.setRequestHeader("X-Up-Language", i18n.locale);
            request.setRequestHeader("Authorization", `Barier ${HttpTreeService.getToken()}`);
            request.setRequestHeader("Content-Type", "application/json;charset=utf-8");
          },
          url: this.table.tableUri,
          data: (data) => {
            data['selectedType'] = instance.selectedType
            return data
          }
        },
        pagingType: "full_numbers",
        //stateSave: stateSave,
        processing: false,
        drawCallback: () => {
          this.loading = false;
        },
        createdRow: function (row, data) {
          const editButton = row.querySelector('.action-button[data-action="action_select"]');
          if (editButton) {
            editButton.addEventListener("click", (event) => {
              event.stopPropagation(); // Prevents the row click event from firing
              const itemId = editButton.getAttribute("data-item");
              instance.selectValue(itemId);
            });
          }
          const setButton = row.querySelector('.action-button[data-action="action_benefit_select"]');
          if (setButton) {
            let rowProductId = JSON.parse(setButton.getAttribute("data-item")).id;

            if (instance.products !== null) {
              instance.products.filter((product) => {
                if (product.id === rowProductId) {
                  instance.processProductButton(setButton)
                }
              })
            }

            setButton.addEventListener("click", (event) => {
              event.stopPropagation(); // Prevents the row click event from firing
              instance.processItem(setButton);
            });
          }

          const setBrandButton = row.querySelector('.action-button[data-action="action_brand_select"]');
          const typesItems = row.querySelectorAll('.availability_sub_type');
          if (setBrandButton) {
            let rowBrandId = JSON.parse(setBrandButton.getAttribute("data-item")).id;

            if (instance.brands !== null) {
              let existingBrand = instance.brands.filter(item => {
                return item.id === rowBrandId
              });
              if (existingBrand.length !== 0) {

                let types = [];

                if (existingBrand[0]['available_products']) {
                  types = existingBrand[0]['available_products'];
                }

                instance.processBrandButton(setBrandButton);
                instance.processBrandSubTypes(typesItems, types)
              }
            }

            setBrandButton.addEventListener("click", (event) => {
              event.stopPropagation(); // Prevents the row click event from firing
              instance.processBrandItem(setBrandButton, typesItems, instance);
            });
          }
        }
      });
    },

    processProductButton(button) {
      const icon = button.querySelector('i');
      const span = button.querySelector('span');
      button.classList.toggle("table-select__actions");
      icon.classList.toggle("table-select-icon__actions");
      span.classList.toggle("table-select-text__actions");

    },

    processBrandButton(button) {
      const icon = button.querySelector('i');
      const span = button.querySelector('span');
      button.classList.toggle("table-select__actions");
      icon.classList.toggle("table-select-icon__actions");
      span.classList.toggle("table-select-text__actions");
    },

    processBrandSubTypes(typesItems, types) {

      if(types === 'undefined') {
        return;
      }


      [...typesItems].forEach( item => {
        item.checked = !!types.includes(item.value);
      });
    },

    processBrandItem(button, typesItems, instance) {
      const itemData = JSON.parse(button.getAttribute("data-item"));

      itemData.available_products = [...typesItems].map( item => {
        if (item.checked) {
          return item.value
        }
      });
      // const
      this.processBrandButton(button);

      let existingBrand = this.brands.filter(item => {
        return item.id === itemData.id
      })

      if (existingBrand.length !== 0) {
        this.brands = this.brands.filter(item => {
          return item.id !== itemData.id
        })

        return;
      }

      this.brands.push(itemData)
    },


    processItem(button) {
      const itemData = JSON.parse(button.getAttribute("data-item"));
      console.log(itemData)
      this.processProductButton(button);

      let existingProduct = this.products.filter(item => {
          return item.id === itemData.id
      })

      if (existingProduct.length !== 0) {
        this.products = this.products.filter(item => {
          return item.id !== itemData.id
        })

        return;
      }

      this.products.push(itemData);
    },


    saveChanges() {
      if (this.products.length !== 0){
        this.$emit('update:selectedProducts', this.products);
        this.hideModal();
      }



      if (this.brands.length !== 0) {
        this.$emit('update:selectedBrands', this.brands);
        this.hideModal();
      }


    },
    selectValue(val) {
      switch (this.type) {
        case 'category':
          this.$emit('update:selectedCategory', val);
          break
        default:
          this.$emit('update:selectedLabel', val);
      }
      this.hideModal();
    },

    hideModal() {
      // this.$emit('update:items', null);
      this.$emit('update:show', false);
    },

    updateShowProp() {
      if (this.show) {
        if (this.products.length !== 0) {
          this.products = [];
        }
        this.$emit('update:items', null);
        this.$emit('update:show', false);
      }
    }
  },

  mounted() {
    const instance = this;
    this.$root.$on("bv::modal::hidden", function (event, id) {
      if (instance.products.length !== 0) {
        instance.products = [];
      }

      if (instance.brands.length !== 0) {
        instance.brands = [];
      }

      if (id === 'listaModal') {
        if (instance.type === 'category' && !instance.selectedCategory ) {
          instance.$emit('update:selectedCategory', false);
        }
      }
    })
  }
}
</script>
<style lang="scss">
.list-modal{
  .tabs {
    .nav-tabs {
      .nav-link {
        padding: 8px 0!important;
      }
      .nav-item {
        .active {
          background-color: #f49400 !important;
        }
      }
    }
  }
}
</style>
