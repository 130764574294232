export default {
  "CARD_ERROR_EMPTY_LIST": "Comanda trimisa nu contine nici un beneficiar cu numar de tichete mai mare ca 0",
  "CARD_ERROR_CUSTOMER": "Codul de client sau codul fiscal din fisierul excel este diferit de codul de client pentru care se face comanda",
  "EXCEL_OLD": "Formatul fisierului nu este valid_ Te rugam sa descarci noul model de fisier de comanda",
  "EXCEL_INVALID_FORMULA": "Fisierul contine formule invalide_ Te rugam sa corectezi informatiile si sa reincarci fisierul",
  "EXCEL_TOO_LARGE": "Fisierul incarcat este prea mare_ In cazul in care sunt linii necompletate la finalul fisierul te rugam sa le stergi si sa incarci noul fisier",
  "EXCEL_TOO_OLD": "Fisierul incarcat este salvat intr-o versiune de Excel mai veche decat cea suportata de aplicatie_ Te rugam sa resalvezi fisierul intr-o versiune mai noua si sa incarci noul fisier",
  "CARD_ERROR_MANDATORY": "Campul {cnp} pentru angajat este obligatoriu",
  "CARD_ERROR_ALREADY": "Cardul a fost deja emis",
  "CARD_ERROR_ALREADY_DELETED": "Angajatul a fost sters, dar are card activ_ Daca doresti reactivarea te rugam sa contactezi UpRomania",
  "CARD_ERROR_LENGTH": "Campul {cnp} pentru angajatul are completate prea multe caractere",
  "CARD_ERROR_DUPLICATE": "Campul {cnp} pentru angajat este duplicat",
  "CARD_ERROR_INVALID": "Campul telefon/cnp pentru angajat are o valoare invalida",
  "CARD_ERROR_LOAD_MONTH": "Nr_ tichete pentru angajatul {info} este invalid",
  "CARD_ERROR_LOAD_VALUE": "Campul {cnp} pentru angajat este obligatoriu",
  "CARD_ERROR_LOAD_VALUE_INVALID": "Valoarea nominala pentru angajatu nu este valida pentru produsul selectat",
  "CARD_ERROR_LOAD_VALUE_MIN": "Valoarea nominala pentru angajat nu poate fi mai mica",
  "CARD_ERROR_LOAD_VALUE_MAX": "Valoarea nominala pentru angajatul de pe linia {name} nu poate fi mai mare",
  "CARD_ERROR_ADDRESS_NOT_FOUND": "Codul de adresa pentru angajat nu este valid",
  "CARD_ERROR_NOT_VALIDRENEW": "Cardul este eligibil pentru reinnoire",
  "CARD_ERROR_NOT_CANCEL_RENEW": "Reinnoirea pentru cardul beneficiarului a mai fost anulata",
  "CARD_ERROR_NOT_IVR_MOBILE": "Beneficiarul nu are numar de telefon valid introdus si de aceea nu se poate face comanda",
  "CARD_ERROR_NOT_VALIDSTATUS": "Statusul cardului pentru nu este valid pentru actiunea selectata",
  "CARD_ERROR_CARD_NOT_VALID_STATUS": "Statusul cardului nu este valid pentru alimentare",
  "CARD_ERROR_CARD_EXPIRED": "Cardul este expirat si nu poate fi alimentat",
  "CARD_ERROR_CARD_CANCELED": "Cardul este anulat si nu poate fi alimentat",
  "CARD_ERROR_CARD_DEACTIVATED": "Cardul este dezactivat si nu poate fi alimentat",
  "CARD_ERROR_CARD_NOTPRODUCED": "Cardul nu este in productie si nu poate fi alimentat",
  "CARD_ERROR_NOT_VALID_REISSUE": "Cardul nu este valid pentru reemitere_ Este necesara comanda de card nou",
  "CARD_ERROR_NOT_FOUND": "Nu exista card pentru CNP-ul {cnp}",
  "CARD_ERROR_NOT_ACTIVE": "Cardul nu este inca activ",
  "CARD_ERROR_NOT_VALIDTODAY": "Statusul cardului nu este valid pentru actiunea selectata",
  "CARD_ERROR_RENEW_INVALID": "Cardul nu este valid pentru a fi reinnoit",
  "CARD_ERROR_RENEW_NOTFOUND": "Nu exista card pentru CNP-ul {cnp}",
  "CARD_ERROR_ALREADY_REPL": "Mai exista o cerere efectuata astazi",
  "CARD_ERROR_NEW_CONTRACT_MIX_ORDER": "Comanda de card cu alimentare nu poate fi efectuata in ziua in care se semneaza contractul_ Puteti realiza doar comanda de card nou fara alimentare",
  "UNKNOWN": "Status necunoscut"
}
