class BrandService {
    brands;
    parentInstance;
    treeInstance;
    type = 'brand'
    add(items, instance, treeInstance) {
        this.parentInstance = instance
        this.brands = items;
        this.treeInstance = treeInstance;
        const create = () => {
            let element = document.createElement('div');
            element.innerHTML = this.getTpl(this.brands).trim();
            this.parentInstance.element.querySelector('[id="itemsBodyBrands"]').append(element);
            this.element = element;
            this.addOptionsEvents(this.brands)
        }

        if (!this.element) {
            create();
            return;
        }

        this.element.remove();
        create();
    }

    update(items) {
        this.brands = items;

        const itemElement = this.element.querySelector('.brands-items');

        // Validate the element exists
        if (!itemElement) {
            throw new Error('Element ".brands-items" not found in DOM');
        }

        // Clear all previous children
        itemElement.innerHTML = ''; // or itemElement.replaceChildren();

        // Add new content
        const fragment = document.createDocumentFragment();

        items.forEach(item => {
            const itemDiv = document.createElement('div');
            itemDiv.className = 'treeview__item';

            const nameSpan = document.createElement('span');
            nameSpan.className = 'treeview__item-name';

            nameSpan.textContent = item.name instanceof Object
                ? item.name[this.treeInstance.componentInstance.$i18n.locale]
                : item.name;

            itemDiv.appendChild(nameSpan);
            fragment.appendChild(itemDiv);
        });

        itemElement.appendChild(fragment);
    }

    addOptionsEvents() {
        const treeService = this.treeInstance;
        const instance = this;

        const levelSelect = this.element.querySelector('.level-select');
        const levelUpdate = this.element.querySelector('.level-update');

        levelSelect.addEventListener("click", function () {
            let closestDataLevel = this.closest("[data-level]");
            let isSelected = closestDataLevel.classList.contains("selected");

            if (!isSelected) {
                let treeView = this.closest(".js-treeview");
                let dataLevelElements = treeView.querySelectorAll("[data-level]");
                dataLevelElements.forEach(function (dataLevelElement) {
                    dataLevelElement.classList.remove("selected");
                });
            }

            closestDataLevel.classList.toggle("selected");
        });

        levelUpdate.addEventListener('click', function () {
            treeService.componentInstance.brandsToUpdate = instance.brands;
            treeService.instanceToUpdate = instance;
            treeService.componentInstance.openModal('brands');
        });
    }

    getTpl(items = null) {
        const itemsString = items.map(item => {
            const name = () => {
                if (item.name instanceof Object) {
                    return item.name[this.treeInstance.componentInstance.$i18n.locale];
                } else {
                    return item.name;
                }
            }
            return `
             <div class="treeview__item">
                <span class="treeview__item-name">${name()}</span>
            </div>
        `
        }).join('');

        return `         
        <div class="treeview__level mt-4" data-level="Brands" data-type="brands">
            <div class="level-select d-flex" style="cursor: pointer">
               <div class="brands-items">
                ${itemsString}
                </div>
               <div class="treeview__level-btns" >
                    <div class="btn btn-default btn-sm level-update">
                        <span style="vertical-align: sub">
                            <i style="color: inherit; padding: 0; height: auto" class="fa-solid fa-pen-to-square"></i>
                        </span>
                    </div>
                </div>
            </div>
        </div>  
        `
    }

    getProcessData(type) {
        return this.brands.map((item) => {
            return {
                "id": item.id,
                'type' : type.name,
                'itemData' : item,
            }
        })
    }
}

export default BrandService;