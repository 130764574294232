export default {
    list_title_page: 'Categories List',
    list_table: {
        header: {
            img: 'Image',
            name: 'Name',
            title: 'Title',
            slug: 'Slug',
            description: 'Description',
            is_active: 'Is Active',
            image_url: 'Image Link',
            cover_url: 'Cover Link',
            icon_url: 'Icon Link',
            created_at: 'Created At',
            updated_at: 'Updated At',
            actions: 'Actions',
            brand: 'Brands',
            available_types: 'Available Types'
        },

    },
    modal: {
      title_select: 'Select Category',
    },
    buttons: {
        update: "Update",
        delete: "Delete",
        cancel: 'Cancel'
    },
    actionModal: {
        title: 'Confirm',
        message: 'Are you sure you want to delete this item?',
    }
}