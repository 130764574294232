export default {
  "CARD_ERROR_EMPTY_LIST": "The order sent does not contain any beneficiary with a ticket number greater than 0",
  "CARD_ERROR_CUSTOMER": "The customer code or fiscal code in the excel file is different from the customer code for which the order is placed",
  "EXCEL_OLD": "The file format is not valid_ Please download the new order file model",
  "EXCEL_INVALID_FORMULA": "The file contains invalid formulas_ Please correct the information and re-upload the file",
  "EXCEL_TOO_LARGE": "The uploaded file is too large_ If there are blank lines at the end of the file, please delete them and upload the new file",
  "EXCEL_TOO_OLD": "The uploaded file is saved in a version of Excel older than the one supported by the application_ Please resave the file in a newer version and upload the new file",
  "CARD_ERROR_MANDATORY": "The {cnp} field for the employee {name} is mandatory",
  "CARD_ERROR_ALREADY": "The card has already been issued",
  "CARD_ERROR_ALREADY_DELETED": "The employee {name} {info} has been deleted, but has an active card_ If you want reactivation, please contact UpRomania",
  "CARD_ERROR_LENGTH": "The {cnp} field for the employee {name} has too many characters filled in",
  "CARD_ERROR_DUPLICATE": "The {cnp} field for the employee {name} is duplicated",
  "CARD_ERROR_INVALID": "The phone/cnp field for the employee {name} has an invalid value",
  "CARD_ERROR_LOAD_MONTH": "No_ tickets for the employee {name} {info} is invalid",
  "CARD_ERROR_LOAD_VALUE": "The {cnp} field for the employee {name} is mandatory",
  "CARD_ERROR_LOAD_VALUE_INVALID": "The nominal value for the employee {name} is not valid for the selected product",
  "CARD_ERROR_LOAD_VALUE_MIN": "The nominal value for the employee {name} cannot be lower",
  "CARD_ERROR_LOAD_VALUE_MAX": "The nominal value for the employee {name} cannot be higher",
  "CARD_ERROR_ADDRESS_NOT_FOUND": "The address code for the employee on line {name} is not valid",
  "CARD_ERROR_NOT_VALIDRENEW": "The card for {name} is not eligible for renewal",
  "CARD_ERROR_NOT_CANCEL_RENEW": "The renewal for the beneficiary {name}'s card has also been cancelled",
  "CARD_ERROR_NOT_IVR_MOBILE": "The recipient {name} does not have a valid phone number entered and therefore the order cannot be placed",
  "CARD_ERROR_NOT_VALIDSTATUS": "The card status for {name} is not valid for the selected action",
  "CARD_ERROR_CARD_NOT_VALID_STATUS": "Card status for {name} is not valid for power",
  "CARD_ERROR_CARD_EXPIRED": "The card for {name} has expired and cannot be topped up",
  "CARD_ERROR_CARD_CANCELED": "The card for {name} is canceled and cannot be topped up",
  "CARD_ERROR_CARD_DEACTIVATED": "The card for {name} is canceled and cannot be topped up",
  "CARD_ERROR_CARD_NOTPRODUCED": "The card is not in production and cannot be powered",
  "CARD_ERROR_NOT_VALID_REISSUE": "The card for {name} is not valid for reissue_ It is necessary to order a new card",
  "CARD_ERROR_NOT_FOUND": "The card for {cnp} is not valid for reissue_ It is necessary to order a new card",
  "CARD_ERROR_NOT_ACTIVE": "The card for {name} is not yet active",
  "CARD_ERROR_NOT_VALIDTODAY": "The card status for {name} is not valid for the selected action",
  "CARD_ERROR_RENEW_INVALID": "The card is not valid for renewal",
  "CARD_ERROR_RENEW_NOTFOUND": "There is no card for the CNP {cnp}",
  "CARD_ERROR_ALREADY_REPL": "There is another request made today for {name}",
  "CARD_ERROR_NEW_CONTRACT_MIX_ORDER": "The card order with charging cannot be made on the day the contract is signed_ You can only order a new card without charging",
  "UNKNOWN": "Unknown status"
}
