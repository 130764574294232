<template>
  <b-modal
    ref="modal"
    :visible="show"
    @hide="hideModal"
    @hidden="updateShowProp"
    size="xl"
    centered
  >
    <div class="modal-body">
      <div class="card-body">
        <div class="row" style="border-bottom: 1px solid black">
          <div class="col-md-6" style="border-right: 1px solid black">
            <div class="form-group">
              <label class="label-input" for="exampleInputBorder">Name</label>
              <input type="text" v-model="formData.name" class="form-control form-control-border" name="name" placeholder="name">
              <div v-if="errors.name" class="text-danger">{{ errors.name }}</div>
            </div>
            <div class="form-group">
              <label class="label-input" for="exampleInputBorder">Activate/Deactivate</label>
              <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                <input v-model="formData.isActive" type="checkbox" class="custom-control-input" id="customSwitch3" name="is_active">
                <label class="custom-control-label" for="customSwitch3"></label>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="label-input" for="exampleInputBorder">Title</label>
              <div class="form-group" style="display: flex">
                <label style="margin-right: 10px">Ro</label>
                <textarea v-model="formData.title.ro" name="title[ro]" class="form-control" rows="3" placeholder="Enter ..."></textarea>
                <div v-if="errors.titleRo" class="text-danger">{{ errors.titleRo }}</div>
              </div>
              <div class="form-group" style="display: flex;">
                <label style="margin-right: 10px">En</label>
                <textarea v-model="formData.title.en" name="title[en]" class="form-control" rows="3" placeholder="Enter ..."></textarea>
                <div v-if="errors.titleEn" class="text-danger">{{ errors.titleEn }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group" style="margin-top: 1rem">
          <div class="row">
            <div class="col-md-4">
              <label class="label-input" for="customSwitch3">Image</label>
              <vue2Dropzone ref="imageUrlRef" id="imageUrl" :options="{
                url: 'https://httpbin.org/post',
                thumbnailWidth: 200,
                addRemoveLinks: true,
                autoProcessQueue: false
                }"></vue2Dropzone>
            </div>
            <div class="col-md-4">
              <label class="label-input" for="customSwitch3">Cover</label>
              <vue2Dropzone ref="coverUrlRef" id="coverUrl"
                :options="{
                url: 'https://httpbin.org/post',
                autoProcessQueue: false,
                thumbnailWidth: 200,
                addRemoveLinks: true,
                }"></vue2Dropzone>
            </div>
            <div class="col-md-4">
              <label class="label-input" for="customSwitch3">Icon</label>
              <vue2Dropzone ref="iconUrlRef" id="iconUrl" :options="{
                url: 'https://httpbin.org/post',
                thumbnailWidth: 200,
                addRemoveLinks: true,
                autoProcessQueue: false
                }"></vue2Dropzone>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template #modal-footer>
      <b-button variant="secondary" @click="hideModal">Close</b-button>
      <b-button variant="primary" @click="submitForm">Save changes</b-button>
    </template>
    <content-loader :loader-visible="this.loading" :loader-error="loaderError"></content-loader>
  </b-modal>

</template>
<style  lang="scss">
  .form-group {
    .label-input {
      font-weight: 700;
      font-size: .8rem;
    }
  }
  .vue-dropzone > .dz-preview .dz-remove {
    font-size: .6rem;
    margin-left: 5px;
  }
</style>
<script>
import { BModal, VBModal, BButton } from "bootstrap-vue";
import HttpCatServices from "@/superUp-module/resources/services/httpCatServices";
import HttpLabelServices from "@/superUp-module/resources/services/httpLabelServices";
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
export default {
  name: 'EditSuperUpModalForm',
  components: {
    "b-modal": BModal,
    "b-button": BButton,
    vue2Dropzone
  },
  directives: {
    "b-modal": VBModal
  },
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false
    },
    refreshTable: {
      type: Boolean,
      required: true,
      default: false
    },
    item: {
      required: true,
      default: null
    },
    type: {
      type: String,
      required: true,
      default: null
    }
  },
  data() {
    return {
      loading: false,
      loaderError: false,
      formData: {
        name: null,
        title: {
          ro: null,
          en: null
        },
        description: {
          ro: null,
          en: null
        },
        isActive: false,
        image_url: null,
        cover_url: null,
        icon_url: null,
      },
      errors: {}
    }
  },

  watch:{
    item: function (value) {
      this.loading = true;
      if (value !== null) {
        let httpInstance;

        switch (this.type) {
          case 'label':
            httpInstance = HttpLabelServices;
            break;
          default:
            httpInstance = HttpCatServices;
        }

        httpInstance.get(value)
          .then(result => {
            let data = result.data;
            this.formData.name = data.name;
            const title = JSON.parse(data.title);
            this.formData.title.en  = title.en;
            this.formData.title.ro  = title.ro;
            const description = JSON.parse(data.description);
            this.formData.description.en  = description.en;
            this.formData.description.ro  = description.ro;
            this.formData.slug = data.slug;
            this.formData.isActive= data.is_active;

            if (data.image_url) {
              this.$refs.imageUrlRef.manuallyAddFile({size: 300, name: "Image", type: "image/*"}, data.image_url);
            }
            if (data.icon_url) {
              this.$refs.iconUrlRef.manuallyAddFile({size: 300, name: "Icon", type: "image/*"}, data.icon_url);
            }
            if (data.cover_url) {
              this.$refs.coverUrlRef.manuallyAddFile({size: 300, name: "Cover", type: "image/*"}, data.cover_url);
            }
            this.loading = false;
          })
          .catch(error => {
            // Handle the error accordingly
            this.loaderError = true;
            console.error(error);

          });
      }
    }
  },

  methods: {
    validateForm() {
      this.errors = {};

      if (!this.formData.name) {
        this.errors.name = 'Name is required.';
      } else if (this.formData.name.length > 50) {
        this.errors.name = 'Name must be less than 50 characters.';
      }

      if (!this.formData.title.ro) {
        this.errors.titleRo = 'Title (Ro) is required.';
      } else if (this.formData.title.ro.length > 250) {
        this.errors.titleRo = 'Title (Ro) must be less than 250 characters.';
      }

      if (!this.formData.title.en) {
        this.errors.titleEn = 'Title (En) is required.';
      } else if (this.formData.title.en.length > 250) {
        this.errors.titleEn = 'Title (En) must be less than 250 characters.';
      }

      // if (!this.formData.description.ro) {
      //   this.errors.descriptionRo = 'Description (Ro) is required.';
      // } else if (this.formData.description.ro.length > 1500) {
      //   this.errors.descriptionRo = 'Description (Ro) must be less than 1500 characters.';
      // }
      //
      // if (!this.formData.description.en) {
      //   this.errors.descriptionEn = 'Description (En) is required.';
      // } else if (this.formData.description.en.length > 1500) {
      //   this.errors.descriptionEn = 'Description (En) must be less than 1500 characters.';
      // }

      if (!this.formData.slug) {
        this.errors.slug = 'Slug is required.';
      }

      return Object.keys(this.errors).length === 0;
    },

    submitForm() {
      if (this.validateForm()) {
        let formData = new FormData();
        // Append the data to formData
        formData.set('name', this.formData.name);
        formData.append('title', JSON.stringify({ ro: this.formData.title.ro, en: this.formData.title.en}));
        formData.append('description', JSON.stringify({ ro: this.formData.description.ro, en: this.formData.description.en}));
        formData.append('is_active', this.formData.isActive ? 'on' : '');
        this.$refs.imageUrlRef.processQueue();
        this.$refs.coverUrlRef.processQueue();
        this.$refs.iconUrlRef.processQueue();

        if (this.$refs.imageUrlRef.getUploadingFiles().length > 0) {
          formData.append('image_url', this.$refs.imageUrlRef.getUploadingFiles()[0]);
        }

        if (this.$refs.iconUrlRef.getUploadingFiles().length > 0) {
          formData.append('icon_url', this.$refs.iconUrlRef.getUploadingFiles()[0]);
        }
        if (this.$refs.coverUrlRef.getUploadingFiles().length > 0) {
          formData.append('cover_url', this.$refs.coverUrlRef.getUploadingFiles()[0]);
        }
        this.loading = true;

        let httpInstance = null;

        switch (this.type) {
          case 'label':
            httpInstance = HttpLabelServices;
            break;
          default:
            httpInstance = HttpCatServices;
        }
        // Send the formData using axios
        httpInstance.update(formData, this.item)
          .then(result => {
            this.$emit('update:show', false);
            this.updateRefreshProp();
            this.loading = false;
          })
          .catch(error => {
            this.loaderError = true;
            const instance = this;
            Object.keys(error.response.data.errors).forEach(function(key) {
              instance.errors[key] = error.response.data.errors[key][0];
            });
            setTimeout(() => {
              this.loading = false;
            },200)
          });
      }

    },

    onFileChange(e, forItem) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      switch (forItem) {
        case 'image_url':
          this.formData.image_url = files[0];
          break;
        case 'cover_url':
          this.formData.cover_url = files[0];
          break;
        case 'icon_url':
          this.formData.icon_url = files[0];
          break;
        default: console.error('N for type')
      }
    },

    hideModal() {
      this.$emit('update:show', false);
    },
    updateShowProp() {
      if (this.show) {
        this.$emit('update:show', false);
      }
    },

    updateRefreshProp() {
      if (this.show) {
        this.$emit('update:refreshTable', true);
      }
    }
  }
}
</script>
