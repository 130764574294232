class TreeProcessorService {
    typeTreeInstance;
    getTree(categoryInstance, type) {
        this.typeTreeInstance = type
        return this.processTree(categoryInstance.getProcessData(), 'category')
    }

    processTree(data, itemType) {
        let result = [];
        let __this = this;

        if (!data) {
            return;
        }



        switch (itemType) {
            case 'category':
                let categoryData = {};
                Object.entries(data).forEach(([key, val]) => {
                    switch (key) {
                        case 'itemData':
                            if (val === 'undefined' || val === null) {
                                return;
                            }
                            categoryData.id = val.id;
                            categoryData.type = itemType;
                            categoryData.itemData = val;
                        break
                        case 'labels':
                            if (val === 'undefined' || val === null) {
                                return;
                            }
                            categoryData.labels = [];

                            Object.entries(val).forEach(([labelsKey, labelsVal]) => {
                                categoryData.labels.push(__this.processTree(labelsVal.getProcessData(), key));
                            })
                        break
                        case 'products':
                            if (val === 'undefined' || val === null) {
                                return;
                            }

                            switch (__this.typeTreeInstance.value) {
                                case 2:
                                    categoryData.offers = __this.processTree(val, key);
                                    break
                                case 1:
                                    categoryData.benefits = __this.processTree(val, key);
                                    break
                                default:
                                    return;

                            }
                            break
                        case 'brands':
                            if (val === 'undefined' || val === null) {
                                return;
                            }

                            categoryData.brands = __this.processTree(val, key);

                    }
                })
                result.push(categoryData);
            break
            case 'labels':
                let labelData = {};
                Object.entries(data).forEach(([key, val]) => {
                    switch (key) {
                        case 'itemData':
                            if (val === 'undefined' || val === null) {
                                return;
                            }
                            labelData.id = val.id;
                            labelData.type = 'label';
                            labelData.itemData = val;
                            break
                        case 'labels':
                            if (val === 'undefined' || val === null) {
                                return;
                            }

                            labelData.labels = [];

                            Object.entries(val).forEach(([labelKey, labelVal]) => {
                                labelData.labels.push(__this.processTree(labelVal.getProcessData(), key))
                            })
                        break
                        case 'products':
                            if (val === 'undefined' || val === null) {
                                return;
                            }

                            switch (__this.typeTreeInstance.value) {
                                case 2:
                                    labelData.offers = __this.processTree(val, key);
                                break
                                case 1:
                                    labelData.benefits = __this.processTree(val, key);
                                break
                                default:
                                    return;

                            }
                        break
                        case 'brands':
                            if (val === 'undefined' || val === null) {
                                return;
                            }

                            labelData.brands = __this.processTree(val, key);
                    }
                })
                return labelData;
            case 'products':
                if (Object.entries(data.products).length === 0) {
                    return [];
                }

                return Object.entries(data.products).map(([key, value]) => {
                    return {
                        "id": value.id,
                        "type": __this.typeTreeInstance.name.toLowerCase(),
                        "itemData": value
                    }
                })
            case 'brands':
                if (Object.entries(data.brands).length === 0) {
                    return [];
                }

                return Object.entries(data.brands).map(([key, value]) => {
                    return {
                        "id": value.id,
                        "type": __this.typeTreeInstance.name.toLowerCase(),
                        "itemData": value
                    }
                })



        }

        return result
    }
}

export default new TreeProcessorService();