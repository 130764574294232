<template>
  <div>
    <b-modal centered :visible="show" @hide="closeModal" size="lg"
             @hidden="updateShowProp" id="modal-1" ref="confirmationModal" hide-footer>
      <div class="d-block text-center">
        <h3 v-if="type === 'all_data'">{{ $t("superUpLang.tree.actionModal.delete_all") }} </h3>
        <h3  v-else-if="type === 'regenerate'">{{ $t("superUpLang.tree.actionModal.regenerate") }} </h3>
        <h3 v-else>{{ $t("superUpLang.categories.actionModal.message") }}</h3>
      </div>
      <div class="mt-2 text-center mr-5 ml-5 mb-2 action-modal-tabs" v-if="type === 'regenerate'">
        <b-tabs content-class="mt-3" fill>
          <b-tab :title="this.$t('superUpLang.tree.buttons.add_previous_build')" active >
            <div class="modal-body">
              <label class="mb-0 ">{{ $t('superUpLang.tree.select_type') }}</label>
              <select class="form-control border" name="ppp3_child" id="ppp3_child"
                      @change="selectVersion" v-model="selectedVersion.inputValue">
                <option :value="item.id" v-for="item in versionItems"
                        :key="item.id" v-html="item.name">
                </option>
              </select>
            </div>
            <div class="d-flex justify-content-sm-around">
              <b-button class="mt-3 w-30" style="font-size: 0.7rem" variant="primary" @click="applyVersion">{{
                $t("superUpLang.tree.buttons.use_version") }}
              </b-button>
              <b-button class="mt-3 w-30" style="font-size: 0.7rem" variant="secondary" @click="closeModal">{{
                $t("superUpLang.categories.buttons.cancel") }}
              </b-button>
            </div>
          </b-tab>
          <b-tab :title="this.$t('superUpLang.tree.buttons.create_version')" >
            <div class="modal-body">
              <div>
                <label class="mb-0 ">{{ $t("superUpLang.tree.import_modal.password") }}</label>
                <input type="password" class="custom-password-input" v-model="password.inputValue">
                <div class="invalid-feedback show" v-if="password.error">
                  {{ $t("superUpLang.tree.errors.password") }}
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-sm-around">
              <b-button class="mt-3 w-30" style="font-size: 0.7rem" variant="primary" @click="createVersion">{{
                $t("superUpLang.tree.buttons.continue") }}
              </b-button>
              <b-button class="mt-3 w-30" style="font-size: 0.7rem" variant="secondary" @click="closeModal">{{
                $t("superUpLang.categories.buttons.cancel") }}
              </b-button>
            </div>
          </b-tab>
        </b-tabs>
      </div>
      <div class="mt-2 text-center mr-5 ml-5 mb-2" v-if="type === 'all_data'">
        <div>
          <label class="mb-0 ">{{ $t("superUpLang.tree.import_modal.password") }}</label>
          <input type="password" class="custom-password-input" v-model="password.inputValue">
          <div class="invalid-feedback show" v-if="password.error">
            {{ $t("superUpLang.tree.errors.password") }}
          </div>
        </div>
        <div>
          <label class="mb-0 ">{{ $t('superUpLang.tree.select_type') }}</label>
          <select class="form-control border" name="ppp3_child" id="ppp3_child"
                  @change="select" v-model="selectedType.inputValue">
            <option value="9"
                    v-html="$t('flexben.general.all')">
            </option>
            <option :value="item.value" v-for="item in typeItems"
                    :key="item.value" v-html="item.name">
            </option>
          </select>
          <div class="invalid-feedback show" v-if="selectedType.error">
            {{ $t("superUpLang.tree.errors.type") }}
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-sm-around" v-if="type !== 'regenerate'">
        <b-button  class="mt-3 w-30" variant="danger" @click="confirmDelete">{{
          $t("superUpLang.categories.buttons.delete") }}
        </b-button>
        <b-button class="mt-3 w-30" variant="secondary" @click="closeModal">{{
          $t("superUpLang.categories.buttons.cancel") }}
        </b-button>
      </div>
    </b-modal>
    <content-loader :loader-visible="this.loading" :loader-error="loaderError"></content-loader>
  </div>
</template>

<script>
import {BButton, BModal, VBModal} from "bootstrap-vue";
import HttpCatServices from "@/superUp-module/resources/services/httpCatServices";
import HttpLabelServices from "@/superUp-module/resources/services/httpLabelServices";
import HttpTreeService from "@/superUp-module/resources/services/httpTreeService";

export default {
  components: {
    "b-modal": BModal,
    "b-button": BButton
  },
  directives: {
    "b-modal": VBModal
  },
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false
    },
    item: {
      required: true,
      default: null
    },
    refreshTable: {
      type: Boolean,
      required: true,
      default: false
    },
    type: {
      type: String,
      required: true,
      default: null
    },
    typeItems: {
      default: null
    },
    versionItems: {
      default: null
    }
  },
  data() {
    return {
      loading: false,
      loaderError: false,
      password: {
        error: false,
        inputValue: null
      },
      selectedVersion: {
        error: false,
        inputValue: 9
      },
      selectedType: {
        error: false,
        inputValue: null
      }
    }
  },
  methods: {
    select(event) {
      this.selectedType.inputValue = event.target.value;
    },

    selectVersion(event) {
      this.selectedVersion.inputValue = event.target.value;
    },

    createVersion() {
      if (!this.password.inputValue) {
        this.password.error = true;
        return;
      }

      this.loading = true;

      const __this = this;

      HttpTreeService.createVersion().then(() => {
        __this.$emit('update:show', false);
        __this.$emit('update:refreshTable', true);
        __this.password.inputValue = null
        __this.loading = false;
      })
    },

    applyVersion() {
      if (!this.selectedVersion.inputValue) {
        return;
      }

      this.loading = true;

      let data = {
        id: this.selectedVersion.inputValue
      };

      const __this = this;

      HttpTreeService.useVersion(data).then(() => {
        __this.$emit('update:show', false);
        __this.$emit('update:refreshTable', true);
        __this.selectedVersion.inputValue = null;
        __this.loading = false;
      })
    },

    confirmDelete() {
      let httpInstance = null;

      switch (this.type) {
        case 'label':
          httpInstance = HttpLabelServices;
          break;
        case 'tree':
        case 'regenerate':
        case 'all_data':
          httpInstance = HttpTreeService;
          break
        default:
          httpInstance = HttpCatServices;
      }

      if (this.type === 'regenerate') {
        if (!this.password.inputValue) {
          this.password.error = true;
          return;
        }

        if (!this.selectedType.inputValue) {
          this.selectedType.error = true;
          return;
        }

        this.loading = true;

        const __this = this;

        httpInstance.regenerateCache({
          type: this.selectedType.inputValue,
          password: this.password.inputValue
        })
          .then(result => {
            this.$emit('update:show', false);
            this.$emit('update:refreshTable', true);
            this.password.inputValue = null
            this.loading = false;
          })
          .catch(error => {
            Object.keys(error.response.data).forEach((key) => {
              this.loading = false;

              if (key === 'type') {
                __this.selectedType.error = true;
              }

              if (key === 'password') {
                __this.password.error = true;
              }
            });
          });

        return;

      }


      if (this.type === 'all_data') {
        if (!this.password.inputValue) {
          this.password.error = true;
          return;
        }

        if (!this.selectedType.inputValue) {
          this.selectedType.error = true;
          return;
        }

        this.loading = true;

        const __this = this;

        httpInstance.deleteAll({
          type: this.selectedType.inputValue,
          password: this.password.inputValue
        })
          .then(result => {
            this.$emit('update:show', false);
            this.$emit('update:refreshTable', true);
            this.password.inputValue = null
            this.loading = false;
          })
          .catch(error => {
            Object.keys(error.response.data).forEach((key) => {
              this.loading = false;

              if (key === 'type') {
                __this.selectedType.error = true;
              }

              if (key === 'password') {
                __this.password.error = true;
              }
            });
          });

        return;

      }

      this.loading = true;

      httpInstance.delete(this.item)
        .then(result => {
          this.$emit('update:show', false);
          this.$emit('update:refreshTable', true);
          this.loading = false;
        })
        .catch(error => {
          // Handle the error accordingly
          this.loading = false;
          console.error(error);
        });

    },
    closeModal() {
      this.$emit('update:show', false);
      this.$refs.confirmationModal.hide();
    },

    updateShowProp() {
      if (this.show) {
        this.$emit('update:show', false);
      }
    },
  }
}
</script>
<style lang="scss">
.action-modal-tabs {
  .tabs {
    .nav-tabs {
      .nav-link {
        padding: 8px 0!important;
      }
      .nav-item {
        .active {
          background-color: #f49400 !important;
        }
      }
    }
  }
}
</style>

