import flexbenLang from "./flexben_ro.js";
import superUpLang from "./superUp/ro/app.js"
export default [
	{
		/*Modules import*/
		superUpLang: superUpLang,
		/*End Module import*/
		// login form page
		companiesLabel: "COMPANII",
		cardUsersLabel: "UTILIZATORI CARD",
		cardUsersMultibenefitsLabel: " SALARIATI",
		confirmButtonLabel: "CONFIRMA",
		cardUserFieldLabel: "E-mail",
		userFieldLabel: "Utilizator",
		passwordFieldLabel: "Parola",
		loginTitle: " Acceseaza contul",
		loginHelpLink: " Ai probleme de autentificare?",
		forgotPasswordLink: "Ai uitat parola?",
		createAccountLink: " Nu ai inca un cont? Inscrie-te aici",
		loginButtonLabel: "LOGIN",
		customerHelpPopup:
			"Daca esti client sau partener afiliat poti accesa platforma uponline folosind informatiile primite in e-mailul de bun venit, imediat dupa inregistrarea contractului.  <br/> <br /> Informatiile de log in initiale sunt: <br /> <b>  Utilizator  </b>  = codul unic de client sau afiliat;<br /> <b> Parola  </b>  = codul fiscal al companiei. <br /> Parola trebuie schimbata dupa prima logare. ",
		customerMultibenefitsHelpPopup:
			"Daca esti client poti accesa platforma Up MultiBeneficii folosind informatiile primite in e-mailul de bun venit, imediat dupa inregistrarea contractului.  <br/> <br /> Informatiile de log in initiale sunt: <br /> <b>  Utilizator  </b>  = codul unic de client;<br /> <b> Parola  </b>  = codul fiscal al companiei. <br /> Parola trebuie schimbata dupa prima logare. ",
		userLoginHelpPopup:
			"Salut! Te anuntam ca am schimbat modalitatea de autentificare pentru utilizatorii de carduri. Te rugam sa iti creezi un cont nou folosind o adresa de e-mail si datele de identificare cerute. <br /> Vei putea folosi acest cont si pentru a te autentifica in aplicatia Up Mobil.",
		downloadAppMessage: "Descarca noua aplicatie Up Mobil+",
		loadingPopupMessage: " Te rugam sa astepti. Pagina se incarca...",
		errorPopupMessage:
			` Va informam ca toate functionalitatile din Uponline, cum ar fi alimentarea, comanda de carduri si reemiterea acestora, sunt complet operationale.<br/> <br />
			Cu toate acestea, va informam ca, din cauza unei probleme tehnice globale care afecteaza retelele de autorizare, tranzactiile cu cardurile Up Romania sunt momentan indisponibile.
			Echipa tehnica lucreaza in acest moment pentru a remedia situatia, iar noi vom reveni cu actualizari pe masură ce avem informatii suplimentare.<br/>
		    Va asiguram ca facem tot posibilul pentru a restabili serviciile in cel mai scurt timp.<br/>
            Vom reveni cu informatii suplimentare de indata ce avem vesti.<br/>
			Ne cerem scuze pentru inconvenientele create si va multumim pentru intelegere!<br/> <br />
			Echipa Up Romania`,
		// "Din cauza unui incident tehnic nu se pot realiza tranzactii cu cardurile Up . Investigăm soluțiile de remediere a situației și vă ținem la curent cu progresul înregistrat. <br />Ne pare rau pentru disconfortul creat!<br />Echipa Up Romania	",
		errorMessage_recaptcha: "Recaptcha nu este valid",
		errorMessage_otp: "OTP invalid",
		errorMessage_user_blocked: "Utilizatorul este blocat",
		errorMessage_invalid_grant: "Utilizatorul sau parola sunt invalide!",

		errorMessage_invalid_login: "Utilizatorul sau parola sunt invalide!",
		errorMessageResetPassword_PSBC:
			"Informatiile de autentificare nu sunt valide",
		errorMessageResetPassword_bad_credentials:
			"Cod companie sau cod fiscal invalid",
		errorMessage_undefined:
			" Serverul nu raspunde. <br /> Te rugam sa astepti cateva minute si sa incerci din nou.",
		errorMessage_invalid_client:
			" Serverul nu raspunde. <br /> Te rugam sa astepti cateva minute si sa incerci din nou.",
		errorMessage_server_error:
			" Serverul nu raspunde. <br /> Te rugam sa astepti cateva minute si sa incerci din nou.",
		errorMessageResetPassword_server_error:
			" Serverul nu raspunde. <br /> Te rugam sa astepti cateva minute si sa incerci din nou.",
		rememberMeText: "Tine-ma minte",

		// create account page
		selectCardTitle: "Selectati cardul Up",
		flexbenActivationTitle: "Creeaza-ti contul Up MultiBeneficii",
		cardNumberFieldLabel: "Insereaza numarul cardului",
		registrationCodeFieldLabel:
			"Insereaza ultimele 6 cifre din CNP (codul numeric personal)",
		registrationCheckDataTitle: "Verifica informatiile sau modifica datele",
		surnameFieldLabel: "Prenume",
		lastNameField: "Nume",
		emailField: "E-mail",
		usernameField: "E-mail / Telefon mobil",
		mobileField: "Telefon",
		otpField: "Codul OTP",
		setPasswordTitle: "Insereaza parola",
		insertPasswordTitle: "Introdu parola contului existent",
		passwordField: "Parola ",
		confirmPasswordField: "Confirma parola ",
		invalidPasswordMessage: "Parola invalida",
		successMessageTitle: "Felicitari!",
		accountAlready: "Am deja cont (completeaza numele de utilizator existent in campul de mai jos)",
		otpResendMessage: "Retrimite codul OTP",
		registrationSuccessMessage:
			"A fost trimis un e-mail de bun venit la adresa",
		invitationSuccessMessage: "A fost creat cu succes contul de utilizator pentru ",

		flexAccountSuccessMessage:
			"Ai activat cu succes contul de Up MultiBeneficii pentru adresa de e-mail:",
		flexAccountAlreadyCreated:
			"Ai deja un cont inregistrat in platforma uponline, cu numele de utilizator <b>{email}</b> <br /> Pentru a activa beneficiile Up MultiBeneficii in contul tau mergi la pasul urmator.",
		flexAccountNew:
			" Pentru a gestiona beneficiile oferite de angajator, te rugam sa creezi un cont nou.",

		// forgot password
		forgotPasswordTitle: "Ai uitat parola?",
		resetPasswordTitle: "Reseteaza parola",
		forgotPasswordMessage:
			"	Pentru resetarea parolei contului, te rugam sa introduci user-ul de login cu care ai creat contul: adresa de email sau numarul de telefon, in functie de modul in care te-ai inregistrat initial. <br/>	Iti recomandam sa verifici cu atentie daca te-ai inregistrat folosind adresa ta de email sau numarul de telefon, deoarece codul OTP va fi trimis doar pe una dintre aceste doua optiuni.",
		forgotPasswordMessageOTP:
			"	Te rugam sa introduci codul OTP primit pe e-mail.",
		otpResendText: "Retrimite codul OTP",
		resetPasswordSuccessMessage: "Parola a fost resetata cu succes",
		changePasswordSuccessMessage: "Parola a fost modificata cu succes",
		companyCodeLabel: "Nume utilizator",
		fiscalCodeLabel: "Cod fiscal",
		companyCodeOrEmailLabel: "Nume utilizator",
		fiscalCodeOrCodeCompanyLabel: "Cod fiscal",
		forgotPasswordMessageCompany:
			"	Te rugam sa introduci numele de utilizator al companiei <br /> si codul fiscal pentru a schimba parola.",
		resetPasswordSuccessMessageCompany:
			"A fost transmis un email ce contine link-ul de schimbare a parolei",
		resetPasswordMessageCompany:
			"	Te rugam sa introduci noua parola pentru <br /> contul companiei tale.",
		// cookies box
		cookiesBoxText:
			"Site-ul nostru foloseste cookie-uri pentru a-ti afisa continut personalizat. Tot cookie-uri foloseste si serviciul de monitorizare statistics de trafic in site, serviciu oferit de Google. <br />Te rugam sa citesti politica noastra si sa iti exprimi preferintele legate de cookie-uri.",
		cookiesAcceptBotton: "ACCEPTA",
		cookiesCookiePreferences: "PREFERINTE COOKIES",
		notificationsTitle: "Optiuni notificari",
		notificationsTransactionalLabel: "Notificari tranzactionale",
		notificationsMarketingTitle: "Notificari de marketing",
		notificationsLabel: "Notificari",
		notificationsSMSLabel: "SMS",
		notificationsEmailLabel: "E-mail",
		notificationsCongrats:
			"Acum iti poti verifica soldul si poti descoperi partenerii afiliati care accepta cardurile Up Romania",
		completeRegistrationLogin: "INTRA IN CONT!",
		// page name
		searchName: "Cauta",
		searchBeneficiaryName: "Cauta Beneficiar",
		dashboardName: "Tablou de bord",
		cardsName: "Carduri",
		servicesName: "Servicii",
		faqName: "Intrebari frecvente",
		settingsName: "Setari",
		myAccountName: "Contul meu",
		contactName: "Contact",
		beneficiarySupportName: "Detalii Beneficiar",
		gdprName:
			"Politica privind protectia datelor cu caracter personal Up Online",
		gdprMultibenName:
			"Politica privind protectia datelor cu caracter personal Up MultiBeneficii",
		termsAndConditionsName: "Termenii si conditiile Up Online",
		termsAndConditionsMultibenName: "Termenii si conditiile Up MultiBeneficii",
		personalDataPolicyName:
			"Politica privind protectia datelor cu caracter personal",
		logoutName: "Iesire",
		deselectName: "Deselectare",
		// beneficiary dashboard
		cardBalanceBubbleTitle: "Sold card",
		balanceBubbleTitle: "Soldul meu",
		currency: "lei",
		spentAmountMessage: "Ai cheltuit {amount} de la ultima alimentare",
		offersButtonLabel: "Oferte",
		partnersButtonLabel: "Parteneri",
		recommendPartnersButtonLabel: "Recomanda partener",
		expiringFundsTitle: "Fonduri care expira",
		transactionsListTitle: "LISTA TRANZACTII",
		transactionListPeriodWeek: "Acum {index} saptamani",
		transactionListPeriodThisWeek: "Saptamana aceasta",
		transactionStatsTitle: "Statistici tranzactii",
		transactionNoTransactionsMessage:
			"Nu ai nici o tranzactie efectuata in perioada selectata",
		transactionStatsDDLThisMonth: "Luna aceasta",
		transactionStatsDDLLastMonth: "Ultima luna",
		transactionStatsDDLLast3Month: "Ultimele 3 luni",
		transactionStatsDDLLastMonths: "Ultimele {index} luni",
		transactionStatsDDLLastYear: "Ultimul an",
		transactionStatsDDLCustom: "Specific",
		beneficiaryPopupMessageNewTC:
			"Va informam ca Up Romania SRL a modificat Termenii si Conditiile de utilizare a cardurilor si a tichetelor pe suport electronic UP aplicabile utilizatorilor de carduri Up Dejun, Up Cadou, Up Vacanta si Up Cultura. <br /> <br /> Data de intrare in vigoare a documentului este 01.10.2024. Utilizarea Cardului dupa intrarea in vigoare a noilor conditii echivaleaza cu acceptarea definitiva a acestora de catre dumneavoastra.  <br /> <br /> Versiunea actualizata a documentului se poate consulta pe site-ul Up Romania Srl, accesand <a href='http://www.upromania.ro' target='_blanc' >www.upromania.ro</a>. <br /> <br />Va multumim! <br />",
		beneficiaryPopupTitleMobilPlus:
			"Scaneaza pentru a descarca aplicatia ",
		beneficiaryPopupMessageMobilPlus:
			"Sau o poti gasi pe <b> App Store </b>, <b> Play Store </b> sau <b> Huawei Store </b> ",
		// beneficiary cards
		cardsPageTitle: "CARDURILE MELE",
		cardValidLabel: "Valabil pana la ",
		showPinText:
			"*Poti vizualiza codul PIN instaland aplicatia Up Mobil si accesand ecranul de administare a cardului, din sectiunea 'Cardurile mele'.",
		// card svg text:
		expireAtLabel: "Expira la ",
		soldLabel: "Sold: ",
		// beneficiary services
		servicesPageTitle: "SERVICII",
		serviceName1: "RECOMANDA UN PARTENER",
		serviceName2: "DESCARCA APLICATIA",
		serviceName3: 'Campanie atragere prospecti "Ai recomandat, ai castigat"',
		serviceName4: "OFERTE DEDICATE",
		serviceName5: "RETEA SI HARTA PARTENERI",
		serviceName6: "TRIMITE-NE FEEDBACK",
		textRecommendAParterner:
			"Iti doresti sa poti folosi tichetele Up Romania intr-o unitate care nu face inca parte din reteaua noastra de afiliere? Poate fi vorba de un magazin, un restaurant, o cresa, o unitate de cazare sau poate o agentie de turism. Da-ne de stire si luam rapid legatura cu ei.",
		placeholderCompanyName: "Denumire companie*",
		placeholderFieldOfActivity: "Domeniu de activitate",
		placeholderDistrict: "Judet*",
		placeholderCity: "Oras*",
		placeholderNameAndSurname: "Nume si prenume*",
		placeholderPhone: "Telefon*",
		placeholderEmployerName: "Denumire angajator",
		placeholderEmail: "E-mail*",
		placeholderVerificationCode: "Cod de verificare*",
		textButtonForm: "TRIMITE",
		textMemberGetMember:
			"Recomanda serviciile noastre unei societati care nu ofera inca tichete de masa angajatilor sai si te vom rasplati cu tichete cadou.\n Completeaza formularul de mai jos si castiga tichete cadou, daca societatea careia ne recomanzi decide sa acorde tichetele de masa Up Dejun angajatilor sai. \nIn functie de numarul de angajati , poti castiga intre 50 si 330 lei.",
		titleYourCoordinates: "DATELE TALE",
		titleRecommendedCompanyDetails: "DETALII PRIVIND SOCIETATEA RECOMANDATA",
		placeholderMemberCompanyName: "Societate*",
		placeholderMemberName: "Nume*",
		placeholderMemberRole: "Rol",
		placeholderMemberDistrict: "Judet*",
		placeholderMemberCity: "Oras*",
		placeholderMemberPhone: "Telefon*",
		placeholderMemberEmail: "E-mail",
		placeholderComment: "Mesaj*",
		placeholderMemberVerificationCode: "Cod de verificare",
		textAcceptPrivacyPolicy:
			"Sunt de acord cu colectarea si folosirea datelor de mai sus de catre Up Romania in conformitate cu ",
		textPrivacyPolicy:
			"Politicile de gestionare a datelor cu caracter personal",
		textReadAndAgree: "Sunt de acord cu ",
		textReadAndAgreeAnd: " si ",
		textCampaignRegulations:
			'"Ai recomandat, ai castigat", inclusiv Sectiunea 7 privind prelucrarea datelor cu caracter personal, si sunt de acord cu acesta.',
		textFeedback:
			"Parerea ta reprezinta un element important in procesul nostru de a imbunatati permanent serviciile pe care ti le oferim.",
		chooseOptionLabel: "Alege produsul...",
		noOffersMessage: "Nu exista oferte momentan.",
		// pin || card request message
		pinReqMsg:
			"Ai solicitat deja un nou cod PIN. Te rugam sa astepti ca angajatorul tau sa-ti valideze solicitarea.",
		cardReqMsg:
			"Ai solicitat deja un card nou. Te rugam sa astepti ca angajatorul tau sa-ti valideze solicitarea.",
		plasticReqMsg:
			"Ai solicitat deja un card fizic. Te rugam sa astepti ca angajatorul tau sa-ti valideze solicitarea.",

		cardVirtualButtonLabel: "Card virtual",
		cardPhysicalButtonLabel: "Card fizic",
		// confirm pin || card request message
		confirmPinReqMsg: "Sigur doresti sa soliciti reemiterea codului PIN?",
		confirmPlasticReqMsg: "Confirma solicitarea de card fizic",
		confirmPlasticReqCampaignMsg: "Alege cardul virtual si noi donam 1 euro pentru tine catre Fundatia Inovatii Sociale Regina Maria, pentru a sprijini educatia copiilor defavorizati. Alegerea ta conteaza!",
		confirmCardReqMsg: "Sigur doresti sa soliciti emiterea unui card nou? <br /> ",
		confirmCardReqVirtualMsg: "Felicitari! Alegerea ta face diferenta! Cu fiecare card virtual emis, donam 1 euro pentru tine catre Fundatia Inovatii Sociale Regina Maria, pentru a sprijini educatia copiilor defavorizati.",
		confirmCardReqPhysicalMsg: "Schimba-ti alegerea! Ai un motiv in plus sa pastrezi doar cardul virtual: DONAM 1 EURO pentru tine, catre Fundatia Inovatii Sociale  Regina Maria, pentru a sprijini educatia copiilor defavorizati",
		confirmSMSReqMsg:
			"Sigur doresti schimbarea optiunii de primire a SMS-urilor pentru mesaje tranzactionale si alimentari?",
		// error pin || card request message
		errorPinOrCardReqMsg:
			"Statusul cardului nu este corect pentru actiunea selectata",
		selectDate: "Select intervalul de date",
		// Buttons card options
		activeCardText: "<br />  ACTIVEAZA <br /> CARD",
		blockCardText: "<br /> BLOCHEAZA<br /> CARD",
		unblockCardText: "<br /> DEBLOCHEAZA <br /> CARD",
		requestPinText: "<br /> REEMITE <br /> PIN",
		requestPlasticText: "<br /> EMITE <br /> CARD	",
		requestCardText: "<br /> REEMITE <br /> CARD",
		faqText: "<br />FAQ",
		// Success message
		successMsg:
			"Cererea de reemitere a {PIN}-ului a fost transmisa cu succes<br /> catre angajator si va urmeaza a fi validata. ",
		// Chart
		expenseText: "Cheltuieli",
		soldText: "Sold",
		// Download Apps
		downloadApps: "Descarca aplicatia Up Mobil",
		// Setting Items Title
		settingPageTitle: "SETARI",
		settingItem1: "CONTUL MEU",
		settingItem2: "NOTIFICARI MARKETING",
		settingItem3: "SCHIMBA PAROLA",
		settingItem4: "STERGE CONTUL",
		// Change Password Form
		placeholderOldPassword: "Parola actuala",
		deleteAccountText: "Esti sigur ca doresti stergerea contului?",
		passwordCriteria1Text: "Minim 8 caractere",
		passwordCriteria2Text: "Minim un caracter litera",
		passwordCriteria3Text: "Minim un caracter numeric",
		// error messages
		errorMessage_BS12: "Parola curenta nu este corecta",
		errorMessage_BS20: "Parola noua invalida",
		errorMessage_EMPTY_CARDS: "Selectarea unui card este obligatorie.",
		errorMessage_500:
			" Serverul nu raspunde. <br /> Te rugam sa astepti cateva minute si sa incerci din nou.",
		errorMessage_server:
			" Serverul nu raspunde. <br /> Te rugam sa astepti cateva minute si sa incerci din nou.",
		errorMessage_503:
			" Documentul cerut nu este momentan disponibil. <br /> Serverul nu raspunde. <br /> Te rugam sa astepti cateva minute si sa incerci din nou.",
		errorActionBeneficiary_PACKAGE:
			" Pentru pachetul de servicii contractat nu se pot da comenzi astazi. Te rugam sa revii in perioada urmatoare pentru efectuarea comenzii.",
		errorMessage_PACKAGE:
			" Pentru pachetul de servicii contractat nu se pot da comenzi astazi. Te rugam sa revii in perioada urmatoare pentru efectuarea comenzii.",
		errorMessage_PSB1:
			"Ai incercat autentificarea de prea multe ori cu o parola gresita. <br/> Contul tau este blocat {param} minute",
		errorMessage_PSB2:
			"Ai incercat sa te autentifici de prea multe ori cu o parola gresita. <br/> Contul tau este blocat {param} ore.",
		errorMessage_PSB3:
			"Ai incercat sa te autentifici de prea multe ori cu o parola gresita. <br/> Contul tau este blocat {param} ore si {param1} minute.",
		errorMessage_LINK_EXPIRE: "Link-ul a fost deja confirmat.",

		errorMessage_403: "Utilizatorul sau parola sunt invalide!",
		errorMessage_400: "Utilizatorul sau parola sunt invalide!",
		// Read More || Read less
		readMore: "Mai mult",
		readLess: "Mai putin",
		// Confirm activate || block || unblock card request message
		confirmActvatedCardReqMsg: "Sigur doresti sa activezi cardul?",
		confirmBlockCardReqMsg: "Sigur doresti sa blochezi cardul?",
		confirmUnblockCardReqMsg: "Sigur doresti sa deblochezi cardul?",
		msgEmailSuccess: "Email-ul s-a trimis cu succes!",
		notificationMessage: "Nu aveti nici o notificare!",
		cardStatus_A: "Activ",
		cardStatus_B: "Blocat",
		cardStatus_F: "Blocat",
		cardStatus_D: "Dezactivat",
		cardStatus_C: "Inactiv",
		cardStatus_J: "Anulat",
		cardStatus_L: "Anulat",
		cardStatus_X: "Expirat",
		cardStatus_PF: "Proforma",
		cardStatus_E: "Asteptare plata",
		cardStatus_I: "Procesare",
		cardStatus_M: "Procesare",
		cardStatus_N: "Procesare",
		cardStatus_O: "Procesare",
		cardStatus_P: "Procesare",
		cardStatus_R: "Procesare",
		cardStatus_S: "Procesare",
		cardStatus_RN: "Expira in curand",
		cardStatus_RNY: "Va fi reinnoit",
		cardStatus_RNYV: "Va fi reinnoit virtual",
		cardStatus_RNYF: "Va fi reinnoit fizic",
		cardStatus_RNV: "Reinnoit",
		cardStatus_RNN: "Nu va fi reinnoit",
		cardStatus_RNX: "Nereinnoit",
		cardStatus_RCV: "Reemitere card",
		cardStatus_RV: "Reemitere card virtual",
		cardStatus_RC: "Reemitere card fizic",
		cardStatus_RM: "Emitere card fizic",
		cardStatus_OH: "Blocat",
		cardStatus_RP: "Reemitere PIN",
		cardStatus_: "Selecteaza...",
		cardType_: "Selecteaza...",
		cardType_V: "Virtual",
		cardType_P: "Fizic",

		// notificari
		notificationPageTitle: "Lista notificari",
		noNotifications: "Nu ai nici o notificare.",
		notificationAction_DELETE: "Sterge",
		notificationAction_READ: "Marcheaza ca citit",
		actionsNotificationText:
			"Pentru cele {count} notificari selectate mai sus doresti:",
		notificationActionSelect: "Administrare notificari",
		showAllNotifications: "Vezi toate mesajele",
		goToSetting: "Mergi la Setari",
		deleteNotification: "Sterge mesajul",

		// dashboard
		welcomeText:
			"Bine ai venit pe noua platforma Up Online dedicata clientilor. Descopera noile functionalitati si bucura-te de o experienta imbunatatita, mai moderna si mai intuitiva. Ti-am pregatiti un ghid de utilizare si iti stam la dispozitie pentru intrebari suplimentare.",
		paperServerDownError:
			" Te informam ca in intervalul 00:00 - 07:00 functionalitati precum istoric comenzi tichete hartie, istoric facturi, comanda tichete hartie si generarea proformei nu sunt disponibile. Ne vedem de dimineata.",
		downloadGuide: "Descarca ghidul",
		acceptTerms: "Sunt de accord",
		declineTerms: "Nu sunt de accord",
		noProductsText:
			"Nu ai disponibil nici un produs. Te rugam sa iei legatura cu Up Romania.",
		dashboardInfoTitle_TOTAL_ORDERS_COUNT: "{value}",
		dashboardInfoTitle_TOTAL_ORDERS_AMOUNT: "{value} lei",
		dashboardInfo_TOTAL_ORDERS_COUNT:
			"comenzi plasate <br/> de la inceputul anului",
		dashboardInfo_TOTAL_ORDERS_AMOUNT:
			"economisiti <br/> de la inceputul anului",
		dashboardInfo_TOTAL_BENEFICIARIES: "beneficiari de card activi",
		dashboardInfoTitle_TOTAL_BENEFICIARIES: "{value}",
		dashboardInfoReissuePINTitle:
			"Aveti {count} solicitari de PIN care asteapta sa fie aprobate",
		dashboardInfoReissuePINMessage:
			"Mergeti pe pagina de beneficiari pentru a le valida sau anula.",
		dashboardInfoReissuePINButton: "Gestioneaza cererile de PIN",
		dashboardInfoReissueCardTitle:
			"Aveti {count} solicitari de reemitere card care asteapta sa fie aprobate",
		dashboardInfoReissueCardButton: "Gestioneaza cererile de reemitere card",
		dashboardInfoIssueCardTitle:
			"Aveti {count} solicitari de emitere card care asteapta sa fie aprobate",
		dashboardInfoIssueCardButton: "Gestioneaza cererile de emitere card",
		dashboardTrackingOrderTitle:
			"Comanda ta de {product} din {date}, in valoare de {amount}, este in curs de livrare.",
		dashboardTrackingOrderPlaced: "Plasata",
		dashboardTrackingOrderProcessed: "Procesata",
		dashboardTrackingOrderPaid: "Plata confirmata",
		dashboardTrackingOrderSent: "Preluata de curier",
		dashboardTrackingOrderDelivery: "Data de livrare estimata",
		signAdditionalActAffiliate:
			"Completeaza adresa de email pentru accesarea actului aditional",
		seeAllInvoicesButtonLabel: "VEZI TOATE",
		averageChartLabel: "Valoare",
		countChartLabel: "Nr. tranzactii",
		customerDashboardCardStats: "Sumar carduri",
		statsCardsActive: "Carduri active",
		statsCardsBlocked: "Carduri blocate",
		statsCardsInactive: "Carduri inactive",
		statsCardsSMS: "Carduri cu SMS activ",
		statsCardsDisabled: "Carduri cu sold mai mic de 5 lei",
		recommendationsTitle: "Recomandari",
		averageTransactionAmount: "Cosul mediu de cumparaturi",
		averageTransactionNumber: "Media tranzactiilor lunare",
		usageVolumeChartTitle: "Volum tranzactii",
		usagePerRegion: "Volum tranzactii per judet",
		topSellers: "Top judete",
		noTransactionsTitle: "Nu exista tranzactii pentru perioada selectata",
		noTransactionsMessage:
			"Te rugam sa selectezi o noua perioada pentru afisarea statisticilor legate de tranzactii.",
		cardRecommendText1:
			"Mentine numarul cardurilor active cat mai aproape de cel total.",
		cardRecommendText2:
			"Aproba cererile de reemitere PIN sau reemitere card venite de la beneficiari pentru a pastra numarul cardurilor blocate cat mai aproape de 0.",
		cardRecommendText3:
			"Recomanda beneficiarilor sa activeze cardurile Up, simplu si rapid, folosind aplicatia Up Mobil sau platforma Up Online.",
		cardRecommendText4:
			"Ofera-le beneficiarilor acces la informatii in timp real despre alimentari si tranzactii prin serviciul Up SMS.",
		cardRecommendText5:
			"Creste valoarea tichetelor acordate pentru a le oferi angajatilor o putere de cumparare mai mare.",

		// cutomer settings
		companySettingsTitle: "Setari cont",
		VATLabel: "VAT",
		VATLabelAFF: "Numar inregistrare la registrul comertului",
		deliveryAddressLabel: "Adresa principala de livrare",
		invoiceAddressLabel: "Adresa sediu social",
		changePasswordLabel: "Schimba parola",
		changePasswordSuccess: "Datele au fost modificate cu succes!",
		payVAT: "Platitor de TVA",
		orderPersonTitle: "Persoana de comanda",
		uploadFileForVATText:
			"Pentru a putea modifica <i>numarul de inregistrare la registrul comertului</i> sau <i>adresa sediului social</i> te rugam sa incarci Certificatul de inregistrare actualizat.",
		sendFileText: "TRIMITE FISIER",
		noPersonOrders: "Nu exista persoana de comanda pe acest produs.",
		noCustomerIBAN: "Nu exista IBAN pentru acest produs.",
		customerBankAccountTitle: "Cont bancar",
		customerBankTitle: "Banca",
		errorSentCustomerIBAN_NO_IBAN: "Este necesara completarea campului IBAN.",
		errorSentCustomerIBAN_NO_IBAN_VALID: "IBAN-ul nu este valid.",
		errorSentCustomerIBAN_NOT_SENT:
			"A aparut o problema in sistem, va rugam sa incercati mai tarziu!",
		addIBANText: "Adauga IBAN",
		ibanAllProductsText: "Aplica modificarile pentru toate produsele Up.",

		// page name,
		ordersName: "Comenzi",
		beneficiariesName: "Beneficiari",
		beneficiariesNameNew: "Beneficiari noi",
		deliveryPointsName: "Adrese livrare",
		invoicesName: "Facturi",
		newOrderName: "Comanda noua",
		orderNowName: "Comanda acum",

		// invoices
		invoiceSeriesHeader: "Serie",
		invoiceNumberHeader: "Numar",
		invoiceDateHeader: "Data",
		invoiceStatusHeader: "Status plata",
		invoiceAmountHeader: "Valoare",
		invoiceDownloadPDFHeader: "Descarca PDF",
		invoiceDownloadXMLHeader: "Descarca XML",
		invoiceDownloadEXLHeader: "Descarca EXCEL",
		lastInvoicesTitle: "Ultimele facturi",
		pendingInvoicesTitle: "Facturi neachitate",
		downloadLinkText: "Descarca",
		noInvoicesMessage: "Nu exista facturi pentru acest produs.",
		poNumberName: "Numar PO",
		poNumberLabel: "Introduceti numarul PO in campul de mai jos",
		errorPOMessage: "Numarul PO nu a putut fi salvat.",
		poMessage: "Numarul PO a fost modificat cu succes.",


		invoicesFilterTitle: "Filtreaza facturile",
		invoicesFilterProduct: "Produs",
		invoicesFilterPlatform: "Sursa",
		invoicesFilterStatus: "Status",
		invoicesFilterPeriod: "Perioada",
		invoiceStatus_L: "Platita",
		invoiceStatus_R: "Platita",
		invoiceStatus_P: "Platita",
		invoiceStatus_V: "Plata in procesare",
		invoiceStatus_U: "Neplatita",
		invoiceStatus_N: "Neplatita",
		"invoiceStatus_Not paid": "Neplatita",
		invoiceStatus_O: "Termen depasit",
		invoiceNoElectronicInvoices:
			"Pentru a beneficia de avantajele serviciului de facturare electronic, te rugam sa completezi formularul de acord si sa il trimiti la numarul de fax 021 313 00 74 sau pe adresa de e-mail ",
		invoiceDownloadAgreementForm: "Descarca formularul de acord",
		invoiceBenefitsElectronicInvoicesTitle:
			"Avantajele sistemului de facturare electronic:",
		invoiceBenefitsElectronicInvoicesL1:
			"Rapiditate – Receptionezi factura fiscala in timp mult mai scurt, fara a mai fi necesar sa astepti curierul sau posta.",
		invoiceBenefitsElectronicInvoicesL2:
			"Acces permanent la facturile originale.",
		invoiceBenefitsElectronicInvoicesL3:
			"Posibilitatea de a reduce costurile legate de consumul de hartie si de spatiul ocupat pentru arhivare, folosind arhivarea electronica.",
		invoiceBenefitsElectronicInvoicesL4:
			"Alegi o modalitate prietenoasa cu mediul inconjurator de a va gestiona facturile.",

		// beneficiaries page
		beneficiariesTitle: "Beneficiari card",
		beneficiariesSelectAll: "Selecteaza toti beneficiarii",
		downloadBeneficiariesList: "Descarca lista de beneficiari",
		beneficiariesDeselectAll: "Deselecteaza toti beneficiarii",
		beneficiariesDescription: "Gestiunea beneficiarilor de card",
		beneficiariesInfoTitle: "Doresti sa adaugi un nou beneficiar?",
		beneficiariesInfoMessage:
			"Beneficiarii sunt adaugati automat dupa emiterea primului card. Daca aveti noi angajati, mergeti la sectiunea de 'Comanda noua' pentru a le emite noi carduri",
		noBeneficiariesInfoMessage:
			"Nu exista beneficiari care sa indeplineasca criteriile selectate.",
		beneficiariesFilterTitle: "Filtreaza beneficiarii",
		beneficiariesFilterProduct: "Produs",
		beneficiariesFilterCardType: "Tip card",
		beneficiariesFilterCardStatus: "Status Card",
		beneficiariesFilterDeliveryAddress: "Adresa livrare",
		beneficiariesFilterKeyword: "Cauta dupa nume sau CNP",
		beneficiariesFilterProformNumber: "Nr. proforma / Nr. comanda",
		beneficiariesTableSurname: "Prenume",
		beneficiariesTableName: "Nume",
		beneficiariesTableNameAndSurname: "Nume si prenume",
		beneficiariesTableCNP: "CNP",
		beneficiariesTableCard: "Card",
		beneficiariesTableCardStatus: "Status card",
		beneficiariesTableCardExpire: "Expira la",
		beneficiariesTableAction: "Actiuni",
		beneficiariesTableStatus: "Status beneficiar",
		beneficiariesTableSeeMore: "Detalii",
		beneficiariesTableDownload: "Descarca lista",
		beneficiariesTableNoOfTickets: "Numar tichete",
		beneficiariesTableTicketValue: "Valoare tichet",
		beneficiariesTableTotalValue: "Valoare totala",
		beneficiariesTableAddress: "Adresa",
		beneficiariesTableCardType: "Tip card",
		beneficiariesTableCardTypeVirtual: "Virtual",
		beneficiariesTableCardTypePhysical: "Fizic",
		orderDetailsDeliveryLabel: "Adrese de livrare",
		backButtonLabel: "Inapoi",
		beneficiariesFileUploadText:
			"Incarca fisierul Excel cu 3 coloane : Nume, Prenume, CNP, Adresa livrare",
		beneficiariesUpdateInfoText:
			"Actualizeaza informatiile beneficiarilor tai folosind un fisier Excel cu structura: <br /> <b>Nume, Prenume, CNP, Nr. telefon, Email</b>",
		updateButtonLabel: "Actualizeaza",
		cardStatus_unknown: "Procesare",
		beneficiaryStatus_Active: "Activ",
		beneficiaryStatus_Inactive: "Inactiv",
		actionsText: "Pentru cei {count} beneficiari selectati mai sus doresti:",
		beneficiariesActionSelect: "Adauga Beneficiari",
		cardsAction_CHOOSE: "Selecteaza...",
		cardsAction_SMS: "Actualizare SMS",
		cardsAction_ACT: "Activare card",
		cardsAction_BLOK: "Blocare card",
		cardsAction_REPL: "Reemitere card",
		cardsAction_PIN: "Reemitere PIN",
		cardsAction_CLO: "Emitere card fizic",
		cardsAction_VALIDATE_REPL: "Validare reemitere card",
		cardsAction_VALIDATE_PIN: "Validare reemitere PIN",
		cardsAction_VALIDATE_REN: "Validare reinnoire card",
		cardsAction_VALIDATE_CLO: "Validare emitere card fizic",
		cardsAction_CANCEL_PIN: "Anulare cerere reemitere PIN",
		cardsAction_CANCEL_REPL: "Anulare cerere reemitere card",
		cardsAction_CANCEL_CLO: "Anulare cerere emitere card",
		cardsAction_C_REN: "Anulare reinnoire card",
		cardsAction_DELETE_BENEFICIARY: "Stergere beneficiar",
		cardsAction_SUSPEND_BENEFICIARY: "Inactivare beneficiar",

		actionConfirmMessage:
			"Confirma lista de beneficiari pentru actiunea selectata.",
		actionSuccessMessage: "Actiunea de {action} a fost realizata cu succes",
		"BENEFICIARIES.UPDATE.ERROR": "A aparut o eroare. Beneficiarii nu au fost actualizati.",
		"BENEFICIARIES.UPDATE.FILE.EMPTY": "Este necesar sa incarcati un fisier Excel",
		beneficiariesUpdateMessage:
			"Actualizarea datelor s-a realizat cu succes",

		details: "Detalii",
		reviewBeneficiariesText:
			"Pentru a gestiona mai eficient operatiunile de comanda, alimentare si reinnoire card iti recomandam sa actualizezi constant lista beneficiarilor din acest ecran.",

		virtualBeneficiariesText:
			"Felicitari! Alegerea ta face diferenta! <br /> Cu fiecare card virtual emis, donam 1 euro pentru tine catre Fundatia Inovatii Sociale Regina Maria, pentru a sprijini educatia copiilor defavorizati.",
		physicalBeneficiariesText:
			"Alege sa oferi carduri virtuale angajatilor tai si Up Romania va dona 1 euro pentru fiecare card virtual emis catre Fundatia Inovatii Sociale Regina Maria, pentru a sprijini educatia copiilor defavorizati.",
		physicalBeneficiariesTextRepl:
			"Schimbă-ți opțiunea! Ai un motiv în plus să alegi cardul virtual pentru angajații tăi. <br /> Up România va dona 1 euro pentru fiecare angajat al tău care beneficiază de card virtual. Donația va fi direcționată către Fundație Inovații Sociale Regina Maria, pentru a sprijini educația copiilor defavorizați. <br /> Alegerea ta contează! ",
		oldFileInfoText:
			"Atentie! O noua versiune a „Tabelului de comanda card” este acum disponibila, oferind posibilitatea de a alege tipul de card pentru fiecare angajat. Varianta actuala poate fi utilizata pana la data de 31.12.2024, dupa care se va trece la noua versiune.",
		newFileInfoText:
			"Important! Tabelul de comanda pentru carduri a fost actualizat cu o noua coloana care iti permite sa selectezi tipul de card pentru fiecare beneficiar. Asigura-le angajatilor tai acces rapid si simplu la cardul virtual.",


		// beneficiary details from client
		suspendUserLabel: "Inactiveaza",
		deleteUserLabel: "Sterge",
		cnpFieldLabel: "CNP",
		saveChangesLabel: "Salveaza",
		editButtonLabel: "Editeaza",
		cancelChangesLabel: "Renunta la modificari",
		deliveryPointLabel: "Adresa de livrare",
		cardExpiryLabel: "Expira la:",
		currentBalanceLabel: "Sold disponibil:",
		activateCardButtonLabel: "ACTIVARE CARD",
		blockCardButtonLabel: "BLOCARE CARD",
		reissueCardButtonLabel: "REEMITE CARD",
		issueCardButtonLabel: "EMITE CARD",
		reissuePINButtonLabel: "REEMITE PIN",
		validateIssueCardButtonLabel: "VALIDARE EMITERE CARD",
		validateReissueCardButtonLabel: "VALIDARE REEMITERE CARD",
		validateReissuePINButtonLabel: "VALIDARE REEMITERE PIN",
		cancelIssueCardButtonLabel: "ANULARE CERERE EMITERE CARD",
		cancelReissueCardButtonLabel: "ANULARE CERERE REEMITERE CARD",
		cancelReissuePINButtonLabel: "ANULARE CERERE REEMITERE PIN",
		notificationsChooseLabel: "Notificari SMS tranzactii si alimentari",
		notificationsChooseOptionYes: "Da",
		notificationsChooseOptionNo: "Nu",

		beneficiaryOrderHistory: "Istoric actiuni",
		orderType_PAPER: "Comanda tichete de hartie",
		orderType_NEW: "Comanda card",
		orderType_NEWCR: "Alimentare card",
		orderType_PIN: "Reemitere PIN",
		orderType_CLO: "Emitere card",
		orderType_REPL: "Reemitere card",
		orderType_REN: "Reinnoire card",
		orderType_RENOUV: "Reinnoire card",
		orderType_VALIDATE_CLO: "Validare reemitere card",
		orderType_VALIDATE_REPL: "Validare reemitere card",
		orderType_VALIDATE_PIN: "Validare reemitere PIN",
		orderType_MODIFY_DATA: "Modificare date beneficiar",
		lastToppingUpLabel: "Ultima alimentare: ",
		confirmActionBeneficiary_PIN:
			"Esti sigur ca doresti reemiterea PIN-ului pentru cardul {product}? ",
		confirmActionBeneficiary_REPL:
			" Esti sigur ca doresti reemiterea cardului {product}? ",
		confirmActionBeneficiary_CLO:
			" Esti sigur ca doresti emiterea cardului fizic {product}? ",
		confirmActionBeneficiary_VALIDATE_PIN:
			" Esti sigur ca doresti reemiterea PIN-ului pentru cardul {product}? ",
		confirmActionBeneficiary_VALIDATE_REPL:
			" Esti sigur ca doresti reemiterea cardului {product}? ",
		confirmActionBeneficiary_VALIDATE_CLO:
			" Esti sigur ca doresti emiterea cardului fizic {product}? ",
		confirmActionBeneficiary_CANCEL_PIN:
			" Esti sigur ca doresti anularea cererii de reemitere a PIN-ului pentru cardul {product}? ",
		confirmActionBeneficiary_CANCEL_REPL:
			" Esti sigur ca doresti anularea cererii de reemitere a cardului {product}? ",
		confirmActionBeneficiary_CANCEL_CLO:
			" Esti sigur ca doresti anularea cererii de emitere a cardului fizic {product}? ",
		confirmActionBeneficiary_SMS:
			" Esti sigur ca doresti schimbarea optiunii de SMS pentru cardul {product}?",
		confirmActionBeneficiary_DELETE:
			" Esti sigur ca doresti stergerea acestui beneficiar? <br /> Atentie! Aceasta operatie este ireversibila si nu veti mai avea acces la informatiile legate de acesta.",
		confirmActionBeneficiary_INACTIVATE:
			" Esti sigur ca doresti inactivarea acestui beneficiar?  <br />  Beneficiarul va putea fi reactivat ulterior ",
		confirmActionBeneficiary_ACTIVATE:
			" Esti sigur ca doresti activarea acestui beneficiar?  ",
		confirmActionBeneficiary_ACT:
			" Esti sigur ca doresti activarea acestui card?  ",
		confirmActionBeneficiary_BLOK:
			" Esti sigur ca doresti blocarea acestui card?  ",
		successActionBeneficiary_CANCEL_PIN:
			"Cererea de reemitere PIN pentru cardul {product} a fost anulata cu succes. ",
		successActionBeneficiary_CANCEL_REPL:
			"Cererea de reemitere pentru cardul {product} a fost anulata cu succes.",
		successActionBeneficiary_VALIDATE_PIN:
			"Reemiterea PIN-ului pentru cardul {product} s-a realizat cu succes. Poti urmari statusul acestei comenzi in meniul de Comenzi.",
		successActionBeneficiary_VALIDATE_REPL:
			"Reemiterea cardului {product} s-a realizat cu succes. Poti urmari statusul acestei comenzi in meniul de Comenzi.",
		successActionBeneficiary_PIN:
			"Reemiterea PIN-ului pentru cardul {product} s-a realizat cu succes. Poti urmari statusul acestei comenzi in meniul de Comenzi.",
		successActionBeneficiary_REPL:
			"Reemiterea cardului {product} s-a realizat cu succes. Poti urmari statusul acestei comenzi in meniul de Comenzi.",
		successActionBeneficiary_DELETE:
			"Stergerea beneficiarului s-a realizat cu succes.",
		successActionBeneficiary_INACTIVATE:
			"Inactivarea beneficiarului s-a realizat cu succes.",
		successActionBeneficiary_SMS:
			"Optiunea de SMS pentru cardul {product} a fost inregistrata cu succes",
		errorActionBeneficiary_CRRR:
			"Pentru cardul {product} mai exista o cerere de reemitere efectuata astazi.",
		errorActionBeneficiary_ASFI:
			"Pentru cardul {product} ai ales optiune SMS invalida.",
		errorActionBeneficiary_CRBS: "Cererea de reemitere este invalida.",
		errorActionBeneficiary_CRNF:
			"Cardul pentru care s-a efectuat cererea nu exista.",
		errorActionBeneficiary_409:
			"Pentru acest card mai exista o cerere de reemitere efectuata astazi.",
		errorActionBeneficiary_CHOOSE: "Selectarea actiunii este obligatorie.",
		errorActionBeneficiary_NOADDRESS:
			"Selectarea adresei de livrare este obligatorie.",
		errorActionBeneficiary_WRONG_VALUE: "Valorile introduse nu sunt corecte.",
		errorActionBeneficiary_PROFORM:
			"A aparut o eroare la descarcarea proformei. Te rugam sa incerci mai tarziu accesand sectiunea Comenzi a platformei.",
		errorActionBeneficiary_500:
			" Serverul nu raspunde. <br /> Te rugam sa astepti cateva minute si sa incerci din nou.",
		errorActionBeneficiary_ERROR:
			" La finalizarea actiunii au aparut urmatoarele erori: ",
		errorActionBeneficiary_NO_FILE:
			" Este necesara incarcarea unui fisier de comanda valid. ",
		errorActionBeneficiary_NO_DATA:
			" Este necesara completarea tuturor campurilor. ",
		errorActionBeneficiary_NO_DATA_NAME:
			" Este necesara completarea campurilor nume sau prenume. ",
		errorActionBeneficiary_NO_DATA_VOUCHERS:
			" Este necesara completarea campului numar tichete. ",
		errorActionBeneficiary_NO_DATA_BOOKLETS:
			" Este necesara completarea campului numar carnete. ",
		errorActionBeneficiary_CNP_INVALID: " CNP necompletat sau invalid ",
		errorActionBeneficiary_VIRTUAL_INVALID: " Tip card necompletat",
		errorActionBeneficiary_MOBILE_INVALID:
			" Numar de telefon necompletat sau invalid ",
		errorActionBeneficiary_EMAIL_INVALID: " Email necompletat sau invalid ",
		errorActionBeneficiary_NO_MONTH:
			" Este necesara alegerea lunii pentru care se doreste alimentarea. ",
		errorActionBeneficiary_NO_DESTINATION:
			" Este necesara alegerea destinatiei pentru tichetele cadou. ",
		errorActionBeneficiary_MORE_VOUCHERS_CARNETE:
			"Numarul maxim de tichete/carnet trebuie sa fie mai mic de 45.",
		errorActionBeneficiary_MORE_VOUCHERS_PACHETE:
			"Numarul maxim de tichete trebuie sa fie mai mic de 99.",
		errorActionBeneficiary_NO_NAME:
			"Nu este selectat campul 'Nume + Prenume' sau campurile 'Nume' si 'Prenume'",
		errorActionBeneficiary_NO_VOUCHERS_CUSTOMER:
			"Beneficiarul de linia {index} nu are numarul de tichete completat",
		errorActionBeneficiary_NOTACCEPTABLE:
			"Te informam ca platforma este momentan in mentenanta. Ne cerem scuze pentru orice inconvenient si te asiguram ca lucram cu prioritate pentru a reveni online cat mai curand posibil. <br />Echipa Up Romania",
		reissuePINBeneficiaryTitle:
			"Acest beneficiar a solicitat {count} reemiteri de cod PIN",
		reissuePINBeneficiaryMessage:
			"Vezi mai jos in sectiunea de carduri cererile de reemitere ale acestui beneficiar pentru a le aproba.",
		reissueCardBeneficiaryTitle:
			"Acest beneficiar a solicitat {count} reemiteri de card",
		suspendedBeneficiaryTitle: "Acest beneficiar este inactiv.",
		suspendedBeneficiaryMessage: "Doresti reactivarea acestuia?",
		reactivateBeneficiaryButtonLabel: "ACTIVEAZA",

		"CARD.ERROR.EMPTY.LIST":
			"Comanda trimisa nu contine nici un beneficiar cu numar de tichete mai mare ca 0.",
		"CARD.ERROR.CUSTOMER":
			"Codul de client sau codul fiscal din fisierul excel este diferit de codul de client pentru care se face comanda.",
		"EXCEL.OLD":
			"Formatul fisierului nu este valid. Te rugam sa descarci noul model de fisier de comanda.",
		"EXCEL.INVALID.FORMULA":
			"Fisierul contine formule invalide. Te rugam sa corectezi informatiile si sa reincarci fisierul.",
		"EXCEL.TOO.LARGE":
			"Fisierul incarcat este prea mare. In cazul in care sunt linii necompletate la finalul fisierul te rugam sa le stergi si sa incarci noul fisier.",
		"EXCEL.TOO.OLD":
			"Fisierul incarcat este salvat intr-o versiune de Excel mai veche decat cea suportata de aplicatie. Te rugam sa resalvezi fisierul intr-o versiune mai noua si sa incarci noul fisier.",
		"CARD.ERROR.MANDATORY":
			"Campul {field} pentru angajatul de pe linia {name} {info} este obligatoriu.",
		"CARD.ERROR.ALREADY": "Cardul de pe linia {name} {info} a fost deja emis.",
		"CARD.ERROR.ALREADY.DELETED":
			"Angajatul de pe linia {name} {info} a fost sters, dar are card activ. Daca doresti reactivarea te rugam sa contactezi UpRomania.",
		"CARD.ERROR.LENGTH":
			"Campul {field} pentru angajatul de pe linia {name} {info} are completate prea multe caractere.",
		"CARD.ERROR.DUPLICATE":
			"Campul {field} pentru angajatul de pe linia {name} {info} este duplicat.",
		"CARD.ERROR.INVALID":
			"Campul {field} pentru angajatul de pe linia {name} {info} are o valoare invalida.",
		"CARD.ERROR.LOAD.MONTH":
			"Nr. tichete pentru angajatul de pe linia {name} {info} este invalid.",
		"CARD.ERROR.LOAD.VALUE":
			"Campul {field} pentru angajatul de pe linia {name} {info} este obligatoriu.",
		"CARD.ERROR.LOAD.VALUE.INVALID":
			"Valoarea nominala {field} pentru angajatul de pe linia {name} {info} nu este valida pentru produsul selectat.",
		"CARD.ERROR.LOAD.VALUE.MIN":
			"Valoarea nominala pentru angajatul de pe linia {name} {info} nu poate fi mai mica decat {field} lei.",
		"CARD.ERROR.LOAD.VALUE.MAX":
			"Valoarea nominala pentru angajatul de pe linia {name} {info} nu poate fi mai mare decat {field} lei.",
		"CARD.ERROR.ADDRESS.NOT.FOUND":
			"Codul de adresa pentru angajatul de pe linia {name} {info} nu este valid",
		"CARD.ERROR.NOT.VALIDRENEW":
			"Cardul pentru {name} nu este eligibil pentru reinnoire.",
		"CARD.ERROR.NOT.CANCEL.RENEW":
			"Reinnoirea pentru cardul beneficiarului {name} a mai fost anulata.",
		"CARD.ERROR.NOT.IVR.MOBILE":
			"Beneficiarul de pe linia {name} {info} nu are numar de telefon valid introdus si de aceea nu se poate face comanda",
		"CARD.ERROR.NOT.EMAIL.DIGITAL":
			"Beneficiarul de pe linia {name} {info} nu are email valid introdus si de aceea nu se poate face comanda",
		"CARD.ERROR.NOT.VIRTUAL":
			"Cardul pentru {name} trebuie sa fie virtual.",
		"CARD.ERROR.NOT.PHYSICAL":
			"Cardul pentru {name} trebuie sa fie fizic.",
		"CARD.ERROR.NOT.VALIDSTATUS":
			"Statusul cardului pentru {name} nu este valid pentru actiunea selectata.",
		"CARD.ERROR.CARD.NOT.VALID.STATUS": "Statusul cardului pentru {name} nu este valid pentru alimentare.",
		"CARD.ERROR.CARD.EXPIRED": "Cardul pentru {name} este expirat si nu poate fi alimentat.",
		"CARD.ERROR.CARD.CANCELLED": "Cardul pentru {name} este anulat si nu poate fi alimentat.",
		"CARD.ERROR.CARD.NOT.ACTIVE": "Cardul pentru {name} trebuie sa fie activ pt actiunea selectata.",
		"CARD.ERROR.CARD.DEACTIVATED": "Cardul pentru {name} este dezactivat si nu poate fi alimentat.",
		"CARD.ERROR.CARD.NOTPRODUCED": "Cardul de pe linia {name} {info} nu este in productie si nu poate fi alimentat.",
		"CARD.ERROR.NOT.VALID.REISSUE":
			"Cardul pentru {name} nu este valid pentru reemitere. Va rugam sa trimiteti o solicitare pentru dezactivarea contului initial al salariatului, pe adresa info@upromania.ro si ulterior puteti lansa comanda de card nou.",
		"CARD.ERROR.NOT.FOUND": "Nu exista card pentru CNP-ul de pe linia {name} {info}.",
		"CARD.ERROR.NOT.ACTIVE": "Cardul de pe linia {name} {info} nu este inca activ.",
		"CARD.ERROR.NOT.VALIDTODAY":
			"Statusul cardului pentru {name} nu este valid pentru actiunea selectata.",
		"CARD.ERROR.RENEW.INVALID":
			"Cardul de pe linia {name} {info} nu expira luna urmatoare. ",
		"CARD.ERROR.RENEW.INVALID.CURRENT.MONTH":
			"Cardul de pe linia {name} {info} nu expira luna aceasta. ",
		"CARD.ERROR.RENEW.NOTFOUND":
			"Nu exista card pentru CNP-ul de pe linia {name} {info}.",
		"CARD.ERROR.ALREADY.REPL":
			"Mai exista o cerere efectuata astazi pentru {name}.",
		"CARD.ERROR.NEW.CONTRACT.MIX.ORDER":
			"Comanda de card cu alimentare nu poate fi efectuata in ziua in care se semneaza contractul. Puteti realiza doar comanda de card nou fara alimentare.",
		"SERVER.COMMUNICATION.ERROR": "Eroare interna comunicare servicii.",
    modifiyDataSucceess: "Datele beneficiarului au fost modificate cu succes",
		deleteDataSucceess: "Stergerea beneficiarului a fost reaizata cu succes",

		// delivery points

		deliveryPointsDescription: "Vizualizarea adreselor de livrare",
		productTypePaper: "Tichete pe suport hartie",
		productTypeCard: "Tichete pe suport electronic",
		deliveryPointAddressTitle: "Adresa",
		deliveryPointAddressStreet: "Strada",
		deliveryPointAddressCity: "Localitate",
		deliveryPointAddressDistrict: "Judet",
		deliveryPointAddressPostalCode: "Cod postal",
		deliveryPointAddressName: "Denumire adresa",
		deliveryPointContactTitle: "Persoana Contact",
		deliveryPointContactOrderTitle: "Persoana responsabila comenzi",
		deliveryPointContactSurname: "Prenume",
		deliveryPointContactName: "Nume",
		deliveryPointContactEmail: "Email",
		deliveryPointContactMobile: "Numar telefon",
		deliveryPointNoBeneficiaries: "Numar beneficiari",
		deliveryNoHasAddress:
			"Nu exista adrese de livrare pentru produsul selectat!",
		deliveryPointConfirmData: "Aplica modificarile pentru toate produsele Up",
		deliveryPointAllProducts:
			"Aplica modificarile pentru toate produsele Up pentru acest cod adresa livrare.",
		errorSentAddressPoint_TOO_LENGTH_COMBO:
			"Campurile nume, prenume si telefon impreuna nu pot contine mai mult de 50 de caractere.",
		errorSentAddressPoint_NO_LENGTH_ADDRESS:
			"Adresa este necompletata sau numarul de caractere este prea mare",
		errorSentAddressPoint_NO_LENGTH_ADDRESS1:
			"Adresa1 este necompletata sau numarul de caractere este prea mare",
		errorSentAddressPoint_NO_LENGTH_ADDRESS2:
			"Adresa2 este necompletata sau numarul de caractere este prea mare",
		errorSentAddressPoint_NO_LENGTH_COUNTY:
			"Este necesara completarea campului judet.",
		errorSentAddressPoint_NO_LENGTH_LOCALITY:
			"Este necesara completarea campului oras.",
		errorSentAddressPoint_NO_LENGTH_FIRSTNAME:
			"Este necesara completarea campului prenume.",
		errorSentAddressPoint_NO_LENGTH_LASTNAME:
			"Este necesara completarea campului nume.",
		errorSentAddressPoint_NO_LENGTH_FUNCTION:
			"Este necesara completarea campului functie.",
		errorSentAddressPoint_NO_LENGTH_EMAIL:
			"Emailul este necompletat sau numarul de caractere este prea mare",
		errorSentAddressPoint_NO_LENGTH_MOBILE:
			"Numarul de telefon este necompletat sau numarul de caractere este prea mare",
		errorSentAddressPoint_NOT_SENT:
			"A aparut o problema in sistem, va rugam sa incercati mai tarziu!",
		sentAddressPointSuccess: "Datele au fost modificate cu succes.",
		warningCardAddressPoint:
			"*Daca se modifica datele pe un produs electronic, acestea se vor propaga pe toate produsele!",
		addDeliveryPointText: "Adauga adrese de livrare",

		// orders
		pendingOrdersMessage: "{noOrders} comenzi in asteptare",
		pendingOrdersTitle: "Comenzi in asteptare",
		pendingOrdersNoOrders: "Nu exista comenzi in asteptare",
		ordersHistoryTitle: "Istoric comenzi",
		cancelOrderButtonLabel: "Anuleaza",
		urgentOrderButtonLabel: "Urgenteaza",
		trackOrderButtonLabel: "AWB",
		showDateDeliveryOrderButtonLabel: "Afiseaza data",
		courierName: "Nume curier",
		statusAWBUnavailable: "Indisponibil",
		reportTopUpButtonLabelPaper: "Foi cu serii alimentare (.pdf)",
		reportTopUpButtonLabelXls: "Foi cu serii alimentare(.xls)",
		reportCardsButtonLabelPaper: "Foi cu serii card",
		reportButtonLabelPaper: "Foi cu serii (.pdf)",
		reportButtonLabelXls: "Foi cu serii (.xls)",
		reportButtonLabelCsv: "Foi cu serii (.csv)",
		reportButtonLabelCard: "Proces verbal",
		invoiceButtonLabel: "Factura",
		proformaButtonLabel: "Proforma",
		orderError_DELETE:
			"A fost intampinata o eroare. Comanda nu a putut fi anulata.",
		orderError_URGENT:
			"A fost intampinata o eroare. Comanda nu a putut fi urgentata.",
		orderError_URGENT_HOUR:
			"Urgentarea se poate face doar in intervalul 11:00 - 14:30.",
		orderError_500: "A fost intampinata o eroare! Comenzile nu pot fi afisate!",
		orderDeleteSuccess: "Comanda a fost anulata cu succes.",
		orderUrgentSuccess: "Comanda a fost urgentata cu succes.",
		confirmOrderDelete: "Esti sigur ca vrei sa anulezi comanda selectata?",
		confirmOrderUrgent: "Esti sigur ca vrei sa urgentezi comanda selectata?",
		deliveryAddress: "Adresa de livrare",
		invoiceAddress: "Adresa de facturare",
		product: "Produs",
		ordersTableHeaderDate: "Data",
		ordersTableHeaderNumber: "Numar",
		ordersTableHeaderProduct: "Produs",
		ordersTableHeaderType: "Tip",
		ordersTableHeaderAmount: "Valoare",
		ordersTableHeaderStatus: "Status",
		ordersTableHeaderAddress: "Adresa de livrare",
		ordersTableHeaderPlatform: "Sursa",
		ordersTableHeaderInvoice: "Factura",
		ordersTableHeaderInvoiceOrProform: "Factura / <br /> Proforma",
		ordersTableHeaderInvoiceOrProformNumber: "Numar factura / <br /> proforma",
		ordersTableHeaderProform: "Proforma",
		ordersTableHeaderDeliveryDate: "Estimare livrare",
		orderStatus_A: "Anulata",
		orderStatus_L: "Platita integral",
		orderStatus_N: "Noua",
		orderStatus_P: "Confirmata",
		orderStatus_PR: "In productie",
		orderStatus_PRP: "In productie",
		orderStatus_PV: "In productie",
		orderStatus_R: "Platita",
		orderStatus_S: "Returnare",
		orderStatus_V: "Finalizata",
		orderStatus_E: "Expediat",
		orderStatus_Z: "Anulata",
		orderStatus_X: "Eroare",
		orderSingleDeliveryAddress: "O adresa de livrare",
		orderMultipleDeliveryAddress: "Multiple adrese de livrare",

		//	Order Details
		orderDetailsTitle: "Comanda {orderId} ",
		orderDescription: "Plasata pe ",
		orderDetailsAddressLabel: "Adresa de facturare: ",
		orderDetailsProductLabel: "Produs: ",
		orderDetailsNoTicketsLabel: "Numar tichete comandate: ",
		orderDetailsNoCardsLabel: "Numar total de carduri",
		orderDetailsNominalValueLabel: "Valoare nominala totala: ",
		orderDetailsTotalValueLabel: "Valoare totala: ",
		orderDetailsMessage1: "Mesaj 1: ",
		orderDetailsMessage2: "Mesaj 2: ",
		proformNumber: "Numar proforma",
		numberTotalTickets: "Nr. total tichete ",
		numberTotalCards: "Nr. total carduri ",
		nominalValue: "Val. nominala tichete",
		groupTickets: "Grupare tichete",
		messageTicket: "Mesaj tichet",
		topUpDateLabel: "Data alimentare",
		totalPayment: "Total de plata",
		noCrtLabel: "Nr. crt.",
		orderTableHeaderLabel: "Comanda nr.",
		noTicketsValue1Label1: "Numar tichete",
		amountVoucher1Label1: "Valoare",
		noAWB: "Nu exista nici un awb pentru aceasta comanda.",
		messageNotCharged: "Nealimentat ",

		// comanda noua

		newOrderTitle: "Comanda noua",
		orderStep_1: "Tipul de comanda",
		orderStep_2: "Produsul",
		orderStep_3: "Personalizeaza",
		orderStep_4: "Confirma",
		orderStep_5: "Comanda finalizata",
		orderStep_1_title: "Ce tip de comanda doresti sa plasezi? ",
		orderStep_2_title: "Alege produsul Up pe care vrei sa il comanzi ",
		orderStep_2_title_duplicate:
			"Alege comanda pe care doresti sa o retrimit din istoric",
		orderStep_3_title: "Configureaza comanda ",
		orderStep_4_title: "Sumar comanda ",
		orderStep_5_title: "Comanda a fost plasata cu succes ",
		orderTypePaper: "Comanda tichete hartie",
		orderTypeCard: "Comanda card",
		orderTypeTopup: "Alimentare card ",
		orderTypeDuplicate: "Duplica o comanda anterioara ",
		orderTypeRenewalCard: "Reinnoire card",
		orderAddBeneficiariesTitle: "Adauga beneficiari",
		orderProductDisabledButton: "Afla mai multe",
		orderChooseOrderDuplicate: "Selecteaza",
		orderExcelTemplateTitle:
			"Personalizeaza structura tabelului excel incarcat",
		orderExcelColumnLabel: "Col. {index}:",
		orderExcelColumn_0: "Selecteaza...",
		orderExcelColumn_1: "Nume + Prenume",
		orderExcelColumn_2: "Nume",
		orderExcelColumn_3: "Prenume",
		orderExcelColumn_4: "CNP",
		orderExcelColumn_5: "Numar identificare",
		orderExcelColumn_6: "Numar tichete",
		orderExcelColumn_7: "Valoare tichet",
		orderExcelColumn_8: "Cod adresa livrare",
		orderExcelColumn_9: "Criteriu ambalare departament",
		orderExcelColumn_10: "Criteriu ambalare subdepartament",
		orderExcelColumn_11: "Valoare totala",
		orderUploadFileTitle: "Incarca tabelul de comanda personalizat",
		orderFileModelLabel: "Model:",
		orderFileModelText: "Descarca fisierul de comanda personalizat",
		orderFileUploadText: "Completeaza fisierul de comanda si incarca-l",
		orderUploadFile: "Incarca fisierul",
		orderAddFileButtonLabel: "Adauga",
		orderAddNewRowButtonLabel: "ADAUGA",
		orderNumberCarnetsPlaceholder: "Numar carnete",
		orderNumberTicketsPlaceholder: "Numar tichete / carnet",
		nextStepButtonLabel: "CONTINUA",
		finalStepButtonLabel: "FINALIZEAZA COMANDA",
		orderDeliveryTitle: "Optiuni livrare",
		orderDeliveryCourier: "Livrare prin curier",
		orderDeliveryPickup:
			"Ridicare de la sediul Up Romania - Calea Serban Voda, nr. 206, Building A, Cladirea U-Center, Bucuresti, sector 4, 040215",
		orderDeliverySaturday: "Livrare si sambata",
		actionUploadFileSuccess:
			"Fisierul a fost incarcat cu succes. Te rugam sa accesezi butonul CONTINUA pentru a verifica datele introduse si a finaliza comanda.",
		orderMessageForSuccess:
			"Te rugam sa verifici datele introduse si sa accesezi butonul din josul paginii pentru a finaliza comanda.",
		orderSuccessMessage:
			"Multumim pentru comanda. <br /> Poti urmari statusul comenzii in sectiunea Comenzi.",
		orderSuccessMessageREN:
			"Cardurile care urmeaza a fi reinnoite au fost actualizate cu succes. Reinnoirea acestor carduri se va face automat la inceputul lunii urmatoare. Poti vedea cardurile care vor fi reinnoite in sectiunea beneficiari, alegand Status card Expira in curand",
		ordersButtonLabel: "Istoric comezi",
		orderMonthLabel: "Luna alimentare *",
		orderMonth_0: "Selecteaza...",
		orderMonth_1: "Ianuarie",
		orderMonth_2: "Februarie",
		orderMonth_3: "Martie",
		orderMonth_4: "Aprilie",
		orderMonth_5: "Mai",
		orderMonth_6: "Iunie",
		orderMonth_7: "Iulie",
		orderMonth_8: "August",
		orderMonth_9: "Septembrie",
		orderMonth_10: "Octombrie",
		orderMonth_11: "Noiembrie",
		orderMonth_12: "Decembrie",
		orderValidFromLabel: "Alimentare la data de",
		orderValidToLabel: "Valabil pana la",
		orderValidFromText:
			"* Selecteaza ziua si luna in care doresti sa fie alimentate cardurile, in situatia in care doresti ca alimentarea sa fie facuta la o data diferita fata de cea predefinita.",
		orderValidFromPopupText:
			"Te informam ca alimentarea se va face la data setata de tine, cu conditia ca pana la acea data sa avem suma aferenta alimentarii incasata/transferata.",
		orderIgnoreMaxTicketsLabel: "NU se valideaza nr maxim tichete",
		orderIgnoreCnpValidLabel: "NU se valideaza CNP",
		renewForCurrentLabel: "Cardurile expira in luna curenta",
		orderFileBeneficiariesLabel:
			"Importa lista cu angajatii tai care au deja card Up Dejun",
		orderFileBeneficiariesRenLabel:
			"Completeaza adresa de livrare a cardului",
		orderFileBeneficiariesButtonLabel:
			"Importa lista cu angajatii tai care au deja card Up Dejun",
		orderBeneficiariesButtonLabel:
			"Comanda card {product} pentru beneficiarii care au card Up Dejun",
		orderPersonalizedLabel: "Tichete personalizate",
		orderNotPersonalizedLabel: "Tichete nepersonalizate",
		orderPersonalizedCardLabel: "Carduri personalizate",
		orderNotPersonalizedCardLabel: "Carduri nepersonalizate",
		orderChooseTypeCard: "Alege tipul de card",
		orderTypesCard: "Tipuri carduri",
		orderPersonalizeVouchersTitle: "Personalizeaza tichetele",
		orderPersonalizedIconLabel: "Logo",
		orderPersonalizedViewIconLabel: "Vezi logo",
		orderPersonalizedPrintIconLabel: "Printeaza pe tichet",
		orderPersonalizedMessageLabel: "Mesaj",
		orderPersonalizedGroupingLabel: "Grupare tichete",
		orderPersonalizedGroupingPlanse: "Planse",
		orderPersonalizedGroupingPachet: "Pachet",
		orderPersonalizedGroupingCarnet: "Carnet",
		noOrdersMessage: "Nu exista comenzi pentru perioada de timp selectata.",
		orderNumberCrt: "Nr. crt.",
		orderDestination: "Destinatie",
		orderChooseDestination: "Alege destinatia",
		orderChooseTicketType: "Alege tipul de tichete",
		beneficiariesTableMobile: "Telefon",
		beneficiariesTableEmail: "Email",
		beneficiariesTableDepartment1: "Departament",
		beneficiariesTableDepartment2: "Sectie",
		hasAnotherOrder: "Mai aveti {count} {orderType} pentru produsul {product}!",
		proformToMail: "In scurt timp veti primi factura fiscala semnata pe mail.",
		confirmCardOrderText:
			"Esti sigur ca doresti sa dai comanda de carduri nepersonalizate?",
		removeVouchersText1:
			"Stimate partener,<br><br>Te informam ca, incepand cu data de 01.02.2022, biletele de valoare vor fi emise, conform legii, exclusiv pe card. Ai mai multe avantaje decat in cazul tichetelor pe suport de hartie. Avem pentru tine o oferta SPECIALA !!!<br>Beneficiaza de oferta de 0 (zero) lei cost la emiterea de carduri, indiferent de numarul de angajati !",
		removeVouchersText2:
			"<br><br>Ofera angajatilor tai cardurile Up Romania si ramai un angajator de top!<br>Echipa Up Romania ",
		removeVouchersIfHasCardCustomerText:
			"Stimate partener,<br><br>Te informam ca, incepand cu data de 01.02.2022, biletele de valoare vor fi emise, conform legii, exclusiv pe card. Ai aceleasi avantaje ca si in cazul tichetelor pe suport de hartie.<br><br>Ofera angajatilor tai cardurile Up Romania si ramai un angajator de top! <br><br>Echipa Up Romania ",
		removeVouchersButtonText:
			"APASA AICI SI UN CONSULTANT DEDICAT Up Romania te va contacta",

		// beneficiary details from client

		comercialTitle: "Gestionar:",
		defaultAmountValue: "Valoare implicita ",
		manualOrderTabTitle: "Online",
		commentsOrderTabTitle: "Comentarii",
		excelOrderTabTitle: "Excel",
		orderHasNotProduct:
			"Pentru a putea oferi produsul angajatilor tai, intra in legatura cu consultantul tau dedicat.",
		orderIsMixOrder:
			"Comanda contine beneficiari pentru care trebuie emis card. Te rugam sa verifici adresele de livrare in ecranul urmator. La confirmarea comenzii se va plasa automat si comanda de card pentru acesti beneficiari.",

		beneficiaryVoucherLabel: "Nume angajat sau test pe carnet",
		noOfBookletLabel: "Nr. carnete",
		employeeIdCardLabel: "CNP",
		logo: "Sigla",

		noDocumentMessage: "Documentul nu este disponibil momentan.",

		// search company
		searchPageName: "Cauta companie",
		searchByCodall: "Codall/Cod client",
		searchByProduct: "Produs",
		searchByCompanyName: "Nume companie",
		searchByFiscalCode: "Cod fiscal",
		searchByProformNumber: "Nr. Proforma",
		searchButtonLabel: "Cauta",
		searchNoCompanies: "Nu exista nici o companie.",

		// cookies
		cookieTab1: "Cookies",
		cookieTab2: "Date personale",
		cookieTab3: "Optiuni marketing",
		cookiesGeneralInfo: "Informatii generale",
		cookiesGeneralInfoDescription:
			"<br />Cookie-urile sunt fisiere text pe care browser-ul tau le stocheaza cand vizitezi site-ul, pentru ca acesta sa tina minte preferintele tale si anumite informatii(cum ar fi limba afisata si optiunile setate in site), astfel incat sa-ti ofere o experienta de navigare personalizata si optimizata din punct de vedere afisare si continut. Cookie-urile contin informatii despre IP-ul tau, despre tipul de browser, despre device, despre anumite preferinte de navigare setate de tine pe site, chiar daca nu esti logat. Cookie-urile nu te pot identifica in mod direct, insa IP-ul tau poate fi asociat cu o tara, o localitate si un furnizor de acces la Internet. Cookie-urile pot fi stocate in browser de catre site-ul vizitat sau de catre servicii partenere de analiza de trafic sau de publicitate. <br /> Pentru ca respectam dreptul tau la confidentialitate, poti alege activarea sau dezactivarea anumitor tipuri de cookie-uri, preferinte pe care le poti exprima in continuare.",
		cookiesNecessary: "Cookie-uri necesare",
		cookiesMandatory: "Obligatorii",
		cookiesNecessaryDescription:
			"<br /> Cookie-urile necesare sunt esentiale pentru functionarea site-ului si nu pot fi dezactivate. Acestea sunt cookie-uri care stocheaza informatii legate de preferintele pe care le declare in site: acceptarea politicilor, inclusive cea de cookie-uri, preferinta de limba, informatii si preferinte de login. Aceste cookie-uri nu contin date personale prin care poti fi identificat in mod direct. <br /> <br />Poti bloca aceste cookie-uri din setarile browser-ului tau, dar atunci unele parti ale site-ului nu vor functiona corect sau nu vor functiona deloc.<br /> <br />Cookie-urile utilizate de site: <br /> <br />",
		cookiesNecessaryNecessary:
			"necessary - necesar pentru afisarea continutului si pastrarea preferintelor tale",
		cookiesNecessarySession:
			"session - cookie temporar, necesar pentru validarea sesiunii tale",
		cookiesFunctional: "Cookie-uri functionale",
		cookiesAllow: "Permite",
		cookiesDontAllow: "Oprite",
		cookiesFunctionalDescription:
			"<br />Acestea sunt cookie-uri care ne permit sa iti afisam continut personalizat in site, in functie de activitatea ta anterioara pe site. Unele dintre aceste cookie-uri pot fi parte ale unor module create de alte companii din domeniul informatic si folisite de noi in dezvoltarea si gestionarea site-ului. <br /> <br />Cookie-urile utilizate de site: <br /> <br />",
		cookiesFunctionalDescriptionLink:
			" Acest tip de cookie nu este utilizat de site la acest moment. Daca acest lucru se va schimba, vei fi anuntat de necesitatea reinnoirii preferintelor tale si a reconfirmarii acordului cu politica de cookie-uti",
		cookiesAnalisys: "Cookie-uri de analiza de trafic",
		cookiesAnalisysDescription:
			"<br />Cookie-urile de analiza de trafic stocheaza informatii de ordin statistic, anonim, despre data si ora fiecarei vizite pe site, durata vizitei, paginile accesate si actiunile in fiecare pagina. Unele cookie-uri pot inregistra miscarea cursorului pe ecran, pentru a determina care au fost zonele de interes urmarite de vizitatorii site-ului.<br /><br />Aceste cookie-uri sunt instalate de module ale unor parti terte, utilizate in dezvoltarea site-ului, si procesate de acestea in mod anonimizat, pentru a ne furniza rapoarte centralizate despre traficul pe site.<br /><br />Cookie-urile utilizate de site:<br /><br />",
		cookiesAnalisysDescriptionLink: "Google Analytics",
		cookiesMarketing: "Cookie-uri de marketing",
		cookiesMarketingDescription:
			"<br />Cookie-urile de marketing sunt instalate de servicii terte, ale unor companii cu care noi derulam activitati de marketing si publicitate. Aceste cookie-uri sunt folosite de aceste companii de publicitate pentru a crea profiluri de marketing si de a-ti afisa reclame pe alte site-uri pe care le vizitezi. Informatiile prelucrate prin aceste cookie-uri nu sunt date care te pot identifica direct, insa acestea fac identificarea unica a browser-ului tau. Acest proces ne ajuta pe noi sa nu cheltuim pe reclame afisate unui public neinteresat de produsele noastre, iar pe tine te ajuta sa primesti mai putine reclame irelevante, si mai multe informatii despre produse si servicii care te-ar putea interesa in mod real. Prin acest proces NU se face targetare pentru marketing direct. Nu vei primi informatii prin e-mail, SMS sau alt canal direct de comunicare directa in urma acceptarii acestui tip de cookie. <br /> <br /> Cookie-urile utilizate de site:<br /> <br />",
		cookiesMarketingDescriptionLink:
			"Acest tip de cookie nu este utilizat de site la acest moment. Daca acest lucru se va schimba, vei fi anuntat de necesitatea reinnoirii preferintelor tale si a reconfirmarii acordului cu politica de cookie-uti",
		gdprP1:
			"Daca ai completat un formular pe site cu datele tale personale pe acest site, trebuie sa stii ca in perioada in care detinem si prelucram datele tale, ai dreptul de a ne solicita in orice moment accesul la date, rectificarea sau stergerea acestora, sau restrictionarea prelucrarii. Ai de asemenea dreptul de a te opune prelucrarii si dreptul de a solicita portarea datelor catre alt operator. Poti oricand sa retragi consimtamantul de prelucrare. Trebuie sa mai stii ca pentru orice suspiciune de nereguli privind prelucrarea datelor dvs, puteti depune o plangere la Autoritatea Nationala de Supraveghere a Prelucrarii Datelor cu Caracter Personal",
		gdprP2:
			"Iti reamintim aici toate conditiile de prelucrare de date personale pe care le colectam prin intermediul acestui site:",
		gdprTitle:
			"Informare privind prelucrarea datelor personale colectate prin acest formular",
		gdprP3:
			"<b>Identitatea operatorului de date</b> <br />Datele completate in acest formular sunt prelucrate de compania Up Romania, cu sediul in Calea Serban Voda, nr. 206, Building A, Cladirea U-Center, Bucuresti, sector 4, 040215. Telefon: 0 800 802 802 (apel gratuit), e-mail: info@upromania.ro",
		gdprP4:
			"<b>Scopul prelucrarii</b> <br />Datele colectate in formularele acestui site sunt prelucrate in scopul gestionarii solicitarilor de propuneri de afaceri si pentru raspunsurile la intrebari, sugestii sau sesizari legate de activitatea comerciala a Operatorului. <br />Datele colectate in formularul de abonare la newsletter sunt prelucrate in scop de marketing direct, pentru informarea periodica in legatura cu noutatile din domeniul de activitate al operatorului.",
		gdprP5:
			"<b>Temeiul juridic</b> <br />Prelucrarea datelor colectate prin intermediul site-ului, si la adresa de e-mail mentionata, se deruleaza sub temeiul juridic reglementat de Regulamentul General pentru Protectia Datelor (GDPR) la Art. 6, lit. b – executarea unui contract sau demersuri in vederea incheierii unui contract.Datele colectate in formularul de abonare la newsletter sunt prelucrate in temeiul legal reglementat de GDPR la Ar. 6, lit a – consimtamantul persoanei vizate.",
		gdprP6:
			"<b>Datele prelucrate</b> <br />Categoriile de date pe care le prelucram sunt datele tale de identificare si contact (nume, prenume, data nasterii, telefon, e-mail, compania angajatoare, domeniul de activitate al companiei angajatoare), precum si datele colectate automat de website (IP si detalii tehnice ale sistemului de pe care vizitezi site-ul - detalii in Politica de Cookies).",
		gdprP7:
			"<b>Perioada prelucrarii</b> <br />Datele completate in formularele acestui site vor fi prelucrate in scopul declarat si stocate pentru un termen de maxim 6 luni de la data completarii. Dupa expirarea acestei perioade, datele tale vor fi sterse din aceasta baza de date. Datele colectate in formularul de abonare la newsletter vor fi prelucrate pe o perioada de maxim 3 ani. Daca acordul de prelucrare nu va fi prelungit de persoana vizata in acest interval, datele vor fi sterse.",
		gdprP8:
			"<b>Modul de prelucrare</b> <br />Datele sunt accesate de angajatii Up Romania responsabili pentru gestionarea fiecarui tip de solicitare si sunt utilizate pentru comunicarea raspunsurilor la solicitarile facute. Datele colectate in formularul de abonare la newsletter sunt stocate si extrase periodic pentru trimiterea de newslettere catre destinatarii abonati.",
		gdprP9:
			"<b>Confidentialitate</b> <br />Datele tale nu vor fi utilizate in niciun alt scop, nu vor fi dezvaluite catre terti si nu vor fi transferate in strainatate.",
		gdprP10:
			"<b>Drepturile tale</b> <br />In perioada in care detinem si prelucram datele tale, ai dreptul de a ne solicita in orice moment accesul la date, rectificarea sau stergerea acestora, sau restrictionarea prelucrarii. Ai de asemenea dreptul de a te opune prelucrarii si dreptul de a solicita portarea datelor catre alt operator. Poti oricand sa retragi consimtamantul de prelucrare. Trebuie sa mai stii ca pentru orice suspiciune de nereguli privind prelucrarea datelor dvs, puteti depune o plangere la Autoritatea Nationala de Supraveghere a Prelucrarii Datelor cu Caracter Personal (www.dataprotection.ro)",
		gdprP11:
			"<b>Modalitati de exercitare a drepturilor</b> <br />Pentru orice solicitare legata de datele tale personale, in procesul de exercitare a drepturilor mentionate in capitolul anterior, poti contacta Operatorul printr-un e-mail trimis la adresa: info@upromania.ro",
		gdprP12:
			"Pentru orice solicitare legata de datele tale personale in vederea exercitarii drepturilor tale legale, te rugam ne trimiti un e-mail la adresa:",
		gdprP13: "Te rugam sa mentionezi in solicitarea trimisa:",
		gdprLi1: "Numele",
		gdprLi2: "Prenumele",
		gdprLi3:
			"Adresa de e-mail si/sau numarul de telefon mobil care fac obiectul solicitarii (daca este cazul)",
		gdprLi4:
			"Tipul solicitarii si cat mai multe detalii legate de contextul prelucrarii datelor la care se refera solicitarea, pentru a putea identifica in sistemul nostru istoricul prelucrarilor si solutia corecta la solicitarea adresata",
		gdprLi5:
			"Adresa de e-mail si/sau numarul de telefon mobil la care te putem contacta pentru intrebari sau informari legate de solicitarea adresata.",
		gdprP14:
			"Acest site nu foloseste un sistem de conturi web. Pentru orice solicitare legate de datele personale, inclusive retragerea consimtamantului de prelucrare, dezabonare sau oric alt drept al tau legat de datele personale, te rugam sa ne trimiti un e-mail la",

		// contact
		feedbackFormTitle: "Trimite-ne un feedback",
		contactPerson: "Persoana de contact",
		commercialPerson: "Gestionar",
		workingHours: "Programul de lucru: Luni - Vineri, 8:30 - 17:30",
		commerceRegisterID: "Numar inregistrare Registrul Comertului",
		vat: "CUI",
		registeredAddressTitle: "Adresa sediului social",
		registeredAddressText1:
			"Calea Serban Voda, nr. 206, Building A, Cladirea U-Center, Bucuresti, sector 4",
		registeredAddressText2: "cod postal 040215",
		mailingAddressTitle: "Adresa de corespondenta",
		mailingAddressText1:
			"Calea Serban Voda nr. 206, cladirea U-Center, Building A, etaj 8, sector 4, Bucuresti.",
		mailingAddressText2: "cod postal 042015",

		// oauth
		clientText: "{client} doreste sa acceseze contul pentru a te putea loga",
		selectCardText:
			"Selecteaza cardul Up pe care o sa il folosesti pentru a efectua plati.",
		selectCardTitleText: "Selecteaza cardul",
		permissionsTitleText: "Permite {client} sa acceseze contul tau Up pentru:",
		permissionsText1:
			"Initierea de tranzactii si verificare soldului disponibil",
		permissionsText2: "Accesarea adresei de e-mail asociate contului tau Up",
		permissionsText3: "A ramane conectat in platforma",
		permissionsText4: "Introduce codul OTP",
		successOAUTHText1: "Felicitari!",
		successOAUTHText2: "Contul a fost adaugat cu success.",

		// notificari clienti
		createNotificationTitle: "Creaza o notificare",
		createNotificationSubject: "Subiect",
		createNotificationBody: "Mesaj",
		createNotificationValidity: "Valabilitate",
		createNotificationTarget:
			"Alege angajatii carora doresti sa le trimiti mesajul",
		createNotificationTargetAllEmployees: "Toti angajatii",
		createNotificationTargetSelectEmployees: "Selecteaza angajatii",
		createNotificationSentButton: "Trimite mesaj",
		notificationListSend: "Lista notificari trimise",
		notificationListReceived: "Lista notificari primite",
		notificationLabelEmployee: "Angajati",

		// afiliati

		// menu
		tallySheetName: "Borderou",
		repaymentName: "Rambursari tichete",
		statisticsName: "Statistici",
		stickersName: "Autocolante",
		cardTransactionsName: "Tranzactii card",
		workingPoints: "Puncte de lucru",
		workingPointsDescription: "Vizualizarea punctelor de lucru",

		pos: "Administrare POS",
		additionalDocuments: "Acte aditionale",

		// dashboard
		increaseSaleText: "Creste-ti vanzarile",
		mediumCartText: "cosul mediu",
		monthlyVisitsText: "vizite lunare ale unui client",
		lastReimbursementsTitle: "Ultimele rambursari",
		TERMS:
			"Pentru a valida noul cont trebuie sa accepti termenii si conditiile.",
		resetPasswordHeader: "Schimbare parola initiala",
		resetPasswordSubheader:
			"Din motive de securitate a contului, te rugam sa modifici parola initiala cu una noua.",
		placeholderOldInitPassword: "Parola initiala (CUI)",

		// invoices
		payInvoiceButtonLabel: "Plateste acum",
		payInvoiceIntegralButtonLabel: "Plateste integral",
		balanceCLabelProduct: "Sold ({product})",
		balanceDLabelProduct: "Sold ({product})",
		balanceCLabelTotal: "Sold total",
		balanceDLabelTotal: "Sold total",
		pendingInvoicesPay: "Plateste urmatoarele facturi",
		noInvoiceForPay: "Nu sunt facturi selectate pentru plata.",
		redirectPaymentMessage:
			"Pentru a finaliza plata vei redirectat catre pagina de plati securizata a mobilpay.ro",
		errorRedirectPaymentMessage:
			"A aparut o eroare! Va rugam sa incercati mai tarziu.",
		paidStatus_CP: "Tranzactia este in verificare",
		paidStatus_PP: "Tranzactia este in verificare",
		paidStatus_P: "Tranzactia este in verificare",
		paidStatus_C: "Tranzactia este creditata",
		paidStatus_Z: "Tranzactia a fost anulata",
		paidStatus_V: "Tranzactia a fost realizata cu succes",
		paidStatus_R: "Tranzactia a fost respinsa",

		paidErrorMessage_16: "Cardul prezinta risc.",
		paidErrorMessage_17: "Numarul cardului este incorect.",
		paidErrorMessage_18: "Cardul este blocat.",
		paidErrorMessage_19: "Cardul este expirat.",
		paidErrorMessage_20: "Fonduri insuficiente.",
		paidErrorMessage_21: "Codul CVV2 este incorect.",
		paidErrorMessage_22: "Banca emitenta nu raspunde.",
		paidErrorMessage_32: "Suma de plata este incorecta.",
		paidErrorMessage_33:
			"Moneda in care se efectueaza tranzacti nu este corecta.",
		paidErrorMessage_34: "Tranzactia nu este permisa acestui card.",
		paidErrorMessage_35: "Tranzactia este respinsa.",
		paidErrorMessage_36: "Tranzactia a fost respinsa din motive antifrauda.",
		paidErrorMessage_37: "Tranzactia a fost respinsa din motive legale.",
		paidErrorMessage_38: "Tranzactia a fost respinsa.",
		paidErrorMessage_48: "Cerere invalida.",
		paidErrorMessage_49: "Tranzactia a fost preautorizata anterior.",
		paidErrorMessage_50: "Tranzactia a fost autorizata anterior.",
		paidErrorMessage_51: "Se poate anula doar o tranzactie preautorizata.",
		paidErrorMessage_52: "Se poate confirma doar o tranzactie preautorizata.",
		paidErrorMessage_53: "Se poate credita doar o tranzactie confirmata.",
		paidErrorMessage_54: "Suma creditata este mai mare decat suma autorizata.",
		paidErrorMessage_55: "Suma este mai mare decat suma autorizata.",
		paidErrorMessage_56: "Cerere de plata dublata.",
		paidErrorMessage_99: "Eroare.",
		ougErrorMessage_MIN_DATE: "Factura pentru care se declara sumele trebuie sa fie emisa dupa data de 30.07.2023.",
		ougErrorMessage_BAD_TEMPLATE: "Fisierul incarcat nu are o structura corecta. Va rugam descarcati modelul, completati-l si incarcati noul fisier.",
		ougErrorMessage_MAX_VN: "Suma declarata nu este acceptata. Pentru suport puteti contacta departamentul de Relatii afiliati",
		ougErrorMessage_MIN_VN: "Suma declarata trebuie sa fie mai mare ca 0",
		ougErrorMessage_MAX_DAYS: "Declararea sumelor aferente OUG67 se poate face intre zilele de 1 si 10 ale lunii in curs pentru facturile din luna precedenta ",
		ougErrorMessage_ALREADY: "Fisierul a fost incarcat si procesat pentru aceasta factura. Nu mai puteti incarca un nou fisier.",
		ougErrorMessage_CHECK: "Trebuie sa fiti de acord cu termenii pentru a incarca fisierul",
		ougErrorMessage_NOFILE: "Fisierul excel este obligatoriu",
		ougSuccessMessage: "Fisierul a fost incarcat cu succes",
		amountIsNotEnough:
			"Suma minima de plata este suma celei mai vechi facturi!",
		balancePaidLabel: "Sold creditor",
		amountCantBePaid: "Soldul nu poate fi achitat fara o factura selectata",
		addNewUserLabel: "Adauga un utilizator nou",
		invoiceAffiliateOugModel:
			"Descarca modelul de fisier pentru declararea sumelor aferente OUG 67/2023",
		invoiceAffiliateOugPopup: "OUG 67",
		invoiceAffiliatDownloadModel:
			"Descarca model",
		invoiceAffiliatCheckboxLabel: "Confirm corectitudinea datelor furnizate.",
		invoiceAffiliateOugTitle:
			"Te rugam sa incarci fisierul completat cu sumele aferente conform OUG 67/2023",

		// 0x00 - actiunea a fost acceptata 0x10 - cardul prezinta un risk (card furat de exemplu) 0x11 - numarul cardului este incorect 0x12 - card blocat 0x13 - cardul este expirat 0x14 - fonduri insuficiente 0x15 - numar CVV2 incorect 0x16 - banca emitenta nu a putut fi contactata 0x20 - suma este incorecta 0x21 - moneda este incorecta 0x22 - tranzactia nu este permisa (cardul nu poate fi folosit online) 0x23 - tranzactia a fost respinsa 0x24 - tranzactia a fost respinsa de filtrele antifrauda 0x25 - tranzactia a fost respinsa (incalcare a legii) 0x26 - tranzactia a fost respinsa 0x30 - cerere invalida 0x31 - nu se poate preautoriza decat o tranzactie noua 0x32 - nu se poate autoriza decat o tranzactie noua 0x33 - nu se poate anula decat o tranzactie preautorizata 0x34 - nu se poate postautoriza decat o tranzactie preautorizata 0x35 - nu se poate credita decat o tranzactie finalizata 0x36 - suma de creditare este mai mica decat suma tranzactiei autorizate sau postautorizate 0x37 - suma tranzactiei de postautorizare este mai mare decat suma preautorizata 0x38 - cerere duplicata 0x63 - eroare generala

		// card transactions
		cardTransactionTitle: "Tranzactii card",
		midLabel: "MID afiliat",
		nameStoreLabel: "Nume magazin ",
		localityLabel: "Localitate",
		dateLabel: "Data",
		typeLabel: "Tip",
		valueLabel: "Valoare",

		firstNameLabel: "Prenume",
		lastNameLabel: "Nume",
		usernameLabel: "Username",

		cardTransactionFilterProduct: "Produs",
		cardTransactionFilterSubsidiary: "Filiala",
		cardTransactionFilterPeriod: "Perioada",
		cardTransactionFilterFiscalCode: "Cod Fiscal",
		cardTransactionFilterAffiliateName: "Nume Partener",
		cardTransactionFilterTransactionType: "Transaction type",

		noTransactionMessage: "Nu exista tranzactii",
		errorTransactionMessage: "Nu s-a putut incarca lista de tranzactii",

		// repayment
		tallySheetTitleSection: "Borderou",
		tallySheetPaperButton: "Borderou hartie",
		tallySheetPaperLoadingLabel: "Se incarca...",
		tallySheetElectronicTitleSection: "Completeaza borderoul online",
		selectCostCenterLabel: "Alege centrul de cost:",
		noOfTallySheetLabel: "Introdu numarul de borderouri:",
		deliveryTitleSection: "Modalitate de livrare",
		repaymentTitleSection: "Istoric rambursuri",
		ticketTypeLabel: "Tip tichet",
		errorAddRepayment_NO_DATA_VOUCHERS:
			" Este necesara completarea campului numar tichete. ",
		errorAddRepayment_NO_VALUE_VOUCHERS:
			" Este necesara completarea campului valoare tichet. ",
		errorAddRepayment_NO_TOTAL_VALUE:
			" Este necesara completarea campului valoare totala. ",
		ticketTallySheetLabel: "Tip borderou",
		tallySheetTypeNormal: "Normal",
		tallySheetTypeExpress: "Express",
		deliveryWithCourier: "LIVRARE PRIN CURIER",
		deliveryPersonal: "EXPEDIERE PERSONALA",
		pickupPointLabel: "Adresa de ridicare a coletului",
		deliveryPointAddressStreetNumber: "Numar",
		insurancePackageLabel: "Asigurare colet",
		selectOtherAddress: "Adresa noua",
		numberOfPackageLabel: "Numar colete: ",
		pickupHourLabel: "Ora ridicare colet: ",
		pickupHourText1: "Astazi la ora: ",
		pickupHourText2: " In functie de disponibilitatea curierului.",
		deliverySumarCosts: "Sumar costuri expeditie",
		costCourier: "Cost curier: ",
		costInsurace: "Cost asigurare: ",
		totalDeliveryCost: "Cost total de expeditie",
		deliveryToCenter: "La sediul Up Romania",
		addressCenterLabel: "Adresa: ",
		addressCenterText:
			"Calea Serban Voda, nr. 206, Building A, Cladirea U-Center, Bucuresti, sector 4, 040215",
		contactCenterLabel: "Contact: ",
		contactCenterText:
			"Rambursare Up Romania, 0754 423 31 47/ 021 312 64 90, afiliati@upromania.ro",
		receptionLabel: "Program receptie tichete: ",
		receptionText:
			"de luni pana vineri in intervalul orar 09:00-15:00",
		reimbursementHeaderID: "Identificator",
		reimbursementHeaderStatus: "Status",
		reimbursementHeaderDate: "Data",
		reimbursementHeaderValue: "Valoare",
		reimbursementSentSucces: "Borderoul a fost trimis cu succes",
		tallyError_TALLY_MAX_SUM:
			"Suma totala a borderoului depaseste valoarea maxima de 45.162 lei",
		tallyError_TALLY_AWB_OUT_OF_HOURS:
			"Solicitarea serviciului de curierat este posibila de luni pana vineri pana la ora 16:15 iar sambata pana la ora 13:15. Te invitam sa revii in orele de program menţionate mai sus.",
		tallyError_TALLY_LOCALITY:
			"Orasul la care s-a ales trimiterea curierului nu se afla in lista accesptata de curier.",
		tallyError_TALLY_NOT_AVAILABLE_HOLIDAY:
			'Cand se ramburseaza doar tichete de vacanta, datorita unei situatii legislative, <b>Serviciul de Rambursare Express</b> si <b>Serviciul de transport tichete</b> nu este permis. Pentru mai multe detalii te rugam sa contactezi <a href="https://upromania.ro/clienti/contact-224/date-de-contact-463/" target="_blank"> Echipa de Suport Relatii cu Partenerii Afiliati </a>. Iti multumim pentru intelegere!',
		tallyError_TALLY_EMPTY:
			"Pentru a genera borderoul electronic trebuie introduse mai intai datele in tabelul de mai jos.",
		tallyError_TALLY_AWB_ERROR:
			"Ne cerem scuze, a aparut o eroare la accesarea serviciului de curierat. In schimb, borderoul a fost procesat cu succes. ",
		tallyError_ISSS: "Internal server error",
		tallyError_500:
			"A aparut o problema in sistem, va rugam sa incercati mai tarziu!",
		tallyError_NOT_SELECTED_CENTER_COST: "Centrul de cost nu a fost selectat!",
		tallyError_NOT_LOADED_CENTER_COST:
			"Incarcam lista centrele de cost. Te rugam sa astepti.",
		noReimbursementsMessage: "Nu exista rambursari.",
		reimbursementHeaderValueDeclared: "Valoare totala declarata",
		reimbursementHeaderValueRead: "Valoare totala lecturata",
		reimbursementStep_304: "Procesat",
		reimbursementStep_716481: "Lecturat",
		reimbursementStep_332: "Facturat",
		reimbursementNumber: "Rambursare",
		reimbursementTicketTypeLabel: "Tichete {name} - De rambursat",
		reimbursementTicketTypeInvoiceLabel: "Factura",
		reimbursementTicketTypeInvoiceValueLabel: "Valoare factura",
		reimbursementTicketTypeValueLabel: "Valoare",
		reimbursementTicketTypePaymentDateLabel: "Data platii",
		reimbursementNoDownloadLabel:
			"Borderoul in format electronic nu este disponibil",
		reimbursementDownloadLabel: "Descarca borderoul primit de Up Romania",
		reimbursementDownloadAWBLabel: "Descarca AWB",
		reimbursementNoCourierAvailable:
			"Pentru a beneficia de <b>Serviciul de Rambursare Express</b> şi <b>Serviciul de transport tichete</b> este necesar sa semnezi un act aditional la contractul de afiliere. ",
		reimbursementStatus_0: "Introdus",
		reimbursementStatus_1: "Introdus",
		reimbursementStatus_2: "Introdus",
		reimbursementStatus_304: "Procesat",
		reimbursementStatus_716481: "Procesat",
		reimbursementStatus_378: "Lecturat",
		reimbursementStatus_381: "Lecturat",
		reimbursementStatus_716364: "Lecturat",
		reimbursementStatus_332: "Facturat",
		reimbursementStatus_334: "Facturat",
		reimbursementInfoMessage:
			"Te rugam sa imprimi borderoul generat online si sa il adaugi in colet alaturi de tichetele pentru care doresti rambursarea.",
		noOfTotallyVouchers: "Introdu numarul de vouchere",
		amountOfVoucher: "Introdu valoarea tichetului",

		// stickers
		stickersTitleSection: "Autocolante",
		bigStickersLabel: "Autocolante pentru vitrina (15.5x8 cm)    ",
		smallStickersLabel: "Autocolante pentru casa de marcat (10x5 cm)",
		quantityStickers: "buc.",
		deliveryAddressStickers: "Adresa de livrare a autocolantelor",
		stickersInfo: "Pentru a semnaliza clientilor ca accepti plata printr-un produs emis de Up Romania, solicita numarul de autocolante necesare pentru vitrina sau casa de marcat. Acest model de autocolant este unic, valabil pentru toate tipurile de produse emise de Up Romania (tichet sau card, masa, cadou, cultural, vacanta, social etc.).",
		digitalStickersLabel: "Autocolante in varianta digitala",
		digitalStickersText:
			"Daca doresti sa imprimi sau sa adaugi imaginea autocolantului Up in propriul sistem de semnalizare, il poti descarca ",
		otherStickersLabel: "Alte autocolante",
		otherStickersText:
			"In cazul in care doriti un alt autocolant decat cele prezentate mai sus va rugam sa completati campul de mai jos cu specificatiile acestora.",
		stickersError_500:
			"A aparut o problema in sistem, va rugam sa incercati mai tarziu!",
		stickersSentSuccess: "Cererea dumneavoastra a fost trimisa cu succes!",

		// working point
		workingPointAddress: "Adresa",
		workingPointAddress1: "Adresa 1",
		workingPointAddress2: "Adresa 2",
		workingPointGPSLat: "Latitudine GPS",
		workingPointGPSLon: "Longitudine GPS",
		workingPointPersonPosition: "Functie",
		workingPointAddressFac: "Adresa de facturare",
		workingPointAddressFacEmail: "Email pentru facturare",
		workingPointProducts: "Produse",
		workingPointProductPaper: "Hartie",
		workingPointProductCard: "Electronic",
		workingPointShopProfile: "Profil",
		workingPointTypeShop: "Tip de magazin",
		workingPointButtonAdd: "Adauga punct de lucru",
		workingPointSelectLabel: "Selecteaza punctul de lucru",
		workingPointContactEmailLabel: "Email(optional)",
		workingPointContactMobileLabel: "Numar telefon(optional)",
		workingPointProductsLabel: "Produse",
		workingPointProductsCardLabel: "Card",
		workingPointProductsPaperLabel: "Hartie",
		workingPointCommercialNameLabel: "Nume magazin",
		workingPointAddNewPersonLabel: "Adauga o persoana noua",
		errorSentAddressPoint_NO_INVOICE_ADDRESS:
			"Este necesara completarea campului adresa de facturare.",
		errorSentAddressPoint_NO_INVOICE_EMAIL:
			"Este necesara completarea campului email pentru facturare.",
		errorSentAddressPoint_NO_PERSON:
			"Este necesara completarea campului persoana de contact.",
		errorSentAddressPoint_NO_VALID_GPS_LATITUDE:
			"Este necesara completarea corecta a campului latitudine GPS.",
		errorSentAddressPoint_NO_VALID_GPS_LONGITUDE:
			"Este necesara completarea corecta a campului longitudine GPS.",
		errorSentAddressPoint_NO_PERSON_EMAIL:
			"Este necesara completarea campului email sau acesta nu este corect.",
		errorSentAddressPoint_NO_PERSON_MOBILE:
			"Este necesara completarea campului numar telefon sau acesta nu este corect.",
		errorSentAddressPoint_NO_UNIT_TYPE:
			"Este necesara completarea campului tip de magazin.",
		errorSentAddressPoint_NO_COMMERCIAL_NAME:
			"Este necesara completarea campului nume magazin.",
		errorSentAddressPoint_NO_WPP:
			"Este necesara completarea campului tip de magazin sau nume magazin.",

		// pos
		updatePOSLabel:
			"Verifica si actualizeaza lista de POS-urilor active pentru punctul de lucru selectat",
		updatePOSLabel1: "Verifica si actualizeaza lista de POS-urilor active",
		addPOSLabel: "Adauga POS nou",
		posBankLabel: "Banca",
		posMIDLabel: "MID",
		posChannelPayment: "Canal de plata",
		posStatusLastTransaction: "Statusul ultimei tranzactii",
		addImagePOS: "Adauga fotografie chitanta(optional)",
		deletePOSLabel: "Sterge",
		modifyPOSLabel: "Modifica",
		posTextButton: "Adauga POS",
		modifyPOSPopupLabel: "Modifica informatii POS",
		cancelModifyLabel: "Renunta",
		confirmDeleteTerminalMsg: "Sigur doresti sa stergi terminalul?",
		yesText: "Da",
		noText: "Nu",
		posError_500:
			"A aparut o problema in sistem, va rugam sa incercati mai tarziu!",
		posError_NO_ADDRESS:
			"Selecteaza punctul de lucru pentru care vrei sa adaugi un POS.",
		posError_NO_BANK: "Este necesara completarea campului banca.",
		posError_NO_MID: "Este necesara completarea campului MID.",
		posError_NO_CHANNEL: "Este necesara completarea campului canal de plata.",
		posError_NO_VALID_FORMAT: "Formatul imaginii nu este valid.",
		posAddSuccess: "POS-ul a fost adaugat cu succes!",
		posModifySuccess: "POS-ul a fost modificat cu succes!",
		posDeletedSuccess: "POS-ul a fost sters cu succes!",
		invalidFormatFilePOS:
			"Formatul fisierul este invalid. Trimite imaginea in format .jpg sau .jpeg.",
		posProductsLabel: "Produse",
		addPosAllProductsLabel: "Adauga POS-ul pe toate produsele Up",
		modifyOnlySelectedProductLabel: "Aplica modificarile doar pentru {card}",

		// affiliate settings
		ibanLabel: "IBAN",
		changeInfoCompanyText:
			"Daca doresti sa modifici informatiile companiei te rugam sa descarci formularul de mai jos si sa il trimiti completat la afiliati@upromania.ro",
		downloadFormChangeInfoCompanyLabel: "Descarca formular",
		flexben: flexbenLang,
		confirmChangeEmail:
			"Introduceti parola pentru inlocuirea adresei de email cu <b>{email}</b>",
		requestMandateTitleSection:
			"Solicita document de imputernicire acces cont si modificare date",
		requestMandateText_CST_N:
			"Pentru a invita colegi sau colaboratori sa acceseze contul tau de companie si pentru a putea modifica sau adauga informatii precum adrese de livrare sau persoane de contact, te rugam sa soliciti documentul de imputernicire accesand butonul de mai jos, iar noi il vom trimite pentru validare in cel mai scurt timp pe adresa de e-mail a reprezentantului societatii.",
		requestMandateText_CST_P:
			"Solicitarea de imputernicire a fost trimisa pe adresa de e-mail a reprezentantului legal al societatii si este in curs de aprobare.",
		requestMandateText_CST_V:
			"Solicitarea de imputernicire a fost aprobata de catre reprezentantul legal al societatii.",
		requestMandateText_AFF_N:
			"Pentru a invita colegi sau colaboratori sa acceseze contul tau de companie si pentru a putea modifica sau adauga informatii precum punctele de lucru sau POS-urile tale, te rugam sa soliciti documentul de imputernicire accesand butonul de mai jos, iar noi il vom trimite pentru validare in cel mai scurt timp pe adresa de e-mail a reprezentantului societatii.",
		requestMandateText_AFF_P:
			"Solicitarea de imputernicire a fost trimisa pe adresa de e-mail a reprezentantului legal al societatii si este in curs de aprobare.",
		requestMandateText_AFF_V:
			"Solicitarea de imputernicire a fost aprobata de catre reprezentantul legal al societatii.",
		requestMandateTextButton_N: "Solicita imputernicire",
		requestMandateTextButton_P: "Retrimite imputernicire",

		requestNotificationSmsFullTitle: "Setari notificari PUSH",
		requestNotificationSmsFullText: "Din dorinta de a implementa o comunicare exclusiv digitala, dorim sa inlocuim notificarile utilizatorilor cardurilor Up Romania prin serviciul SMS cu notificarile de tip push. Pentru a implementa aceasta schimbare, te invitam sa iti exprimi acordul prin intermediul butonului de mai jos.<br /> In cazul in care iti exprimi acordul, contractul incheiat intre Up Romania si societatea pe care o reprezinti va fi modificat corespunzator, prin dezactivarea serviciului SMS Full, adica notificarile SMS pentru tranzactii operationale, alimentare card si expirare tichet.<br /> Chiar si dupa activarea notificarilor push, vom continua sa informam salariatii tai cu privire la alimentarea cardurilor si expirarea tichetelor.<br /> Te rugam sa ne confirmi acordul tau pentru aceasta schimbare.",
		requestNotificationSmsFullButton: "Da, sunt de acord.",
		requestNotificationSmsFullSuccess:
			"Iti multumim pentru accept. Notificarea a fost descarcata automat.",
		requestNotificationSmsFullFailure:
			"Acceptul nu a putut fi transmis. Va rugam incercati mai tarziu.",

		noRalText:
			"Pentru a putea modifica informatii referitoare la societatea ta, te rugam sa completezi notificarea de mai jos prin intermediul careia sa ne transmiti datele de contact ale reprezentantului legal al societatii. ",

		requestNotifTitleSection:
			"Completeaza datele de contact si datele pentru reprezentantul legal al societatii",
		requestNotifText:
			"Pentru a putea solicita documentul de imputernicire este necesara completarea datelor reprezentantului societatii.",
		requestNotifTextRal:
			"Pentru a putea modifica datele de contact si datele reprezentantului societatii, va rugam sa generati notificarea.",
		requestNotifTextButton: "Genereaza notificare",
		requestNotifEmailLabel: "Email",

		requestAdditionalTextButton: "Genereaza actul aditional",

		posNoMandateText:
			"Pentru a putea modifica informatii referitoare la POS-uri sau pentru a adauga POS-uri noi, te rugam sa soliciti imputernicire de la reprezentantul legal al societatii accesand sectiunea Setari.",
		posNoMandateTextButton: "Mergi la Setari",

		// search affiliate
		searchAffliatePageName: "Cauta afiliat",
		searchAffliateByCodall: "Codall/Cod afiliat",
		searchAffliateByAffiliateName: "Nume afiliat",
		searchAffliateNoAffiliates: "Nu exista nici un afiliat.",
		affiliateCodeLabel: "Cod afiliat",

		// manage account
		companyManagementTitle: "Gestiune acces cont",
		companyManagementDescription:
			"Gestioneaza accesul la contul tau de companie al colegilor si colaboratorilor",
		userSettingsDescription:
			"Gestioneaza accesul la uponline si la companiile pe care ai fost invitat sa le administrezi",
		inviteUserTitleSection: "Invita utilizatori sa acceseze contul tau",
		manageUsersTitleSection:
			"Gestioneaza utilizatorii care au acces la contul tau",
		manageCompaniesTitleSection: "Gestioneaza companiile la care ai acces",
		adminInformationTitleSection: "Informatii admin",
		roleLabel: "Rol",
		userTypeLabel_CST: "Client",
		userTypeLabel_AFF: "Afiliat",
		userModeLabel_MASTER: "Admin",
		userModeLabel_USER: "Utilizator",
		userModeLabel_EDITOR: "Editor",
		rightsLabel: "Drepturi",
		userClientRightsLabel_MASTER: "Admin",
		userClientRightsLabel_USER:
			"Utilizatorul are dreptul de a utiliza toate functionalitatile existente in contul tau uponline.ro, insa nu poate modifica sau adauga informatii referitoare la compania ta precum puncte de lucru, informatii POS sau persoane de contact.",
		userClientRightsLabel_EDITOR:
			"Editorul are dreptul de a utiliza toate functionalitatile existente in contul tau uponline.ro si poate modifica sau adauga informatii referitoare la compania ta precum puncte de lucru, informatii POS sau persoane de contact..",
		userAffiliateRightsLabel_USER:
			"Utilizatorul are dreptul de a utiliza toate functionalitatile existente in contul tau uponline.ro, insa nu poate modifica sau adauga informatii referitoare la compania ta precum puncte de lucru, informatii POS sau persoane de contact.",
		userAffiliateRightsLabel_EDITOR:
			"Editorul are dreptul de a utiliza toate functionalitatile existente in contul tau uponline.ro si poate modifica sau adauga informatii referitoare la compania ta precum puncte de lucru, informatii POS sau persoane de contact",
		codallLabel: "Cod companie",
		typeUPPartner: "Tip partener UP",
		deleteCompanyText: "Esti sigur ca doresti stergerea companiei?",
		deleteLastCompanyText:
			"Esti sigur ca doresti stergerea companiei? In cazul care aceasta va fi stearsa nu vei mai avea acces la platforma UpOnline.",
		addUserSuccessMessage: "Utilizatorul a fost creat cu succes!",
		addUserInviteSuccessMessage: "Utilizatorul a fost invitat cu succes!",
		modifyUserSuccessMessage: "Utilizatorul a fost modificat cu succes!",
		deleteUserSuccessMessage: "Utilizatorul a fost sters cu succes!",
		deleteCompanySuccessMessage: "Compania a fost stersa cu succes!",
		mandateSentSuccessMessage:
			"Documentul de imputernicire a fost trimis cu succes!",
		documentSentSuccess:
			"Multumim! Fisierul a fost trimis cu succes. Modificarile vor fi efectuate dupa verificarea documentului primit.",
		mandateLPMessage:
			"Iti multumim pentru confirmare. Contul {company_code} are permisiunea de a invita colegi sau colaboratori sa acceseze contul si sa modifice informatiile companiei {company_name}.",
		mandateConfirmMessage:
			"Pentru a finaliza procesul de imputernicire a contului {company_code} te rugam sa apesi pe butonul CONFIRMA.",
		userManageError_EMAIL_EMPTY: "Este necesara completarea campului email.",
		userManageError_500:
			"Serverul nu raspunde. <br /> Te rugam sa astepti cateva minute si sa incerci din nou.",
		userManageError_401:
			"Serverul nu raspunde. <br /> Te rugam sa astepti cateva minute si sa incerci din nou.",
		userManageError_400:
			"Serverul nu raspunde. <br /> Te rugam sa astepti cateva minute si sa incerci din nou.",
		confirmModifyUserMessage: "Sigur doresti sa modifici userul?",
		confirmDeleteUserMessage: "Sigur doresti sa stergi userul?",
		companyStatus_true: "Activ",
		companyStatus_false: "Inactiv",

		createMandateTitle: "Confirmare document de imputernicere",
		createUserTitle: "Creaza contul",
		finishButtonLabel: "FINALIZEAZA",
		submitTypeChooseLabel: "Alege modul in care vrei sa te autentifici",
		submitTypeChooseLabelSuper: "Cod generat pentru aplicatia Up Mobil+",
		submitTypeChooseLabelUserlogin: "Credentiale aplicatie mobila Up Mobil",
		superUpInfo: "Pentru a gestiona beneficiile oferite de angajator, te rugam sa te autentifici in aplicatia UpMobil+ si sa introduci codul de mai jos in sectiunea Multibeneficii.",
		superUpApp: "Descarca noua aplicatie Up Mobil+",


		// offers
		offersLabel: "Campanii de promovare",
		yourOffersLabel: "Campaniile tale de promovare",
		addOfferButtonLabel: "Adauga o campanie",
		modifyOfferButtonLabel: "Modifica campania",
		deleteOfferButtonLabel: "Sterge campania",
		noContractHeaderLabel: "Nu ai un contract de publicitate activ",
		noContractTextLabel:
			"Pentru a-ti creste notorietatea in randul utilizatorilor de carduri Up si pentru a transforma cat mai multi dintre acestia in clienti noi, am dezvoltat pentru tine un modul de campanii de promovare pe canalele Up. Pentru mai multe detalii, te rugam sa iei legatura cu gestionarul companiei tale.",
		noContractButtonLabel: "Contacteaza gestionarul",
		addOfferTitleLabel: "Denumire campanie de promovare",
		addOfferValabilityLabel: "Perioada de valabilitate",
		addOfferChooseCardsLabel: "Cardurile UP pentru care se aplica oferta",
		addOfferChooseStoreLabel: "Marketul pentru care se aplica oferta",
		addOfferDescriptionLabel: "Descriere campanie de promovare",
		addOfferLinkLabel: "Link oferta",
		addOfferImageLabel: "Adauga imagine (dimensiune, format)",
		sendOfferLabelButton: "Trimite campania",

		// affiliate popup
		noBankRZB:
			"Stimate partener,<br><br>Te informam ca incepand cu data de 01.11.2021 conturile Raiffeisen Bank nu vor mai fi valabile.<br>Te rugam sa efectuezi plata intr-unul din conturile mentionate in factura proforma.<br><br>Echipa Up Romania",
		uploadFileLabel: "Incarca fisierul",
		selectLabel: "Selecteaza...",
		affiliateElectronicInfo:
			"Stimate partener,<br>Te informam ca, incepand cu data de 01.02.2022, biletele de valoare vor fi emise, conform legii, exclusiv pe card.<br>Tichetele pe suport de hartie emise de Up Romania vor putea fi acceptate, in continuare, in magazine intrucat au valabilitate pana la 31 decembrie 2022, procedura de decontare ramanand aceeasi.<br>Echipa Up Romania",

		// integrator
		filterLaberTransactionId: "Id tranzactie",
		invoicedLabel: "Facturata",
		refundedLabel: "Refunded",
		rejectedLabel: "Rejectata",
		processingLabel: "In procesare",
		refundButtonLabel: "Refund",
		idStoreLabel: "Cod magazin",
		refundSucccessMessage: "Tranzactia a fost returnata cu succes",
		refundErrorMessage_AO01: "Suma nu este corecta",
		refundErrorMessage_AO03: "Codul de afiliat este obligatoriu",
		refundErrorMessage_AO05: "Tranzactia nu a fost gasita",
		refundErrorMessage_AO12: "Serverul nu raspunde",
		rejectReason_balancenotvalid: "Fonduri insuficiente",
		rejectReason_cardnotvalid: "Card blocat",
		rejectReason_transactionnotvalid: "Eroare",
		refundAmount: "Valoare rambursata",
		refundAmountTitle: "Valideaza valoarea rambursata",

		mutareSediuAFFText: "<b>Receptia tichetelor pe suport de hartie </b> Up Social se va face exclusiv la adresa <b> U-Center, Calea Serban Voda, nr. 206, Building A, 040215, de luni pana vineri, in intervalul orar 09:00-15:00 </b>. <br /> Pentru receptie va rugam sa ne contactati la numarul de telefon <b> 075 423 31 47 </b>.",
		erroLoadingCards: "<b>Te informam ca intampinam probleme de ordin tehnic, ce impacteaza alimentarea cu intarziere a cardurilor. Ne cerem scuze pentru situatia intampinata si te informam ca lucram cu prioritate la remedierea acesteia. Revenim catre tine imediat ce rezolvam problema. </b>.",

		errorMultibenMaintenanceBeneficiary: "Draga {name}, <br/>Te informam ca platforma Up Multibeneficii este in mentenanta si nu poate fi folosita.<br />Cu respect,<br />	Echipa Up MultiBeneficii",
		errorMultibenMaintenanceClient: "Draga {name}, <br/>Te informam ca platforma Up Multibeneficii este in mentenanta si nu poate fi folosita.<br />Cu respect,<br />	Echipa Up MultiBeneficii",

		newVNMessage: "Vesti bune! <br /> <br /> A crescut valoarea tichetului de masă la 40,04 lei/ zi lucrata. <br /> Ofera-le si tu angajatilor tai tichete la valoare maxima legala!",

		// suport utilizatori
		deleteAccountLabel: "Sterge cont aplicatie mobila",
		updateMobileTranzations: "Actualizeaza lista tranzactii",
		deleteMobileAccountSucceess: "Stergerea contului  a fost realizata cu succes",
		updateMobileAccountSucceess: "Catualizarea contului  a fost realizata cu succes",

	},
];
